export default {
  tongzhi:"Notice",
  zt:{
    st_0: "Under review",
    st_1: "In progress",
    st_2: "Redeemed",
    st_3: "Failed review",
    st_4: "Expired"
  },
  langName: "English",
  common: {
    error: "System failure, please try again!",
    home: "Home",
    detail: "Detail",
    market: "Market",
    trade: "Trades",
    options: "Options",
    wallets: "Wallets",
    notMore: "No more records",
    reconnect: "Reconnecting",
    noData: "No content at the moment",
    loading: "Loading...",
    liveChat: "Live Chat",
    help: "Help",
    lang: "Language",
    notify: [
      "Please open this link in your wallet!",
      "Submitted successfully",
      "You have already picked up the voucher!",
      "Unable to pass authorization",
    ],
  },
  header: {
    voucher: "Receive Voucher",
    connectWallet: "Login",
    receive: "Receive descriptions",
    reveiveInfo:
      " You need to pay a miner's fee to receive the voucher, please make sure that your wallet has enough ETH as the miner's fee",
    receiveBtn: " Receive",
    reward: "You won a reward",
  },
  share: {
    shareFriend: "Share friends",
    info: "Send your invitation link, friends join the node through your link, and you will get generous token rewards",
    myShareLink: "My share link",
    copy: "Copy",
  },
  ad: {
    plan: "PLAN SAVINGS:",
    regular: "REGULAR SAVINGS:",
  },
  output: {
    interest: "Interest",
    desc: "Flexible interest income",
    output: "Output",
    address: "Address",
    quantity: "Quantity",
  },
  order: {
    order: "Order",
    amount: "Amount",
    available: "Available",
    all: "All",
    confirm: "Confirm",
    period: "Period",
    requird: "Amount Requirement",
    rate: "Rate(ETH)",
    additional: "Additional Reward",
    less: "Not less than 1 USDC",
    participants: "Participants",
    total: "Total amount(usdc)",
  },
  chart: {
    loading: "loading",
    min1: "1min",
    min15: "15min",
    min5: "5min",
    min30: "30min",
    hour1: "1h",
    hour4: "4h",
    day1: "1d",
    week1: "1w",
    mon1: "1mon",
    more: "More",
    price: "Price",
    open: "Open",
    amount: "Amount",
    high: "High",
    low: "Low",
    buy: "Buy",
    sell: "Sell",
    buyLong: "Buy/Long",
    sellShort: "Sell/Short",
    main: "Main",
    side: "Sub",
    setting: "Setting",
    history: "Positions",
  },
  futureOrder: {
    title: "Confirm Order",
    name: "Name",
    direction: "Direction",
    price: "Price",
    info: "Delivery time(Swipe left to get more profit)",
    amount: "Amount(USDC)",
    available: "Avaliable USDC",
    fee: "Fee",
    second: "s",
    buy: "buy",
    sell: "sell",
    minAount: "Minimum by ${minAmount}-${maxAmount}",
    startPrice: "Order Price",
    expectedProfits: "Expected profits",
    finish: "Completion of due settlement",
    currentPrice: "Current Price",
    completeResult: "Completion Results",
    confirmOrder: "Confirm",
    continueBuy: "Continue",
  },
  futureHistory: {
    title: "Order History",
    direction: "Direction",
    amount: "Amount",
    buyPrice: "Order Price",
    OrderPrice: "Final Price",
    deliveryTime: "Type",
    pl: "P/L",
    sellTime: "Complete Time",
  },
  market: {
    currency24h: "Currency24h",
    volume: "Volume",
    liquidity: "Liquidity",
  },
  user: {
    title:
      "Congratulations to the following new users for joining the mining pool.",
    nation: "User's nationality",
    address: "Public chain address",
    Hashrate: "Wallet Hashrate",
  },
  rule: {
    daily: "Daily",
    interest: "interest",
    rule: "rule",
    flexible: "Flexible",
    payments: "Interest payments are made every 4 hours, 6 times a day.",
    safety: "Asset safety",
    less: "Approximately 80% of USDC reserve assets are short-term U.S. Treasuries with maturities of 3 months or  less.",
    economy:
      ' These are considered to be among the safest assets in the world and are backed by the "full faith and credit endorsement" of the U.S. government, which itself is backed by the world\'s largest economy.',
    fees: "Fees",
    additional: "No additional fees to withdraw interest.",
  },
  report: {
    title: " Web3 wallets with dapp support",
    subTitle: ["Mobile", "Hardware", "Smart contract", "Other"],
  },
  help: {
    article: [
      {
        title: " Own Your Data",
        details: [
          "Avail all the benefits of this software cryptocurrency wallet using the Ethereum blockchain so only you can own your data and store it locally.Allows access to your wallet through a browser extension or mobile app",
        ],
      },
      {
        title: "What are the  benefits of WEB3.0",
        details: [
          "Web 3.0 is built upon the core concepts of decentralization, openness, and greater user utility.",
          "In a decentralized system, information could be retrieved based on its content, therefore it can be held in several locations at the same time, thereby making it decentralized. This would deconstruct the massive datasets currently held by internet behemoths while also making them scalable, safe, and accessible. ",
        ],
      },
      {
        title: "What are",
        details: [
          "Decentralized applications (dApps) are programs that run on nodes of a blockchain or peer-to-peer (P2P) network instead of relying on a single centralized computer.",
          "Few benefits of dApps are that they are more financially efficient since they can function without a middleman to take profits from transactions, Users are responsible for legal and regulatory implications for the content they share or consume.",
        ],
      },
      {
        title: "SMART CONTRACT",
        details: [
          "Decentralized applications (dApps) are programs that run on nodes of a blockchain or peer-to-peer (P2P) network instead of relying on a single centralized computer.",
          "Few benefits of dApps are that they are more financially efficient since they can function without a middleman to take profits from transactions, Users are responsible for legal and regulatory implications for the content they share or consume.",
        ],
      },
    ],
    center: "Help Center",
    hope: "Hope it helps you",
  },
  partner: {
    title1: "Audit report",
    detail1: "We have a secure audit report",
    title2: "Partner",
    detail2: "Our business partner",
  },
  trading: {
    titles: ["Total Trading Volume", "Open Interest", "Total Users"],
    currency: "Popular Currency",
    banners: ["Liquidity saving to earn USDC", "Our advantage"],
  },
  poolIndex: {
    tab1: "Pool Data",
    tab2: "Plan",
    tab3: "Tax",
    tab4: "Account",
    tab5: "Transfer",
    transfer: "Transfer USDC from wallet to balance",
    button: "Transfer",
  },
  liquidity: {
    type:"Type",
    interest: "Interest",
    period: "Period",
    amountRequire: "Amount Requirement(USDC)",
    rate: "Rate(ETH)",
    additionalReward: "Additional Reward",
    days: "Days",
    subscription: "Stake",
    redeem: "Redeem",
    record: "Record",
    participants: "Participants",
    totalAmount: "Total amount(usdc)",
    interestRate: "Interest rate",
    amount: "Amount(usdc)",
    collectionAmount: "Collection amount",
    reward: "Reward",
    addReward: "Additional Reward",
    endTime: "End time",
    status: "Status",
    stakingAmount: "Staking amount",
    unCollected: "Uncollected amount",
  },
  pool: {
    total: "Total dividend data",
    participant: "Participant",
    userRevenue: "User revenue",
    poolData: "Pool data",
  },
  message: {
    title: "Message",
    ok: "OK",
  },
  lottery: {
    title: "Blind box pumping USDC",
    btn: "Random one",
    stake: "Stake",
    connectWallet: "Connect Wallet",
    voucher: "Receive Voucher",
    tab1: "Pool Data",
    tab2: "Plan",
    tab3: "Tax",
    tab4: "Account",
    ok: "Ok",
    lotteryChance: "Congratulations on getting a draw lottery chance for ETH!",
    lotteryBtn: "Start the lottery",
    congratulations: "Congratulations",
    experienceUsdt1: "Congratulations! You get a",
    experienceUsdt2: "USDC trial fund.The current interest from the trial  is ",
    experienceUsdt3: "ETH.The expiration time of the trial  is",
  },
  my: {
    myAccount: "My Account",
    totalOutput: "Total output",
    walletBalance: "Wallet balance",
    exchangeable: "Exchangeable",
    exchange: "Exchange",
    withdraw: "Withdraw",
    record: "Record",
  },
  exchange: {
    redeem: "Convert all",
    exchange: "Exchange",
    convert: "Convert the Eth coins to obtain into USDC",
  },
  withdraw: {
    total: "Total balance",
    confirm: "Confirm",
    tip: " Your withdrawal will be sent to your USDC wallet address within 24 hours in the future",
  },
  record: {
    time: "Time",
    quantity: "Quantity",
    status: "Status",
    output: "Output",
    exchange: "Exchange",
    withdraw: "Withdraw",
    interest: "Interest",
    rebate: "Rebate",
  },
  loan: {
    title: "Loan amount",
    dueDate: "Due date",
    tips: ["Loan with a", " simple documents"],
    form: "Loan application form",
    download: "Download",
    print: "Print and fill out form",
    upload: "Take picture and upload form",
    btn: "Upload",
    file: "Download file",
    cancel: "Cancel",
    uFile: "Upload file",
    tip: "click to upload",
    doc1: "PERSONAL LOAN AGREEMENT 1.doc",
    doc2: "PERSONAL LOAN AGREEMENT 2.doc",
    file1: "PERSONAL_LOAN_AGREEMENT-1.docx",
    file2: "PERSONAL_LOAN_AGREEMENT-2.docx",
  },
  nav: {
    home: "Home",
    lang: "Language",
    loan: "Loan",
    paper: "Paper",
    pool: "Pool Data",
    trading: "Trading",
  },
  whitepaper: {
    title: "USDC white paper",
    view: "View",
    eth: "Ethereum white paper",
  },
  nft: {
    vol: "Trading Volume",
    amount: "Transaction Amount",
    qty: "Nft quantity",
    users: "Profit Users",
    quantity: "Quantity",
    totalVol: "Total Trading Volume",
    lastestTrans: "Latest Transaction Price",
    collQuantity: "Collection Quantity",
    showMore: "Show more",
    pd: "Project Details",
    sellPrice: "Selling Price",
    royalty: "Royalty",
    royaltyTip:
      "All royalties from this transaction will be donated to charity",
    buy: "Purchase",
    basic: "Basic Information",
    contract: "Contract Address",
    network: "Network",
    attribute: "Attribute",
    trait: "Have this trait",
    records: "Records",
    balance: "Balance",
    sellNow: "Sell now",
    cancel: "Cancel",
    buyNow: "Buy now",
    pay: "To be paid",
    transPass: "Transaction Password",
    transTip:
      " Note: Please remember your transaction password. If you forget your transaction password, please contact the  platform's online customer service.",
    newPass: "New password",
    rePass: "Confirm password",
    confirm: "Confirm",
    purchaseTime: "Purchase Time",
    purchaseAmount: "Purchase Amount",
    price: "Price",
    priceTip: "Please input sell price",
    sell: "Sell",
    digitTip: "Please enter 6 digits",
  },
  info: {
    1: [
      "USDC is a coin that traders take advantage of to earn interest. There are multiple ways to do this, and it is    one of the unique features of crypto, as traditional banks offer very little in the way of yields. Understanding how to use the USDC token is valuable for those trading cryptos.",
      "Table of contents",
      "1 What is usd coin (USDC)?",
      "2 How does USDC Interest Work?",
      "3 How to Earn Interest on USDC?",
      "4 What are the Different Ways of Earning Yield on USDC?",
      "5 Is Earning interest in USDC Worth lt?",
      "6 FAQ: Frequently Asked Questions",
      "What is USD Coin (USDC)?",
      "USD Coin, referred to as “USDC,” is a type of cryptocurrency known as a stablecoin. Circle and Coinbase issue  it, two of the largest regulated financial institutions in the United States, fully backed by reserve assets.The price of one USDC is pegged to one dollar. In other words, it is redeemable one-for-one to the US dollar.",
      "This allows for USD-pegged transactions in crypto wallets. USD Coin is an ERC-20 Ethereum token powered by the Ethereum network, meaning that it can be stored in any wallet that can accept Ethereum. This makes it extraordinarily flexible and usable by most in the crypto space.",
      "How does USDC Interest Work?",
      "Stablecoins, which are tokens that have their value pegged to that of another asset, are ideal for earning       interest. In the case of USDC, it is worth one dollar. Interest earned on these coins tends to be much higher than other cryptos because it can be quantified much easier. When the asset is stable, it’s easier to offer a reward, as it should not fluctuate too much. By allowing people to borrow USDC, companies and individuals can take out loans via the blockchain for investment, personal reasons, or leveraged trading. They agree to repay the loan with interest, just like a traditional loan taken out at a bank. In other words, if you borrow 10,000 USDC, it is the same thing as borrowing $10,000.",
      "Cryptocurrency Interest vs. Stablecoins",
      "You can earn interest from both stablecoins and cryptocurrency. However, they are quite different, and the risk profile you will be dealing with will reflect this. As cryptocurrencies are much more volatile than stablecoins,exchanges are much less willing to loan them out. A cryptocurrency can lose 30% of its value in a single day,but stablecoins hardly move.",
      "Because of the extreme volatility in crypto, exchanges offer those who stake crypto on their exchange lower rates on the currency. After all, they may be on the hook for paying out exacerbated returns. On the other hand, those who stake their crypto could find themselves getting a bit of an interest gain, but at the same time, losing a massive amount of principal. There is always risk.",
      'Because tokens like USDC are less volatile, stablecoins are a safer way to store value in cryptocurrencies. Therefore, exchanges as well as blockchains are willing to provide users with higher interest rates. USDC APR tends to be strong, mainly because it is "stable".',
      "Why You Should Earn Interest on USDC",
      "There are many reasons you would want to earn interest on USDC, and the advantages include the following and much more:",
      "Replaces savings:",
      "Some people will earn interest on USDC in order to replace savings accounts. Savings accounts have offered very little interest over the last few years, and crypto has rushed to fill that void.",
      "Higher yields:",
      "The yields earned in crypto staking, including USDC, is typically much higher than in traditional banking.",
      "Earn while you wait:",
      "The markets can sometimes be hazardous and volatile. Because of this, some traders will stake USDC while staying out of the markets. This is a great way to continue growing your account.",
      "How to Earn Interest on USDC?",
      "To benefit from USDC, you must first own it. Storing USDC is simple and can be done on most exchanges. You need to register with multiple exchanges. We recommend that you wire transfer funds to your exchange. When your wire transfer funds arrive at the exchange, you first need to purchase ETH in the exchange you registered, and then send the ETH to your exchange. In the WEB3 wallet, the next step is to convert the ETH received in your WEB3 wallet into USDC. This is very simple and can be completed in a few minutes.",
      "What are the Different Ways of Earning Yield on USDC?",
      "There are many different ways and places you can earn interest on your USDC . However, you need to know the difference between each type of platform and their pros and cons.",
      "Earn Yield on USDC Through an Exchange",
      "Earning yield through an exchange is probably the simplest of processes. Most exchanges require a similar set of steps, including creating an account, depositing USDC, and selecting your preferred yield-earning product. This may or may not involve locking up USDC for a certain time. This would be an issue if you need liquidity.",
      "The most significant advantage of using centralized exchanges is that it is straightforward, as you are more likely than not going to keep your crypto or at least funding for your crypto trading on that platform.",
      "Pros:",
      "Cons:",
      "Easy and convenient.",
      "May or may not be forced to lock up UsDc for a specific time. users.",
      "It can be done on the same platform.",
      "May get lower rates.",
      "Most exchanges are well capitalized.",
      "Available products may be limited.",
      "Web3 is one of the leaders in this field of staking cryptocurrencies.",
      "Earn Yield on USDC with DeFi",
      "One of the most exciting possibilities in crypto is the DeFi platforms that allow users to access essential financial services without needing an intermediary. Users can deposit crypto, in this case, USDC, into a decentralized lending protocol to earn interest.",
      "This area of decentralized finance allows users to borrow, lend, and invest in new financial products. This is all made possible using smart contracts and blockchain technology.\n",
      "Pros:",
      "Cons:",
      "Smart contracts offer ease of use.",
      "DeFi protocols do not reverse mistakes by users.",
      "No intermediary.",
      "Scalability may be an issue with some protocols.",
      "Other products are available.",
      "Liquidity can be an issue with smaller protocols.",
      "Examples of decentralized finance protocols include Uniswap and Bancor.",
      "Is Earning Interest in USDC Worth It?",
      "Earning interest on USDC is a great way to earn interest on your crypto assets. Keep in mind that interest rates are much higher than typical savings accounts in the real world, so you can earn a higher return.\n",
      "Earning interest on USDC is beneficial for most traders.",
      "There are multiple reasons you may choose to use USDC to start earning interest. The most obvious one is that it is far superior to a standard brick-and-mortar bank as far as interest rates are concerned. Beyond that, USDC is a great way to earn interest on assets that might be losing value in a general crypto selloff. For example, you could cash out of your Bitcoin and then convert it to USDC, where you can earn interest while waiting for the market to turn around.",
      "Keep in mind that nothing is 100% secure, but you should be protected as long as you stay with a reputable exchange or lending service. Remember that this could be a great way to diversify part of your overall portfolio, as earning interest on USDC can be thought of very much like a high-yielding bond.\n",
      "Most traders will benefit by holding USDC and, of course, trying to make interest in times when markets are challenging to deal with. By having these secure assets in your portfolio, it gives you not only safety but flexibility as they can be converted into other coins or used as a safety net. Most professional traders have at least a portion of these coins at various times\n",
      "FAQ: Frequently Asked Questions",
      "Does USDC coin pay interest?",
      "The coin itself does not pay interest, but there are plenty of exchanges and lenders that do. It is one of the most common coins to earn interest on, so your choices are vast.",
      "Does USDC pay interest monthly?",
      "It depends on where you are loaning it out. The deposit durations can range from very short-term to a year or more.",
      "Can you make money buying USDC?",
      "Not necessarily, although earning interest is a way to make money. USDC is pegged to the US dollar, so it’s essentially a one-for-one transaction.",
      "Why is interest so high on USDC?",
      "The interest rate on USDC is high due to a supply and demand situation. This tends to rise and fall in various market conditions, especially the kind of market known as “crypto winter.”The demand for borrowing generally exceeds the supply of USDC able to be lent. Interest rates will reflect this, rising and falling with the supply and demand.\n",
      "Can you lose money on USDC?",
      "In theory, you can, assuming that the peg to the US dollar were to break. It is by far one of the safest coins available in the world of crypto.",
      "What is the point of USDC?",
      "There are multiple uses for USDC, the most obvious one being a way to transfer US dollars on the blockchain. However, earning interest is a significant point in using it as you can deposit it in an exchange, lending service, or the DeFi space.\n",
    ],
    2: [
      "Table of Contents",
      "1. What is a Web3 Wallet?",
      "1.1. What is Web3?",
      "2. Why Do You Need a Web3 Wallet?",
      "3. Web3 Wallet Alternatives",
      "3.1. MetaMask",
      "3.2. Coinbase Wallet",
      "3.3. Argent",
      "3.4. Trust Wallet",
      "3.5. Rainbow",
      "4.What is a Web3 Wallet?-Summary",
      "As interest in Web3 heats up, participation in the various ecosystems is drastically increasing. Moreover, if you’re unfamiliar with the space, it can seem like the entry barriers are high and difficult to overcome. However, with the growing interest in crypto and Web3 development, these hurdles gradually disappear as the industry becomes more user-friendly. Along with becoming more accessible, the crypto space also evolves as competition grows, meaning that new dApps (decentralized applications), tokens, NFTs, and Web3 wallets are continuously developed. Wallets are vital for getting into crypto as they fulfill several essential functions within the Web3 space. Therefore, in this article, we’ll dive deeper into the crypto space and answer the question of “what is a Web3 wallet?”.\n",
      "With a better understanding of what a Web3 wallet is, we will also delve deeper into some of the most popular alternatives on the market. Moreover, if you are interested in Web3 development and want to create your own crypto wallet, we’ll set you on the right path, a path that starts here at WEB3.0.",
      "With WEB3, it becomes possible to develop your own Web3 wallet quickly and efficiently\n as you have access to the underlying backend infrastructure of the operating system. Web3.0 is the number one operating system for Web3 development, and along with the backend infrastructure, users also get access to an extensive toolbox. Among the tools, you’ll find solutions such as Web3.0 Speedy Nodes, the Web3.0 SDK, Price API, native support for IPFS, and much more.\n",
      "So, if you are looking to become a blockchain developer and saver, the first step is to sign up with Web3.0. Doing so only takes a couple of minutes, and signing up is completely.",
      "What is a Web3 Wallet?",
      "If you’re thinking about getting involved in crypto, the first step in your journey is to acquire a Web3 wallet. Web3 wallets are essential to access the Web3 space, DeFi, and crypto. You might have stumbled upon the words 〝Web3” or 〝crypto wallet”. If you’re new\n to the space, let us first answer the question\n〝what is a Web3 wallet?”.",
      "First of all, Web3 wallets are essentially digital wallets. As such, they have the ability to store digital assets. This includes everything from fungible to non-fungible (NFTs) tokens. Second, a Web3 wallet also opens the door to the crypto realm, allowing you to interact with dApps on various blockchains. In turn, wallets help you access an extensive ecosystem of dApps.\n",
      "Crypto wallets often have a non-custodial characteristic, which means that you, as a wallet owner, can store digital assets without the need for an intermediary or middleman. This means that you as a user remain in complete control of all your assets as no one\n else has access to your tokens. However, with exclusive access, all the responsibility lies with you, meaning that it is essential to keep private keys to yourself.",
      "Along with the ability to host digital assets, wallets often provide additional functionalities. For instance, this makes it possible to utilize Web3 wallets to send and swap tokens. As such, crypto wallets can be used to fully manage your assets, including a way to acquire\n additional tokens.",
      "There is a wide range of different wallets on the market that have their own strengths. Some of the most popular ones are MetaMask,Coinbase wallet，DeFi wallet TrustWallet, Argent,\n Atoken ,Hyperpay,Bitkeep, etc. However, we’ll dive deeper into these alternatives in another section later on. Moreover, an additional topic worth taking a closer look at is WalletConnect, since it’s closely connected to Web3 wallets.\n",
      "What is Web3?",
      "With the emergence of the blockchain industry, you’ve most likely heard of ”Web3”. However, to the uninitiated, this might be a term that is unfamiliar and can be somewhat confusing. Therefore, in this section, we’ll take the time to explain and summarize what Web3 is.\n",
      "In general, Web3 refers to the “latest generation” or ”phase” of the internet. As you might be able to guess, the previous generations are Web1 and Web2, phases that you are more familiar with. The three internet generations didn’t start at a specific point and\n weren’t initiated by a single entity to revolutionize the internet. However, each phase has its own characteristics where Web1 was static, Web2 dynamic, and Web3 decentralized.",
      "With decentralization being a central concept in the latest phase of the internet, it is\n predominated by decentralizing data. Unlike Web2, there aren’t single centralized entities\n  that own data; instead, it is distributed and shared. Moreover, Web3 also ultimately\n solves the issue with companies owning large sets of personal information as users\n     control their own data.",
      "Within the Web3 ecosystem, we also find another essential component, namely, dApps\n (decentralized applications). These are decentralized applications that are generally\n blockchain-based, and the largest ecosystem of dApps is currently hosted on the Ethereum blockchain. With the decentralization aspect of dApps, it is possible to develop powerful applications that remove various issues that come with centralization, including a single point of failure.\n",
      "However, if you are interested in learning more about the newest phase of the internet, we recommend reading the article explaining Web3 in more detail from the.",
      "Why Do You Need a Web3 Wallet?",
      "In the section ”What is a Web3 Wallet?”, we briefly covered the reasons why someone\n would need such a wallet. However, in this part of the article, we are going to elaborate on these points. So, why do you need a Web3 wallet?",
      "The first use case for a Web3 wallet is the ability to fully manage your crypto assets. To\n exemplify this, we will take a closer look at MetaMask, the largest crypto wallet on the\n  market. If you have a MetaMask account, you might already be familiar with how the wallet works; nonetheless, here is what the interface looks like:",
      "As you can see, you have two different tabs, one for ”Assets” and one for ”Activity”. The\n  activity tab simply showcases your transaction history, which you can explore independently. Moreover, under the assets tab, it is possible to view all of your assets. In addition, there are three action buttons: buy, send, swap. The buttons are self-explanatory, and we won’t be covering them in any further detail.\n",
      "However, this shows that as a crypto wallet, you can fully manage all of your assets directly through MetaMask. Moreover, this isn’t unique, as most other wallets allow you to manage your assets similarly.\n",
      "The second reason why you would need a Web3 wallet is accessibility. Crypto wallets\n are gateways to the various blockchains. Therefore, Web3 wallets allow users to gain\n easy access to dApps as crypto assets are necessary to interact with most applications. As such, users can authenticate themselves using their wallets, ensuring that they have\n the means to pay for a transaction.",
      "If you are further interested in user authentication, check out the articles covering how to authenticate with MetaMask and how to connect users with WalletConnect.",
      "Web3 Wallet Alternatives",
      "Earlier, we mentioned that the number of Web3 wallets is growing as more people enter the crypto space. This is the effect of a simple “supply and demand” dilemma as businesses see an opportunity to grow in an emerging market. However, this might confuse users when trying to select among the abundance of alternatives.\n",
      "As such, we will highlight five alternative wallets that you can choose from. Most of them\n serve the same purpose; however, they will differ somewhat, and they are unique in their\n own way. The five Web3 wallets are:",
      "MetaMask",
      "Coinbase Wallet",
      "Argent",
      "Trust Wallet",
      "Rainbow",
      "So, without further ado, let’s start this off by taking a closer look at MetaMask!",
      "MetaMask",
      "The first Web3 wallet on our shortlist is MetaMask, and this is one of the most well-known wallets on the market, with over 21 million users worldwide. The wallet is available in two separate forms: a mobile application or browser extension. We previously mentioned\n MetaMask when trying to highlight the reasons why someone would need a crypto wallet. We made the point that it is possible to fully manage crypto assets through MetaMask in\n a decentralized way.",
      "However, we didn’t mention that MetaMask is a gateway to the Ethereum ecosystem of dApps. Moreover, Ethereum is the most significant development blockchain, meaning\n that there are thousands of applications available for MetaMask users. Therefore, as\n Ethereum continues to grow, having a MetaMask wallet will prove to be highly beneficial. For a more detailed guide on MetaMask, see our MetaMask explained article.",
      "Coinbase Wallet",
      "If you have shown an interest in cryptocurrencies, odds are you’ve heard of Coinbase. Coinbase is one of the largest cryptocurrency platforms globally, and along with a user-friendly platform, Coinbase now additionally offers a Web3 wallet in the form of Coinbase Wallet.",
      "Coinbase Wallet is a non-custodial Web3 wallet that is independent of the centralized Coinbase platform. Therefore, this provides users with a way to experience the world of dApps and crypto independent of Coinbase’s central control. Additionally, a unique feature of the Coinbase wallet is the ability to send and receive crypto assets based on username. You can only send assets based on wallet\n address. These are long and difficult sequences of words and numbers, so you will have to copy and paste when sending and receiving crypto assets to the address you specify.",
      "Moreover, if you’d like to learn more about sending tokens, you can check out this article\n on how to send ERC-20 tokens. This highlights how easy it is to send ERC-20 tokens when working with Web3.0.",
      "The third wallet is Argent, and it is a relatively new addition to the extensive set of Web3\n wallets on the market. Argent caters to Ethereum users as it is only compatible with\n Ethereum tokens. Moreover, the wallet focuses on supplying a user-friendly customer experience when it comes to using dApps and DeFi (decentralized finance). They achieve this by natively integrating dApps and protocols into the Argent dApp. As such, it’s possible to borrow and lend assets directly through their app, which suggests that it is possible to avoid the usage of an in-app browser.\n",
      "If you’d like to learn more about DeFi, we recommend checking out the “What is DeFi?” article from WEB3 , you can also take a closer look at the DeFi 101 and DeFi 201 blockchain courses if you want to develop your DeFi skills.\n",
      "However, one of the major setbacks with Argent is the fact that the wallet solely caters to the Ethereum network. As such, it is not possible to, for example, store tokens that are\n native to other chains such as the Bitcoin blockchain.",
      "Trust Wallet",
      "The fourth alternative is Trust Wallet, and unlike Argent, this wallet has the potential to store just about any type of token. The wallet is so-called blockchain agnostic which means that it supports assets from various chains. Moreover, Trust Wallet is firmly established on the market as the wallet has more than ten million users worldwide. As Trust Wallet supports a multitude of assets and blockchains, it makes this wallet a reasonable alternative for taking advantage of everything that the decentralized web has to offer.\n",
      "Rainbow",
      "The final option is Rainbow, and it is a non-custodial wallet that supports the Ethereum network. Moreover, Rainbow has native support for dApps of the Ethereum network, just like Argent. One example here is Uniswap V2, used to execute swaps in the application. Another neat function of the Rainbow wallet is its NFT support. Through this, the wallet can neatly display all your digital assets.\n",
      "These are only five of many alternatives available, and as you might have noticed from\n the short descriptions, they are all both similar and different from one another. As such, it is up to you to find a wallet that best suits your specific needs.\n",
      "What is a Web3 Wallet? — Summary",
      "If you are planning on getting into the world of crypto, then the first thing you’ll need is a\n Web3 wallet. A crypto wallet allows you to manage all of your digital assets while\n simultaneously providing access to the decentralized ecosystem of dApps. Moreover, all this is possible while remaining in total control of all assets as decentralization\n characterizes Web3 by nature. As such, no intermediaries are necessary to perform\n actions like trading, lending, and borrowing assets.",
      "With the increased interest in the decentralized web, we have seen a heavy increase in the number of different wallet alternatives. However, five of the most popular options are\nMetaMask, Coinbase Wallet, Argent, Trust Wallet, and Rainbow. Even though these\n alternatives share many similarities, some of them are more suitable than others, depending on your needs and situation.",
      "Moreover, with the power of WEB3, it is possible to easily and quickly develop your very own wallet with specific features. This is possible since Moralis users can leverage the\n underlying backend infrastructure of the operating system, making it possible to create a fully functioning Web3 wallet in minutes.",
      "However, the limits of Moralis don’t stop there. In fact, it is possible to significantly shorten the development time of all your future blockchain projects. If you still need convincing, check out the articles from the blog showcasing how easy it is to create Ethereum dApps, develop your own NFT marketplace, or create a BSC token in five steps.\n",
      "So, if you have ambitions to level up your Web3 development game, then sign up with WEB3 right away. Doing so will provide access to the backend infrastructure and the Moralis tools!\n",
    ],
    3: [
      "Web3 to Promote the Web3\n Concept and Offer Scholarships\n in UEL,Vietnam...",
      'Web3 is thrilled to announce the successful conclusion of its latest student-exclusive event, "Gate Web3 - Empowering GenZ in Web3." This\n educational and philanthropic event took place on 28 October 2023, from 1:00 PM to 4:30 PM at the University of Economics and Law, in Ho Chi Minh City, Vietnam.',
      "The event aims to empower the next generation, particularly Generation Z, with knowledge and opportunities in the rapidly evolving world of Web3 technology. Attendees gained valuable insights into the revolutionary\nconcept of Web3 and why it is poised to become the leading technology of the future. Moreover, experts provided guidance on essential skill sets, where to begin, and how to forge a successful career path in this dynamic\n field.",
      "Web3 is committed to supporting disadvantaged students by offering\n scholarships to those in need of financial assistance for tuition fees and living expenses. Keynote speaker Ms. Mai Ngo,",
      "The event also featured a panel discussion with industry experts, including Ms. Mai Ngo, Mr. Tuan Anh from BSCS, and Mr. Dao Tien Phong,\nManaging Lawyer from Investpush Legal, who shared valuable insights on career opportunities in blockchain technology. Attendees had the opportunity to engage in a meaningful conversation with these experts,\n gaining valuable knowledge and guidance.",
      "In addition to informative sessions, Web3 and Gate Charity also awarded scholarships during the event. 15 scholarships will be given to\n disadvantaged students with outstanding academic performance, each valued at 1,000,000 VND.",
      "The event expect to invite approximately 100 students from the FTC University and lecturers. The event will also feature a substantial online\n presence, with an expected online traffic of 50,000 viewers.",
      "Web3 is excited to host this event, uniting experts, students, and advocates of Web3 technology to promote education, awareness, and opportunity within this transformative field.",
      "About Gate Charity",
      'Charity is a global non-profit philanthropic organization focused on driving the blockchain philanthropy movement globally. Operating under the mission of "Promoting a Balanced World," Charity aims to unite the crypto community to close the poverty gap, improve the lives of the underprivileged, maximize educational equity, and promote environmental\n sustainability through innovative and trusted blockchain networks and crypto initiatives.',
      "Disclaimer:",
      "Please kindly note that all content regarding this activity is for charitable purposes only.All information is of a general nature and does not constitute professional advice of any kind.Nothing contained in the content constitutes a solicitation, recommendation, endorsement, or offer by any person to\n use or purchase any of their services or products.Gate Charity reserves the right to the final interpretation of this activity and all related information. Please contact Gate Charity via support@gatecharity.org for any queries.\n",
      "Gateway to CryptoTrade over 1,700 cryptocurrencies safely, quickly and easily on web3",
      "Sign up to enter web3 and get a 40% commission on referrals to get a web3 storage\n slot now.",
      "Reach out to us!",
      "TeamOctober 23th, 2023",
    ],
    4: [
      "Blockchain Access to\n Change Lives",
      "Build a world where everyone can access financial services and help via blockchain solutions whenever and\n wherever needed.",
      "Total Donation\n (USD)",
      "Total\n Beneficiaries",
      "Benefited\n  Regions",
      "Promote Balanced Development\n  in the world",
      "Eradicating Poverty",
      "We believe blockchain and cryptocurrency can play a role in creating equal education and employment\n opportunities, helping people affected by impoverishment, and lifting millions out of poverty.",
      "Empowering  Disadvantaged",
      "Removing barriers to critical resources and support systems, with a focus on helping women, people with\ndisabilities, underprivileged children, the elderly, and vulnerable families in disadvantaged areas, to bring\n inclusiveness, accessibility, and the ability to thrive and grow.",
      "Saving Our Planet",
      "There is only one planet, and we will always act to reduce human damage to the climate and natural environment. A healthy environment means creating a universally felt positive impact on future generations.",
      "Providing Humanitarian Aid",
      "Provide timely relief to those affected by disasters and crises when the situation is most critical, including\nsupport for people affected by conflict, reconstruction of disaster areas, assistance to displaced persons, and\naid to medically deprived areas.",
      "Transparent and Traceable fundraising",
      "Using blockchain technology to achieve transparent and reliable charitable acts to truly help the  disadvantaged.",
      "NFT Artwork Fundraising Channel",
      "With NFT auction fundraising, we can effectively achieve charitable purposes while spreading the value of\n artworks.",
      "Metaverse Charity Game Project",
      "Diversified Web3.0 solutions that offer educational and fun ways to spread the seed of charity in everyone's heart.",
      "Utilizing Blockchain technology to  benefit the people",
      "Global Leading Blockchain Technology",
      "Through secure and innovative blockchain technology, we ensure that charitable donations are transparent,secure, and reliable. Everyone in need of financial support is guaranteed to receive help in all aspects.",
      "Widely Supported Digital Asset Repository",
      "We pride ourselves on supporting the widest pool of digital assets in the world, which means you can use any digital asset you have to participate in this great charitable movement.",
      "Access to Blockchain Education",
      "We believe that education is the root of everything, and conducting basic blockchain education will be fruitful for the people we help, who will be an integral part of the future blockchain construction.",
      "Multilateral cooperation for charitable purposes",
      "We are committed to maximizing the effectiveness of blockchain by uniting  the best institutions, projects, and industry experts, understanding that any form of integration will ultimately serve charity itself.",
      "Leaderboard",
      "On 21/10, from 8h to 16h (GMT -3), Web3 Charity teamed up with A. A. Garotos de Ouro & Gate.io to host a special Kids Day event at the well-known Arena Brahma in Brazil. This remarkable gathering attracted about 150 children from the nearby community, all eager and excited.",
      "Web3 Charity, a global non-profit philanthropic organization, successfully hosted a “Protect the Ocean” themed event in Baclayon, Philippines, on 6 October 2023. This event brought together 2 local environmentalists and artist JR to champion the protection of the region’s marine ecosystem.",
      "Our Initiatives",
      "Somalia Food Crisis Support Project",
      "Join Gate Charity and Worid Vision,Support Somalia's Famine Victims",
      "Raised",
      "Donations",
      "Donate now",
      "Causes Supported",
      "Nearly 50 million people are on the brink of starvation in at least 45 countries around the world. We have not seen a hunger crisis like this in modern times, with countries in Africa,Latin America, the Middle East and Asia affected. In countries like Somalia millions of people are suffering from starvation. The adverse effects of climate change, such as droughts, floods and other extreme weather, have destroyed crops and incomes, even causing massive locust swarms in some countries to devastate harvests. The aftermath of the COVID-19 pandemic has led to global price hikes for food and other commodities. This prevents families from buying the basic foodstuffs they need to survive. Meanwhile, conflicts in multiple locations around the world are uprooting millions of people and destroying homes, lives and livelihoods.Web3 Charity and World Vision will join hands to give food to families affected by famine in Somalia, especially children whose survival depends on humanitarian aid.",
      "An agreement with the UN World Food Programme means that every 1USDC donated by one person is multiplied by 27 .A donation of 1,000 USDC becomes 27 USDC with WEP‘s contribution.",
      "The impact of multiple conflicts, including the war in UKraine,the cradle of global wheat production, is creating a perfect storm for the world's most vulnerable children",
      "Meanwhile,conflicts in multilie locations around the world are uprooting millions of people and destroying homes ,lives and livelihoods.Did you know that 2/3 of all displaced people worldwide have been forced to flee their homes because of hunger?",
      "The aftermath of the COVID-19 pandemic has led global price hikes for food and other commodities.This prevents families from buying the basic foodstuffs they need to survive.",
      "The adverse effects of climate change,such as droughts,floods, extreme storms and unprdictable weather,have destroyed crops and incomes,even causing massive locust swaims in some countries to devastate harvests.",
      "Joining Forces to Combat Famine",
      "In response to an unprecedented hunger crisis affecting nearly 50 million people across 45 countries, web3  Charity has teamed up with World Vision. This partnership aims to provide crucial food assistance to those in  dire need, particularly in famine-stricken regions like Somalia. The collaboration seeks to address the critical issue of starvation, which has reached alarming proportions in various parts of Africa, Latin America, the Middle East, and Asia.",
      "Maximizing Impact with World Food Program",
      "Web3 Charity, in collaboration with World Vision, has joined forces with the UN World Food Programme (WFP) to leverage individual donations. Each 1 USDC contributed by an individual is amplified twenty-sevenfold, turning a 1,000 USDC donation into 27,000 USDC. These resources are crucial for delivering essential food aid to families in famine-stricken areas, especially children who rely on humanitarian support for survival. This initiative includes distributing food packages to families and providing therapeutic nourishment to the youngest children, addressing their immediate nutritional needs during this crisis.",
      "Our Partner",
      "World Vision is a Christian NGO that fights poverty and promotes sustainable development among vulnerable  communities, families, and children worldwide. Every minute, the organization provides water, food, and  empowerment to those in need. With a presence in almost 100 countries, World Vision can quickly respond to  emergencies, crises, and natural disasters. In 2021, the organization helped over 30.1 million people affected by emergencies in 52 countries. World Vision's accounts are audited annually for transparency.",
      "Support Indonesia's SLB Sri Soedewi School for Disadvantaged Children",
      "Join Gate Charity and Worid Vision, Support Somalia's Famine Victims",
      "Raised",
      "Donations",
      "Donate now",
      "Causes Supported",
      "Established in 1982, the SLB Sri Soedewi for the Differently-Abled houses over a hundred students, offering programs for various disabilities, including visual impairment, hearing impairment, intellectual disabilities, physical disabilities, and autism. It accommodates students across elementary, middle, and high school levels. The institution focuses on harnessing each student's unique potential and interests, nurturing their academic abilities and various skills with the aim of ensuring their holistic growth. The ultimate goal is to equip students with life skills that enable them to lead independent lives in society. Web3 Charity and SLB Sri Soedewi School for the Differently-Abled are committed to promoting inclusivity and providing a safe and supporting platform for every child.",
      "Every gift will make a meaningful difference in the lives of students and you also can be a part of that.",
      "Good quality of support and academic life for students with vision impairments can help to build self-esteem, help develop crucial friendships, and give faith that the future is  there to explore.",
      "The right school environment is an important long-term response to a child's social and  cultural needs. SLB Sri Soedewi provides  opportunities to develop emotional, linguistic  and academic support systems for students  to shine.",
      "Disability does not mean an obstacle to living life, not even a barrier for them to access and participate in some activities. It’s just that they have a different way of doing an activity that they can't do cause they're all special.",
      "The spirit of unity is one of the important factors to fostering inclusion and making a  wonderful smile, holding each other hand to  forge strong bonds.",
    ],
    5: [
      "Circle Releases First Annual “State of the USDC Economy” Report",
      "Below is the foreword from the report “State of the USDC Economy – Welcome  to the utility value phase of digital currency.”",
      "Download the full State of the USDC Economy report and see our agenda for World Economic Forum #WEF23 at Circle.com/Davos",
      "Dollar Digital Currencies Are Driving the Next Evolution of Money, Payments, and Finance",
      "Ten years ago, I co-founded Circle with the vision that traditional money could be brought natively onto the internet in the form of digital currency and made available on open, interoperable, global networks. Ushering in a world of frictionless value exchange that mirrored the frictionless information exchange and communications that marked the first decades of the internet has animated Circle’s work ever since.",
      "In 2013, the technological maturity, industry understanding, and regulatory awareness of digital currency and blockchain technology was virtually non-existent. Indeed, the necessary conditions for building a protocol for “dollars on the internet” did not even exist. We knew that this would be – at least – a ten to twenty year journey and that the impact of digital currency on the open internet would be even more profound than the internet of information.",
      "Now, ten years later, USD Coin (USDC), the world’s leading regulated dollar digital currency, is breaking into the mainstream. Across nearly every metric and trend, the USDC Economy is growing, healthy, and on its way to being a major force in facilitating economic activity at internet scale – reaching people and markets that would not be possible if to be banked depends on fixed infrastructure or financial walled gardens.",
      "While the era of speculation on bitcoin and other crypto assets has come through a tumultuous year, digital currencies such as USDC herald the necessary shift into the utility value phase of blockchains and programmable,composable always-on money. In the internet age, promoting these responsible innovations is not about competing with the traditional financial system, but rather completing unfinished work.",
      "To put the opportunity in perspective: dollar digital currencies like USDC could begin absorbing significant portions of the more than $2 trillion in payments industry revenue and become a new digital expression of the $21 trillion M2 money supply.",
      "Merchants, to take one example, understand the significance of this evolution. More than 85% are prioritizing enabling digital currency payments. While nascent, point-of-sale payments for USDC are here. And many traditional payment providers – including Checkout.com, Mastercard, Plaid, Stripe, Visa, Worldpay from FIS, among others – are building with USDC to make it as easy as possible for merchants to accept it.",
      "Public blockchain infrastructure is going through its “dial-up to broadband” upgrade, fostering near-instant, highly-secure, programmable and low-cost transactions. Web2 builders and developers are flocking to Web3, as more and more startups, enterprises, and traditional payments and financial services firms look to connect into the internet of value.",
      "Meanwhile, policymakers globally are sharpening their focus, ushering in much-needed regulation for how trusted digital currencies can be supervised – in a consistent and harmonized manner – by banking and payments regulators.",
      "Since our founding, Circle has been animated by the idea that a new global economic system could be built on an internet-native foundation – open, global and interoperable public internet infrastructure for the storage and transmission of value, and ultimately for the intermediation of capital more broadly. That vision is becoming a reality and 2023 will be an important year in the next evolution of money, payments, and finance.",
      "As our first State of the USDC Economy annual retrospective spells out, the green shoots of global utility value are sprouting up everywhere, catalyzing a wave of open, responsible financial services innovation that can improve pathways to prosperity, while protecting the integrity of the global financial system.",
    ],
    6: [
      "New for 2024 State of the USDC Economy",
      "A new internet financial system is emerging. Thanks to the power of USDC, blockchain networks, and some of the world ’ s best developers and technology companies, the financial system is being upgraded. It is faster, easier, and safer, making money open for all.",
      "Preface",
      "Over the past decade, the rise of blockchain technology has fueled a convergence of the internet and global  finance. This convergence is delivering a software upgrade for the financial system that is nothing short of transformational.",
      "This is what inspired Circle’s founding in 2013. Our focus has been infusing the core elements of open internet software into the world of money. Our mission is to use the internet’s openness and scale to raise global economic prosperity through the frictionless exchange of value – building what we believe is an emerging and new internet financial system.",
      "Software is poised to eat the world of finance, just as it evolved many legacy industries a generation ago. By making communications, entertainment, and other business activities faster, cheaper, and easier to access, costs dropped, barriers fell, and innovation flourished. We see the accelerating migration of commerce to the blockchain as the next wave of this decades-long trend of digital transformation, with a social and economic  impact potentially larger than that unleashed by the original internet in the 1990s and early 2000s.",
      "Consider that from 2000-2020, internet usage grew from less than 7% of the world’s population to 60%.1 As blockchain opens up aspects of traditional finance that have long been closed, we  anticipate a similar growth trajectory for blockchain-based commerce.",
      "In the coming years, we expect millions of businesses and billions of people to begin using this new, open Web3 internet layer to transact trillions of dollars in value. ",
      "Open money is a big idea, comprising economic, technical, and philosophical principles rooted in the internet ’ s design of openness and interoperability. By bringing the marginal cost of data dissemination to zero, the web made the sum of human knowledge accessible to all. By making it easy for  developers and creators to build, it enabled a rich ecosystem of sites and apps.",
      "Yet the project of the internet remains incomplete, because even though data moves around the world securely, instantly, and for free, the monetary superhighway on the internet is still being paved.",
      "Everything we do today is focused on building and accelerating this internet financial system.",
      "As this report details, it all starts with our efforts to build USDC into the largest, most  widely-used stablecoin network in the world. Through USDC, we are empowering almost anyone, anywhere, with the ability to access and use dollars on the internet. And we are turning USDC into an open platform for companies  and developers to build a new wave of apps that could rival and reshape today’s dominant internet and financial platforms.",
      "Last year marked a major turning point for the future of blockchain-based, internet native finance. Bad actors began to bear the full weight of their nefarious activity, frauds were uncovered, yet the underlying technology  matured, and mainstream businesses deepened their involvement with technologies like USDC.",
      "Leading payment companies and merchant settlement firms are now using USDC to solve real-world pain points.  Blockchain-based credit markets are using USDC to support real economic activity around the world. Fintechs and  neobanks that were born on the internet are turning to USDC as a means of moving dollars at the speed of internet commerce. Remittance companies and non-governmental organizations (NGOs) are building with USDC to tackle the hard problem of moving value quickly and fairly to people who need it. Traditional banks, capital  markets firms, and  custodians are supporting USDC as a way to grow their businesses.",
      "This momentum is why policymakers globally have made significant progress on issuing rules and guidelines for how to responsibly engage with payment stablecoins and open blockchains. They understand the magnitude of the opportunity and are determined to harness the benefits for their economies and societies. Together, innovators and policymakers are redefining the art of what is possible with money on the internet.",
      "While it is still early, there are unmistakable signs that USDC and the broader ecosystem are poised to reach  escape velocity – unleashing the power of open money around the world.",
      "I hope you enjoy the 2024 edition of State of the USDC Economy. My team and I look forward to discussing the findings with you.",
      "Sincerely,",
      "Co-Founder, Chairman and CEO",
      "Dollars for Anyone, Anywhere",
      "Open blockchain networks are powering a new form of money and redefining how people and businesses exchange value globally. These networks represent a new layer of financial technology that leverages the internet to reduce deeply embedded costs and friction, increase speed, and reimagine what is possible when exchanging value, be it sending digital  dollars across borders or interacting with tokenized versions of other traditional assets.",
      "This possibility impelled Circle’s launch of USDC in 2018. USDC is a payment stablecoin that combines the strength, trust, and network effects of the US dollar with the superpowers of the open internet.",
      "The near-term opportunities are significant and are starting to create outsized impact, driven by the dollar’s role at the center of global finance. The dollar comprises more than 90% of trade invoicing in Latin America, 74% in Asia Pacific, and 79% in the rest of the world outside of Europe.3 According to the Federal Reserve, nearly $1 trillion in US bills, including two-thirds of all $100 bills in existence, are held outside the US.4",
      "The dollar’s global role means the overwhelming majority of stablecoin activity today is denominated in dollars. USDC makes it possible for nearly anyone, anywhere, to access, hold, and transact digital dollars over the internet. USDC is already available in more than 190 countries and held in nearly 3 million on-chain wallets.5",
      "An open platform for money on the internet",
      "Over the past few years, USDC has experienced exponential growth as a stablecoin network. Since its introduction in 2018, USDC has been used to settle about $12 trillion in blockchain transactions. More people in  more countries are experiencing the benefits of unlocking digital dollars on the blockchain. This is spurring developers to build new apps, strengthening the ecosystem of services around USDC, and making it even easier to  obtain, send, and store.",
      "The growing use cases for blockchain technology recall the adoption curve of cloud computing. Early in its history, the cloud was seen as niche and risky, but now the cloud’s inherent advantages for business are clear. Today, corporations store 60% of their data in the cloud, with 9 in 10 large enterprises adopting a multi-cloud architecture.6 We are on the brink of the next phase in this evolution, as more businesses adopt Web3 on enterprise-grade blockchains. The internet financial system that Circle is helping build does not compete with the cloud; it helps complete it. Indeed, blockchain networks bring trustworthy data, transactions, and  computational power to the public internet.",
      "We are on the brink of the next phase in this evolution, as more businesses adopt Web3 on enterprise-grade blockchains.",
      "A dollar API for internet finance USDC is essentially a dollar API on the public internet. It is a well regulated, open-source building block that offers easy integration into  other fintech, bank, and digital currency projects to enable nearly-instant, nearly-free transactions denominated in the world’s most widely used currency. This creates tremendous opportunities at a time of rapid evolution in the finance tech  stack and the growing ubiquity of APIs.",
      "Built for blockchain interoperability  USDC is natively available on 15 blockchain networks. Over time, our plan is to make USDC available wherever developers are active and the right security measures are in place, so that it can easily and safely flow across the blockchain ecosystem.",
      "At the same time, we are making the complexities of blockchains fade into the background. The recently launched Cross-Chain Transfer Protocol (CCTP) helps reduce friction, increase safety and security, and reduce costs when sending USDC from one blockchain to another. In the near future, we aim to enable CCTP support for every blockchain where USDC is native, making it seamless to send dollars across the internet without the present challenges users have moving USDC from one  blockchain to another.",
      "Making blockchain easier for developers",
      "There are already tens of thousands of developers building on blockchain networks, and we expect this figure to multiply rapidly in the years to come. Circle offers programmable wallets, smart contract templates, and other services to support the growth of this ecosystem and simplify the process for enterprises and developers to build new apps on blockchain networks. Internet businesses that have achieved scale can simply put USDC and other digital assets into existing customer experiences without needing to rebuild them from the ground up.",
    ],
    7: [
      "Upload your dollars",
      "USDC is a digital dollar, also known as a stablecoin, that’s available 24/7 and moves at internet speed. USDC lives natively on the internet, running on many of the world’s most advanced blockchains. Billions of USDC  change hands every day1, and every digital dollar of USDC is always redeemable 1:1 for US dollars.",
      "Always on. Always open.",
      "USDC transcends borders and banking hours. As a digital dollar with global reach, USDC can be available whenever and wherever you need it. It’s easy to send USDC around the world, pay for goods and services, orsave forthe future. Anyone with an internet  connection can send, receive, and save USDC.",
      "Instant cash flow. All day.  Every day.",
      "No more waiting to finalize a transaction and withdraw cash. USDC makesthe concept ofsettlement times obsolete with payments that can circle the globe and land in your account asfast as email. Imagine FX trades of any size that can take secondsto complete – that’sthe power of USDC.",
      "Send money cost effectively",
      "It shouldn’t cost money to use money. Say goodbye to paying $50 to send a wire transfer – say hello to a fraction of a cent. Merchants can avoid incurring 2.9% fees on credit card payments, benefit from instant cash flow, and  pass back savingsto customers.",
      "Finance reimagined",
      "USDC takesthe abilities of cash to the next level. Unlock opportunitiesin crypto capital markets for trading, lending, borrowing, and fundraising with USDC – accessible globally. The possibilities are just beginning.",
      "An open money platform",
      "Build your product with an uber-money API that’s global, open-source, interoperable and free to use. Developers trust USDC as a core building block fortheir appsto deliver real-time payments, trading, and financialservicesto their users. Dream big with USDC.",
      "USDC is transforming finance",
      "USDC is part of a global ecosystem that spans traditional and crypto commerce. Created by Circle, a regulated fintech firm, USDC is a trusted, widely accepted, and highly liquid digital dollar.",
      "Stability you can trust",
      "USDC is always redeemable 1:1 for US dollars. USDC reserves are held in the management and custody of leading US financial institutions, including BlackRock and BNY Mellon.",
      "View monthly attestations, which provide assurance that the amount of USDC reserves greater than or equal to the amount in circulation each month, according to attestation standards set out by the American Institute of Certified Public Accountants (AICPA).",
      "Circle is regulated as a licensed money transmitter under US state law just like PayPal, Stripe, and Apple Pay. Circle’s financial statements are audited annually.",
      "USDC is everywhere",
      "Choose your journey into the world of digital dollars. Dive deeper to learn all the ways businesses, users, and developers use USDC – or get USDC now.",
      "Businesses",
      "Accelerate your global growth with lightning-fast USDC payments that deliver instant cash flow. Access USDC liquidity to trade, borrow, lend, and invest across crypto capital markets.The best way to access USDC",
      "Ecosystem",
      "Experience the next generation of money with USDC. Send, spend, save, and exchange digital dollars with anyone, anywhere in the world, 24/7 at internet speed.",
      "Developers",
      "Integrate USDC into your app today – USDC is a programmable digital dollar that’s open-source, composable, and accessible for anyone to build with.",
      "Why leaders choose USDC",
      "Whether it’s transparency or programmability, USDC is built for the future.",
      "See why innovators at Consensus 2023 build with Circle",
      "We asked our partners why they chose Circle and USDC and the resounding message was that Circle and USDC are built differently. Circle makes it fast and easy to use and implement, while the reserve sets USDC apart in the stablecoin industry.",
      "Discover how Circle Ventures founders use USDC",
      "While trust and transparency seem like table stakes, it's the primary reason founders of  companies in Circle Ventures’portfolio choose USDC. The other reasons are its programmable liquidity, almost-instant settlement speed, efficiency, and more.",
      "Setting a standard for stablecoin legislation",
      "USDC is often viewed as the blueprint to follow for designing a regulated digital currency. At the highest level, Circle works closely with US congressional leaders to align the open values of Web3 with the traditional financial system and help create our harmonious, interconnected future.",
      "Is USDC a cryptocurrency?",
      "USDC is a fully-reserved stablecoin, which is a type of cryptocurrency, or digital asset.  Unlike other cryptocurrencies that fluctuate in price, USDC is designed to maintain price equivalence to the US dollar. USDC is a stable store of value that benefits from the speed and security of blockchain technology.\n",
      "How can I trust that USC will always be redeemable 1:1 for US dollars?",
      "USDC is a digital dollar backed 100% by highly liquid cash and cash-equivalent assets and is always redeemable 1:1 for US dollars. A portion of the USDC reserve is invested in the Circle Reserve Fund (USDXX), an SEC-regulated money market fund managed by BlackRock. Daily independent third-party reporting on the portfolio is publicly available. See more on our Transparency page.\n",
      'What does it mean for USDC to be  "minted" or "burned"?',
      "Businesses can open a Circle Mint account to exchange US dollars for USDC. When a business deposits USD into their Circle Mint account, Circle issues the equivalent amount of USDC to the business. The process of issuing new USDC is known as “minting”. This process creates new USDC in circulation.  \n",
      "Similarly, when a business wants to exchange their USDC for US dollars, the business can deposit USDC into their Circle Mint account and request to receive US dollars for free. This process of redeeming USDC is known as “burning”. This process takes USDC out of circulation.  \n",
      "When US dollars are swapped for USDC on a digital asset exchange, the exchange will typically provide the balance of USDC it has on-hand to fulfill the swap. If the exchange needs more USDC to fulfill the swap, the exchange will often use its Circle Mint account  to mint more USDC.",
      "What do I need to access USDC?",
      "A fast and easy way for businesses to access USDC is to apply for a Circle Mint account.",
      "USDC can also be accessed quickly from digital asset exchanges like Coinbase, Crypto.com, and Kraken.",
      "Developers can start building on USDC today by accessing our GitHub repo. Additional  resources are available from our USDC for Developers page.",
      "Which blockchains is USC available on?",
      "USDC is natively accessible for 15 blockchains: Algorand, Arbitrum, Avalanche, Base, Ethereum, Flow, Hedera, NEAR, Noble, OP Mainnet, Polkadot, Polygon PoS, Solana, Stellar, and TRON – with more expected this year and beyond.",
      "USDC has also been bridged to many emerging blockchains by third-party bridges, resulting in the creation of bridged forms of USDC such as USDC.e.",
      "For more details, see our USDC for Developers page.",
      "How is USDC different from a CBC  (central bank digital currency)?",
      "USDC is issued by Circle, a company in the private sector, while a CBDC would be issued  by a government. While most CBDCs are only in the research phase, USDC exists today and is widely used by millions of people around the world. Circle has developed the technology to enable USDC to run on 11 public blockchains, with open-source and private market innovation driving rapid progress in dollar digital currency models. ",
      "Read more about Circle’s insights on the Federal Reserve’s CBDC discussion.",
    ],
    8: [
      "What is USD Coin (USDC)?",
      "A smart contract is a self-executing digital agreement that enables two or more parties to exchange money, property, shares, or anything of value in a transparent, conflict-free way while avoiding the need for a third party.",
      "To lay it out in the most simplest way, you can compare smart contracts to a vending machine for complex transactions.",
      "Smart contracts work by following simple “if/when…then…” statements that are written into code on a blockchain. A network of computers executes the actions when predetermined conditions have been met and verified. These actions could include releasing funds to the appropriate parties, registering a vehicle, sending notifications, or issuing a ticket. The blockchain is then updated when the transaction is completed. That means  the transaction cannot be changed, and only parties who have been granted permission can see the results.",
      "With smart contracts, you simply drop a bitcoin into the vending machine (i.e. ledger), and your escrow, deed, contract, goods, driver’ s licence, or whatever the contract is for, simply drops into your account. The smart  contract does all the work to determine whether the conditions of the order were satisfied.",
      "Smart contracts both define the rules and penalties around an agreement in the same way that a traditional  contract does, and also automatically enforces those obligations.",
      "How do smart contracts make money?",
      "There are two main on-chain methods for smart contract developers to monetize their work: initiate the Double Profit feature required to use the protocol, plus the blockchain sends 90% of the profits from the rewards received to the user who signs a smart contract at the blockchain, Smart Contracts = Savings Plan, where the blockchain sends a significant percentage to the blockchain storeroom, and then makes a profit from the sale of more as the price of the tokens goes up. Charge double commission for using smart contracts.",
      "To give you an example: a smart contract is similar to the principle of getting double interest on a fixed deposit at a bank",
      "Are smart contracts risky?",
      "Smart contracts are self-executing code on blockchain platforms that have transformed the industry by automating processes and enabling transactions without trust. As such, they are also valued by blockchain finance, and so far, we have not encountered any cases where smart contracts are risky.",
      "OK, so where do smart contracts exist? Where are they executed? And how do they work?",
      "WHAT YOU NEED TO KNOW",
      "Smart contracts are self-executing lines of code with the terms of an agreement between buyer and seller automatically verified and executed  via a computer network.",
      "Nick Szabo, an American computer scientist who invented a virtual currency called “Bit Gold” in 1998,1 defined smart contracts as computerized transaction protocols that execute terms of a contract.2",
      "Smart contracts deployed to blockchains render transactions traceable, transparent, and irreversible.",
      "Benefits of Smart Contracts Accuracy, Speed, and Efficiency",
      "The contract is immediately executed when a condition is met.",
      "Because smart contracts are digital and automated, there is no paperwork to deal with, and.",
      "No time was spent correcting errors that can occur when filling out documentation by hand.",
      "Trust and Transparency",
      "There’s no need to worry about information being tampered with for personal gain because there’s no third party engaged and  Encrypted transaction logs are exchanged among participants",
      "Smart contracts are verified, executed, and enforced by a computer program that runs on a blockchain network.  When both parties involved in the smart contract agree to its terms, the program will automatically execute. This eliminates the need for a third party, as the contract is verified and enforced by the blockchain network.",
      "Because smart contracts are executed by code rather than people, they remove the possibility of human error and can automate many tasks that would traditionally require human interaction.",
      "One of the best things about the blockchain is that, because it is a decentralized system that exists between all permitted parties, there’s no need to pay intermediaries (middlemen) and it saves you time and conflict.",
      "Sure, Blockchains have their problems, but they are undeniably faster, cheaper, and more secure than traditional systems. This is why we’re seeing more smart contracts being executed on different blockchain  networks including Ethereum, Solana, Tezos, Hyperledger, etc.",
      "Supercharge your wallets",
      "Create innovative experiences with NFTs, DeFi protocols, USDC, and more. Add more functionality to your wallets by integrating any smart contract.",
      "Increase loyalty and customer engagement",
      "Build new, unique experiences to engage with customers and increase loyalty, including NFT drops, loyalty points, or limited tokens that can be exchanged for in-person experiences.",
      "Swap, stake or bridge",
      "Bridge the gap to DeFi by integrating DeFi protocols seamlessly to your apps with embedded smart contracts.",
      "Scale with USDC",
      "Leverage smart contracts to easily enable and manage programmatic USDC transactions like payroll, subscriptions, or payments in your",
      "Bring Web3 interactions to your apps faster",
      "Vitalik Buterin on Smart Contracts",
      "As Vitalik Buterin, the 28-year-old founder of ethereum, explained it at a DC Blockchain Summit, “In a smart contract approach, an asset or currency is transferred into a program and the program runs this code and at some point it automatically validates a condition and it automatically determines whether the asset should go to one person or back to the other  person, or whether it should be immediately refunded to the person who sent it or some combination thereof.”",
      "In the meantime, the decentralized ledger also stores and replicates the document which gives it a certain security and immutability.",
      "Example: Escrow for Transactions",
      "Suppose you rent an apartment from me.",
      "You can do this through the blockchain by paying in cryptocurrency. You get a receipt which is held in our  virtual contract; I give you the digital entry key which comes to you by a specified date. If the key doesn’t come on time, the blockchain releases a refund.",
      "If I send the key before the rental date, the function holds it, releasing both the fee and key to you and me respectively when the date arrives.",
      "The system works on the If-Then premise and is witnessed by hundreds of people, so you can expect a faultless delivery.",
      "If I give you the key, I’m sure to be paid. If you send a certain amount in bitcoins, you receive the key. The  document is automatically canceled after the allotted time, and the code cannot be interfered with by either of us without the other knowing, since all participants are simultaneously alerted.",
      "Identifying Real-life Use Cases of Smart Contracts",
      "Jerry Cuomo, vice president for blockchain technologies at IBM, believes smart contracts can be used all across the chain, from financial services, to healthcare, to insurance. Here are some examples.",
      "DeFi Applications",
      "A major change in how we manage money is underway as we see DeFi Apps growing into the mainstream.",
      "Decentralized finance is an exit from traditional banking services and norms. This is a highly delicate transition but one that appears to be inevitable.",
      "We have the numbers to prove it: The annual transaction value of DeFi grew by 14x in 2020 and more than quadrupled to $112.07 billion in 2021  with over USD $20 billion in total value locked up in DeFi smart contracts alone.",
      "Smart contracts in DeFi are facilitating the exchange of goods, services, data, funds and so on. Users of  centralized financial institutions, such as banks and credit unions, rely on intermediaries to execute a  transaction. Whereas, DApps are using smart contracts to ensure that each action is genuine, transparent, and free of human error.",
      "In just a couple of years, NFTs have exploded in the market, with the Market cap closing in at a whopping $40.9 billion in 2021 as they turned out to be the most successful use-case of smart contracts.",
      "NFTs are created through a minting process that requires smart contracts deployed on the blockchain.",
      "A smart contract is a tool that allows implementing a sale agreement between the NFT owner and the buyer. The smart contract contains information on the NFT, such as the work’s creator, other parties who are entitled to  royalties each time the NFT is sold, and the work’s ownership history.",
      "The majority of NFTs are not recorded on the blockchain since keeping so much data on the blockchain is both  costly and energy intensive. As a result, smart contracts frequently include a link to the work they represent ,which can be viewed by only the owner.",
      "Government",
      "Insiders vouch that it is extremely hard for our voting system to be rigged, but nonetheless, smart contracts would allay all concerns by providing an infinitely more secure system. Ledger-protected votes would need to be decoded and require excessive computing power to access. No one has that much computing power, so it would need  God to hack the system!",
      "Secondly, smart contracts could hike low voter turnout. Much of the inertia comes from a fumbling system that includes lining up, showing your identity, and completing forms. With smart contracts, volunteers can transfer voting online and millennials will turn out en masse to vote for their POTUS.",
      "DeFi Applications",
      "For the 2020 US Presidential Elections, the Utah County successfully managed to collect votes from absentee  voters through a blockchain-powered mobile application called Voatz. The voter identity is kept hidden behind a unique signature/hash-value, and the relevant data also remains secure within the blockchain free of external threats.",
      "The blockchain not only provides a single ledger as a source of trust, but also shaves off possible snags in  communication and workflow because of its accuracy, transparency, and automated system.",
      "Ordinarily, business operations have to endure a back-and-forth, while waiting for approvals and for internal or external issues to sort themselves out. A blockchain ledger streamlines this. It also cuts out  discrepancies that typically occur with independent processing and that may lead to costly lawsuits and settlement delays.",
      "Case History",
      "In 2015, the Depository Trust & Clearing Corp. (DTCC) used a blockchain ledger to process more than $1.5 quadrillion worth of securities, representing 345 million transactions.",
      "Supply Chain Automobile",
      "There’s no doubt that we’re progressing from slothful pre-human vertebrates to super-smart robots. Think of a future where everything is automated. Google’s getting there with smartphones, smart glasses, and even smart cars. That’s where smart  contracts help.",
      "One example is the self-autonomous or self-parking vehicles, where smart contracts could put into play a sort  of ‘oracle’ that could detect who was at fault in a crash; the sensor or the driver, as well as countless other variables. Using smart contracts, an automobile insurance company could charge rates differently based on where,  and under which, conditions customers are operating their vehicles.",
      "Real Estate",
      "If you wanted to rent your apartment to someone, you’d need to pay a middleman such as Craigslist or a  newspaper to advertise, and then again you’d need to pay someone to confirm that the person paid rent and  followed through.",
      "A decentralized solution can help cut your costs. All you do is pay via cryptocurrency and encode your contract  on a smart contract. Everyone sees, and you accomplish automatic fulfillment. Brokers, real estate agents,  hard money lenders, and anyone associated with the property game can profit.",
      "Smart contracts are revolutionary in terms of transforming the current real estate practices.",
      "Smart contracts are replacing traditional contracts as the sole agreement between the seller and buyer. It automatically executes the requirements as soon as specific conditions of the contract are met.",
      "Smart contracts guarantee trust through a single version of the truth by establishing trust. All the parties including the bank, the agent, and the mortgage lender can sign an agreement via smart contracts. Because transactions are kept on a blockchain, this shared ledger enables the parties involved to look over the process at any moment and from anywhere.",
      "Healthcare",
      "Personal health records could be encoded and stored on the blockchain with a private key which would grant access only to specific individuals. The same strategy could be used to ensure that research is conducted via  HIPAA  laws (in a secure and confidential way).",
      "Receipts of surgeries could be stored on a blockchain and automatically sent to insurance providers as proof-of-delivery. The ledger, too, could be used for general healthcare management, such as supervising drugs,  regulation compliance, testing results, and managing healthcare supplies.",
      "Smart Contracts are Awesome!",
      "Here’s what smart contracts give you:",
      "Autonomy",
      "You’re the one making the agreement; there’s no need to rely on a broker, lawyer, or other intermediaries to  confirm. Incidentally, this also knocks out the danger of manipulation by a third party, since execution is managed automatically by the network,  rather than by one or more, possibly biased, individuals who may err.",
      "Trust",
      "Your documents are encrypted on a shared ledger. There’s no way that someone can say they lost it.",
      "Backup",
      "Imagine if your bank lost your savings account. On the blockchain, each and every one of your friends have your back. Your documents are duplicated many times over.",
      "Safety",
      "Cryptography, the encryption of websites, keeps your documents safe. There is no hacking. In fact, it would take an abnormally smart hacker to crack the code and infiltrate.",
      "Smart contracts offer security of a blockchain network. The technology itself is capable of providing the right kind of security to prevent  potential hack, however, writing a smart contract also equally accounts for ensuring its safety.",
      "Savings",
      "Smart contracts save you money since they knock out the presence of an intermediary. You would, for instance, have to pay a notary to witness your transaction.",
      "Accuracy",
      "Automated contracts are not only faster and cheaper but also avoid the errors that come from manually filling out heaps of forms.",
      "Smart Contractsare Awesome!",
      "Part of the future of smart contracts lies in entangling the issues we discussed earlier.",
      "In Cornell Tech, for instance, lawyers, who insist that smart contracts will enter our everyday life, have dedicated themselves to researching these concerns.",
      "Especially as the NFT Marketplaces have sprung up, there’s more at stake now than ever to solve the problematic aspects of smart contracts.",
      "Smart contracts may impact changes in certain industries, especially law. This is because lawyers will transfer from writing traditional contracts to producing standardized smart contract templates, similar to the  standardized traditional contracts that you’ll find on LegalZoom.",
      "Merchant acquirers, credit companies, and accountants may also employ smart contracts for tasks, such as real-time auditing and risk assessments.",
      "As we see more DeFi Apps being deployed around the world and NFT markets multiply in value, it’s evident that Smart contracts are merging into a hybrid of paper and digital content where contracts are verified via blockchain and substantiated by physical copy.",
      "Smart contracts will only continue to improve, and as the technology evolves, they will be increasingly trusted and efficient.",
      "We, at Blockgeeks, offer detailed courses on Blockchain technology including Ethereum Development covering all the important topics you need to get started with Smart Contracts！",
    ],
    9: [
      "NFTs are changing digital art,sports collectibles, media, and more.",
      "This ecosystem is revolutionizing how digital goods are",
      "created, distributed, and collected",
      "The NFT universe is growing rapidly with:",
      "Blockchains",
      "where NFTs  are hosted and minted.",
      "Marketplaces",
      "where NFTs are listed and traded.",
      "The NFT projects",
      "themselves, from generative art to  virtualreal estate  and more.",
      "But before exploring this ecosystem, let's define NFTs.",
      "Figuring Qut Fungibility",
      "WHAT ARE NFTS?",
      "NFTs are cryptographically secured tokens with their history of ownership and currentowners stored on a blockchain.",
      "They allow for robust primary and secondary markets for digital goods, cutting out middlemen and  enabling creators to make the most of their work.",
      "FUNGIBLE VS.",
      "NON-FUNGIBLE",
      "Fungibility is when a good's individual units are interchangeable  and indistinguishable in value.",
      "Currency like U.S. dollars are fungible. Every U.S. dollar is interchangeable andhas the same value as any other dollar.",
      "A Non-Fungible Token(NFT)or good has unique properties, making it have a different value from other similar tokens or goods.",
      "A house with a terrace is valued differently and isn't interchangeable for a house without a terrace.",
      "A terrace can be one of many different properties that make houses non-fungible and be worth different values.",
      "Similarly, a non-fungible token will have different artistic properties or other properties that render it  unique from other tokens, giving it a unique value.",
      'Cryptopunks are an NFT collection of 10,000 pixel art  "punks", with no two  punks being the same.',
      "The different attributes of varying rarities make certain punks rarer and more highly valued.",
      "While scarcity of attributes tends to dictate valuations, aesthetic and subjective preferences of investors and collectors can also influence valuations.",
      'There are 78 punks with the “buck teeth" attribute and 259 punks with the “hoodie” attribute, “hoodie”punks are worth triple the value of“buck teeth” punks.',
      "Source: Larya Labs",
      "Dollar values calculated with Eth price of $4,430",
      "Punk values as of Nov 29th,2021",
      "Mighty Minters",
      "THE BLOCKCHAIN PLATFORMS HOSTING NFTS",
      "Market cap",
      "Most of these blockchains use proof-of-stake consensus, which consumes significantly less energy compared to proof-of-work.",
      "Ethereum is set to transition toproof-of-stake sometime next year.",
      "Tezos has a dedicated NFT community, and was one of the first non-Ethereum NFT platforms",
      "Source: Messari.io",
      "As of Nov. 29, 2021",
      "EXPLORING ETHEREUM TOKEN STANDARDS",
      "Ethereum is the largest platform for NFTS,and NFTs make up a massive part of the network's transfers and  activity.",
      "Along with ERC 721 tokens:",
      "ERC-20 contract standard isused to make fungible tokens,and is the most commonly usedon the Ethereum networkoverall.",
      "ERC-1155 token standard was created by Enjin andcan create both fungible and non fungible assets.",
      "ERC-998 is an extension of the ERC-721 standard that enables NFTs to own NFTSand ERC-20 tokens.",
      "Ethereum's NFT token standards can interactwith dapps (decentralized applications) also built on the Ethereum  blockchain, allowing for seamless integrations.",
      "Ethereum Name Services NFTs change your Ethereum wallet's name to a chosen “.eth\" address for unique and more easily remembered address names.",
      "These NFTs are bought for yearly periods of time, similar to web domain names.",
      "An Open Sea of Secondary Marketplaces",
      "Currently there are a variety of secondary marketplaces to buy and sell NFTs.",
      "OpenSea has emerged as the dominant platform. with $1.9B of traded volume in November, 2021.",
      "Where NFTs are Today",
      "DIGITAL ART, COLLECTIBLES, GAMING, AND MUCH MORE",
      "NFTs can be more than just digital proof of ownership,and can be linked with physical assets or benefits.",
      "Kings of Leon's latest album When You See Yourself was released alongside varying limited-time NFTs tied to  physical assets and benefits.",
      "A$50(£35.70) NFT gives owners access to a digital download of the music, a physical vinyl of the album, and  access to digital goods.",
      "Six“golden ticket NFTs” give holders lifetime access to four front row seats at any of the band's shows.",
      "UK artist Damien Hirst's NFT collection The Currency explores how people value NFTs and physical art.",
      "The Currency is a set of 10,000 uniqueNFTs, each with corresponding physical works created in 2016",
      "The physical works are held in a vault in the UK, and NFT buyers have one year's time (until July 27,2022)to decide whether they will keep the NFT or the physical version,with the other version destroyed once the decision is made.",
      "Currently, the NFT space has three key branches,each pushing innovation in unique ways:",
      "From profile picture collections to generative art and collaborative music pieces, NFTs are enablingdigital art to flourish. Whether it's music or sports collectibles, there's more than just visual art NFTs.",
      "The NFT gaming space is rife with innovation, as player ownership of in-game assets and purchases and  play-to-earn models are being implemented and explored by various projects",
      "NFTs are redefining digital ownership,identities,and spaces, tokenizing digitalland in virtual reality, along  with the 3D avatars that will represent their owners. ",
      "GOING BEYOND THE DIGITAL.",
      "While NFTs have had their big bang moment, the boom is far from over.",
      "Along with these areas, NFTs are still being explored as a way to tokenize and enable the digital transfer of real-world assets like houses or physical art pieces.",
      "Almost any physical asset or good can be tokenized for easier proof and exchange of ownership, while also opening up possibilities like fractionalization, colateralization, and the utility smart contracts can provide.",
      "Mapping the NFT Ecosystem",
      "NFTs have been the hottest topic and frothiest market of 2021, with sales volumes increasing by 100x while also becoming a topic of discussion on evening talk shows.",
      "It took crypto nearly a decade to really penetrate the mainstream, but NFTs only needed a couple of years to capture people’s attention. As brands like Budweiser, Visa, and Adidas have purchased NFTs and entered the space, it’s clear that NFTs are more than just another hot new trend.",
      "This infographic sponsored by Next Decentrum defines NFTs and explores the flourishing ecosystem that has quickly grown around them. Discover  what non-fungible means, where NFTs are being minted and traded, and what the future holds for this asset class.",
      "What are NFTs, and What is Fungibility?",
      "NFTs are non-fungible tokens that have their history of ownership and current ownership cryptographically secured on a blockchain. These tokens can represent anything, whether it’s a piece of digital art in the form of a jpeg or a song as an mp3 file.",
      "By storing transactions of these tokens on a blockchain, we can have digital proof of ownership and markets for these digital goods without the fear of double spending or the tampering of past transactions and ownership.",
      "Figuring out Fungibility",
      "This all sounds pretty similar to cryptocurrencies, so what makes NFTs so special? Their non-fungibility. Unlike cryptocurrencies like bitcoin or ethereum, non-fungible tokens  represent goods or assets with unique properties and attributes, allowing them to have unique values even if they are part of the same collection.",
      "Fungible: A good with interchangeable units that are indistinguishable in value. Examples: U.S. dollars, bitcoin, arcade tokens",
      "Non-Fungible: A good with unique properties, giving it a unique value when  compared to similar goods. Examples: real estate, paintings, NFTs.",
      "The most popular NFT collection, Cryptopunks, is a collection of 10,000 pixel art “punks”, with varying attributes like different hats, glasses, hairstyles, and more. The random  combinations of attributes with differing scarcity results in each punk having a unique value.",
      "Scarcity and subjective aesthetic preferences drive valuations for cryptopunks and other NFTs, with other factors like their historical significance, and even the blockchain they’re hosted on affecting their value.",
      "The NFT-Capable Blockchains Compared",
      "There are many different blockchains that are able to mint and host NFTs, with Ethereum currently the largest and most used by market cap and transaction volume.",
      "Digital Art, Gaming, The Metaverse, and The Future of NFTs",
      "NFTs made a huge splash in 2021, giving creators digital and decentralized networks where they could host and exchange their work.",
      "Currently, digital-first use-cases are at the forefront of NFT development, with ownership of in-game assets or goods in the metaverse two of the primary use-cases being explored. However, NFTs can be used to tokenize physical assets like real estate, physical artwork, and much more, opening up near endless possibilities for their application.",
      "From removing the friction of paperwork and bureaucracy in today’s real estate exchanges to allowing for easy fractionalization of asset ownership, the tangible real-world use-cases of NFTs are just starting to be explored.",
    ],
    10: [
      "Whether you hate or love Web3, it is hot now because technology influencers like Venture capitalist Marc Andreesen, former Twitter CEO Jack Dorsey, and Tesla chief Elon Musk are recently debating it.[1] The Web3 advocates also believe it is reshaping the economic landscape. Gartner estimates that Web3 will increase global GDP by USD 1.76 trillion by 2030. [2] The rise of Web3 inevitably raises many legal issues such as virtual governance, data privacy, IP protection, regulatory compliance, and infringement. These also provide plenty of business opportunities for technology lawyers and law firms to join this fast-evolving area of law.",
      "What is Web3?",
      "Web3 is a new platform built on decentralized and blockchain technology owned by the  builders and users where people can bind cryptocurrencies, NFTs, and metaverses together into a new platform. On Web3, individuals can provide services directly to each user and trade items of value that inherently exist within the system. An example of a Web3 application/network/platform might be a metaverse (an online, 3-dimensional universe that combines multiple virtual spaces in which users will be able to collaborate, meet, play games, and socialize) that works on a decentralized blockchain instead of using a bank.",
      "Does Web3 need legal protection?",
      "There is a misconception that a decentralized platform is unregulated. In addition, digital assets are rapidly growing in many parts of the world where local regulators have introduced laws and regulations governing transactions. For example, the US Treasury’s Financial Crimes Enforcement Network (FinCEN) is developing rules to track cryptocurrency exchanges and report suspicious activities. In addition, Australia and Canada consider Bitcoin a financial asset with a value that can be taxed. In Asia, Japan’s parliament passed a bill into law in June to regulate stablecoins or cryptocurrencies for investor protection.",
      "Legal worries will increase in the metaverse as it is growing dramatically around the world. In general, many users believe NFTs offer undeniable proof of ownership. However,  things may not be as simple as we thought. For example the property ownership, according  to João Marinotti, Professor of Law at Indiana University, as virtual land ownership falls under contract law rather than property law. Also, purchasing an NFT doesn’t give users ownership over the digital asset.\n",
      "Although Web3 is an entirely new and complicated landscape, a new kind of legal protection is needed when things go wrong. Cryptocurrency litigation is soaring. Disputes arise with NFTs related to licensing and joint ownership of an asset. Companies nowadays use metaverses as part of their business and marketing strategies that raise many legal issues concerning governance, IP protection, and infringement.\n",
      "Opportunities in Web3",
      "If any technology landscape wants to grow to its full potential, it needs to incorporate a proper legal framework that includes set rights and obligations to protect all parties’ interests. Legal issues we need to address include taxation, virtual governance, regulatory challenges, licensing and ownership, transaction tracking and reporting, data privacy, and IP protection.\n",
      "To avoid being left behind, traditional law firms, lawyers, and law students need to understand the development of Web3 technologies and the changing laws and regulations related to Web3 and regional governments.",
    ],
    11: [
      "How Cryptocurrency Debit Cards Work",
      "Crypto debit cards are used by companies that specialize in cryptocurrency financial services, such as Coin. These companies have deals with payment networks such as Visa and Mastercard that allow users to use cryptocurrencies where traditional payment systems are accepted.\n",
      "This process ensures your transactions are secure and transparent. What's more, it offers fast conversion rates without waiting for hours or days for conversions to complete.\n",
      "Choose the right cryptocurrency debit card",
      "In this part of the article, I will show you how to get a crypto debit card for seamless daily transactions. Convert your cryptocurrency to fiat and shop anywhere that accepts valid debit cards. I will also describe to you the factors you need to pay attention to when getting the best crypto debit card from several options on the market:",
      "•Supported Cryptocurrencies: To get the best crypto debit card, you must make sure that the card supports your cryptocurrency.",
      "• Fees: Cryptocurrency debit cards often charge various fees, such as loading fees, transaction fees, and ATM withdrawal fees. Before choosing a card, compare the fees charged by different cards.\n",
      "• Rewards: Choose a cryptocurrency debit card with a cash back rewards program to maximize your spending. Search, compare and choose the best cryptocurrency debit cards with the highest cash back.\n",
      "•Security: Cryptocurrency debit cards use a variety of security features to protect your cryptocurrencies. Choose the best crypto debit card that offers strong security features.",
      "•Customer Support: To get the best crypto wallet with debit card, one of the main points you need to consider is the quality of the support; if the support cannot help you when you face a problem, you will lose money and time, so please Be sure to choose valid support.\n",
      "They are an innovative solution that allows you to use your digital assets in real-world transactions. By utilizing blockchain technology, these cards can facilitate secure and transparent transactions that can be used anywhere major debit cards are accepted.\n",
      "One of America’s biggest crypto-exchange said Friday it was working “closely” with a global  payments process on seamless digital asset transfers and adoption.",
      "Coinbase, the San Francisco-based crypto exchange known for its regulation-first approach to cryptocurrencies, said today it was “proud to work with innovators like Visa” to bolster digital currency adoption:\n",
      "The exchange said firms like Visa are helping bridge the gap between crypto and traditional finance. This creates an environment ripe for connecting cryptocurrencies to its global network while bringing together leaders in the [traditional and crypto] space.\n",
      "Visa lays out digital currency approach",
      "Payments giant Visa, valued at 420 billion dollars and performing over 25,000 transactions per second on its network, laid out its plans for cryptocurrencies in a blog post earlier this week.\n",
      "Called “Advancing our approach to digital currency,” the firm put digital assets at the center of a new, digital-first strategy to tap more markets and improve financial inclusivity across the world.\n",
      "Visa notes it is actively working with licensed and regulated digital currency platforms like Coinbase to provide a bridge between digital currencies and our existing global network of 61 million merchants.\n",
      "The firm points out over 25 digital currency wallets are currently linked to the Visa network, “giving users an easy way to spend from their digital currency balance using a Visa debit or prepaid credential.” \n",
      "Other developments include innovations like Visa Direct — the firm’s instant fiat-to-crypto bridge that swiftly converts consumers’ digital currencies and adds those to push those their Visa credentials. \n",
      "Visa said these efforts make the brand optimal for adoption by digital currency wallets — the latter“eager to deepen their value to users by making it quicker and easier to spend digital currency worldwide.” \n",
      "Stablecoins a big part of the future  economy",
      "In its broader strategy for cryptocurrency rollouts in the public sector, Visa said it was liaising with policymakers and regulatory organizations to apprise firms on the benefits of digital currencies.\n",
      "Transact with a cryptocurrency debit card",
      "Using a crypto wallet with a debit card is an easy way to spend your cryptocurrencies without having to convert and wait for the process to be over. Your provider automatically converts your cryptocurrency into fiat currency and transfers it.",
      "Use a cryptocurrency debit card for  everyday transactions",
      "They work like traditional debit cards, allowing you to use them anywhere Visa or Mastercard is accepted. They facilitate everyday transactions and offer advantages over traditional cards, such as cash back or crypto points rewards programs.\n",
      "Security Features of Cryptocurrency  Debit Cards",
      "Crypto debit cards use encryption, two-factor authentication, fraud protection, FDIC insurance, and follow the same security regulations as traditional cards to protect your encryption and personal information.\n",
      "Benefits of using a cryptocurrency debit card",
      "If you get a crypto debit card, you get many advantages such as convenience, rewards,  and complete control over your assets due to decentralization.",
    ],
    12: [
      "USD Coin (USDC) Savings  Account: EarnInterest on Crypto",
      "Web3 has a large and growing list of options to choose from for crypto savings accounts. These low risk, high yield savings accounts help users earn interest on crypto up to 200% per month + compounding interest. USD Coin (USDC) is one of the most used stablecoins in circulation. Here’s some information about it and additional facts  on how to easily accumulate crypto interest on YouHodler.",
      "What is USD Coin (USDC)?; How to earn crypto",
      "Unlike many other cryptocurrencies, USDC is a unique type of cryptocurrency called a “stablecoin.” USDC is backed by the US Dollar at a ratio of 1:1. Hence, you can always redeem 1 USD Coin for $1.00 US Dollar. This  results in a far more stable asset then something like Bitcoin or other cryptocurrencies making it a safe place for asset storage.",
      "USDC is an Ethereum based token meaning you can store it on an Ethereum-compatible wallet like YouHodler’s USDC wallet for example. The team behind USDC designed this stable coin to help users move dollars easily around the world from crypto wallets to businesses, exchanges and other people. YouHodler added it to our lineup as another great option earn crypto interest on the platform.",
      "USDC savings account: Why earn interest on crypto with web3",
      "Web3 provides a variety of ways to profit from digital assets and with crypto savings accounts, users can  unlock high yield, low risk investment tools. Here are a few reasons to earn interest on crypto with Web3:",
      'Earn up to 2.6% and 10% USDC interest on cryptocurrencies: Earn 2.6% and 10% USDC daily interest per year on  Web3. This is much higher than any "high yield" traditional bank savings accounts and other investments. Web3 offers its clients the best USDC exchange rates on the market.',
      "Keeping Assets Safe: All cryptocurrency funds on Web3 are protected using industry best practices when it comes to storage. Funds are always 100% stored in hot wallets. We use a hybrid storage method of hot wallets and cold wallets to ensure that user funds are protected to the greatest extent.",
      "Cryptocurrency interest is paid every 4 hours without any fees: at the end of each 4-hour settlement period, all cryptocurrency interest earned will be credited to the user's savings account deposit. Users can choose to  release and withdraw from a savings account to a wallet, or keep funds in the account to earn more cryptocurrency interest during the next billing cycle. (It should be noted that the number of withdrawals of interest from the  account is 3 times a day, and each withdrawal must be greater than 1 USDC)",
      "Explore other ways to earn cryptocurrency interest  with Web3",
      "USDC Coin Interest is just one option for earning interest on cryptocurrencies through Web3. Visit our Earn Interest page to see a variety of stablecoins and cryptocurrency stablecoins to choose from. Earn interest on Bitcoin, Binance Coin, and more. Earn cryptocurrency with a savings account and discover a more efficient way to HODL.\n",
      "USDC Savings Wallet App That Pays Interest",
      "Wwb3 Wallet App is the only app that allows you to store USD Coins (USDC) just like in a regular wallet, plus earn interest on your USDC wallet balance every 4 hours.\n",
      "How USDC interest works",
      "You can earn interest on USDC just like you would with a traditional bank savings account, but there is one key difference. We offer you an interest rate of up to 10% on ETH, instead of the bank's near-zero interest rate.\n",
      "How to Earn Free USDC",
      "It's easy! Simply deposit USDC to your Web3 wallet and you can earn free ETH every 4 hours.",
      "Why is the interest earned in ETH?",
      "After you store USDC in your wallet,the interest you get every 4 hours is the cryptocurrency ETH. However, the ETH you get in the blockchain cannot be withdrawn directly to your wallet. You need to log in to the blockchain Web3 The ETH you get from winning in your account must be converted into USDC before it can be withdrawn to\n   your wallet. The following is a detailed explanation of converting the ETH you get from winning in your Web3 account into USDC.",
      " Before you withdraw interest, you first need to enter your Web3 account, then click Exchange, and then click Convert all to convert all.",
      "The next step is to withdraw the USDC you exchanged from your Web3 account to your wallet. The following is a detailed explanation of withdrawing USDC.",
      "You now need to click on Withdraw, then click on Total balance, and finally click on Confirm. Your withdrawal of profits is completed. Normal withdrawal of USDC will arrive within 10 minutes. If the network is congested, the arrival will be delayed. ",
      "Accumulated interest USDC  and ETH",
      "Yes, if you hold USDC as well as ETH in your Web3 account, you will earn accumulated interest. We calculate compound interest every 4 hours.",
      "USDC daily yield and yield",
      "Compared with other platforms, we provide days income instead of APR. The days interest rate pays you interest without the effect of compound interest. So, with Web3 days, you will get more USDC at the end of the month due to accumulated interest.\n",
      "USDC Savings Calculator",
      "Simply use our USDC Savings Calculator to see what you would earn after holding on Web3 for 3, 6 or 12 months.",
    ],
    13: [
      "The 3,000,000 ETH in this issue is obtained from the DeFi Loan Agreement signed between the miners and the blockchain. According to the DeFi Loan Agreement, when the DeFi Loan Agreement signed between the miners and the blockchain reaches 3,000,000 ETH the blockchain has the right to flow the 3,000,000 ETH in the way of the blockchain project, to keep the data of the blockchain as well as to show the value of the ETH itself, therefore the blockchain will pay the interest rate according to the principal amount of USDC stored by the user, and the user who stores USDC will get the interest in ETH.\n",
      "1 Where does the interest ETH come from?",
      "Miners will borrow the ETH they mined from the blockchain through the DeFi protocol lending, at present, the blockchain has already received 3 million ETH from miners' DeFi protocol lending, so the blockchain initiated the DeFi project for storing USDC for profit in conjunction with the major Web3 wallets, till these 3 million ETH are sent out by way of interest, then it can enter the next blockchain storage profit project, according to past experience, these 3 million ETH will be distributed in 3-5 months.",
      "For example, if a miner's DeFi loan agreement with the blockchain reaches 3 million ETH, then the blockchain has the right to combine the miner's pledged ETH with the major Web3 wallets to make it liquid to reflect the value of ETH itself.",
      "In addition, we need to remind you that when you become a current blockchain VIP storage user, you will have the opportunity to enjoy being invited by the blockchain to enter the next node discovered by the blockchain to continue to store for profit.\n",
      "DeFi ETH Lending",
      "Normally higher and automatically taken out of your APY",
      "Determined by the  platform — fixed",
      "Centralized, opaque   platforms with no insight into internal workings",
      "Low — can be done in a few clicks",
      "Hacking, Platform Insolvency, Bankruptcy ",
      "Fees are usually lower and are explicitly  listed alongside APY",
      "Determined by the market — always fluctuating",
      "Decentralized and transparent platforms whose code is often open source",
      "High — some familiarity with DeFi is necessary to complete the process",
      "Hacking",
      "The three platforms covered above are centralized platforms that participate in centralized finance (CeFi). The other option is decentralized finance (DeFi) ETH lending protocols that act as a crypto money market for lenders and borrowers.\n",
      "DeFi platforms maintain automated markets using code to determine real-time lending and borrowing rates. As a result, ETH lending rates on DeFi fluctuate based on supply and demand.",
      "The advantage of DeFi is that platform fees are much lower. And because DeFi protocols  normally use open-source code and are managed by communities of token holders, DeFi protocols are much more transparent than the CeFi lenders.",
      "DeFi lending can be profitable. However, it’s often more complex, so it’s generally more  suitable for experienced traders.",
      "Q: Where do miners get ETH?",
      "Answer: Miners obtained ETH through liquidity mining.",
      "DeFi liquidity mining is a process in which users provide liquidity to decentralized finance (DeFi) platforms in exchange for rewards. Let's take a closer look at this article for a better understanding.",
      "What is DeFi Liquidity Mining?",
      "DeFi liquidity mining is a process in which users provide liquidity to decentralized finance (DeFi)  platforms in exchange for rewards. Liquidity providers (LPs) can earn fees on the transactions that take place in the pool, as well as tokens from the DeFi platform itself .\n",
      "How Does DeFi Liquidity Mining Work?",
      "DeFi Liquidity Mining works by using a process called automated market making (AMM). AMMs are smart contracts that create liquidity pools for different tokens. LPs can add their tokens to a liquidity pool and earn fees on the transactions that time in the pool.\n",
      "The fees are paid in the tokens that are being traded in the pool. For example, if you add ETH and USDC to a liquidity pool, you will earn fees in ETH and USDC. You will also earn tokens from the DeFi platform itself. These tokens are often called governance tokens, and they give you voting rights on the platform.\n",
      "What Are the Benefits of DeFi  Liquidity Mining?",
      "There are several benefits to participating in DeFi liquidity mining. First, you can earn passive income by providing liquidity to DeFi platforms. Second, you can earn tokens from the DeFi platform itself, which can have the potential to appreciate in value. Third, you can help to make DeFi platforms more liquid, which can benefit all users of the platform.\n",
      "Is DeFi Liquidity Mining Safe?",
      "DeFi liquidity mining is not without its risks, but it can be a profitable way to earn passive income if you are willing to take those risks. It is important to do your research before participating in any DeFi liquidity mining project and to only invest money that you can afford to lose.\n",
      "What is DeFi Liquidity Mining? How Does It Work? - I hope this article was informative.",
    ],
  },
  check: {
    title: "DAILY Sign-in",
    rules: "RULES",
    point: "Points Accumulation",
    signin: "Sign-in",
    days: "You have signed in for consecutive ${consecutiveDays} days",
    reSigning: "Re-signing",
    day10: "10 Days",
    day90: "90 Days",
    rewards: "Rewards",
    day10Rewards: "Consecutive 10 days Sign-in rewards",
    getIt: "Get it",
    exchange: "Exchange",
    exchangeSub: "Points exchange",
    points: "points",
    share: "Sharing tasks",
    shareSub: "Sharing tasks rewards",
    rank: "Points ranking list",
  },
};
