export default {
    tongzhi: "Benachrichtigung",
    "zt": {
        "st_0": "In Überprüfung",
        "st_1": "In Bearbeitung",
        "st_2": "Eingelöst",
        "st_3": "Prüfung abgelehnt",
        "st_4":"Abgelaufen"
    },
    "langName": "Deutsch",
    "common": {
        "error": "Systemfehler, bitte versuchen Sie es erneut!",
        "home": "Startseite",
        "detail": "Details",
        "market": "Markt",
        "trade": "Handel",
        "options": "Optionen",
        "wallets": "Wallets",
        "notMore": "Keine weiteren Aufzeichnungen",
        "reconnect": "Stelle Verbindung wieder her",
        "noData": "Momentan keine Inhalte",
        "loading": "Lädt...",
        "liveChat": "Live Chat",
        "help": "Hilfe",
        "lang": "Sprache",
        "notify": [
            "Bitte öffnen Sie diesen Link in Ihrem Wallet!",
            "Erfolgreich eingereicht",
            "Sie haben diesen Voucher bereits abgeholt!",
            "Autorisierung fehlgeschlagen"
        ]
    },
    "header": {
        "voucher": "Voucher abholen",
        "connectWallet": "Anmelden",
        "receive": "Erhaltserklärung",
        "receiveInfo": "Sie müssen eine Miner-Gebühr bezahlen, um den Voucher abzuholen. Stellen Sie sicher, dass Ihr Wallet genügend ETH für die Miner-Gebühren hat.",
        "receiveBtn": "Abholen",
        "reward": "Sie haben eine Belohnung gewonnen"
    },
    "share": {
        "shareFriend": "Mit Freunden teilen",
        "info": "Senden Sie Ihren Einladung-Link, Freunde können über diesen Link dem Node beitreten, und Sie erhalten großzügige Token-Belohnungen.",
        "myShareLink": "Mein Share-Link",
        "copy": "Kopieren"
    },
    "ad": {
        "plan": "Sparplan:",
        "regular": "Regelmäßige Ersparnisse:"
    },
    "output": {
        "interest": "Zinsen",
        "desc": "Flexible Zinserträge",
        "output": "Ertrag",
        "address": "Adresse",
        "quantity": "Menge"
    },
    "order": {
        "order": "Bestellung",
        "amount": "Betrag",
        "available": "Verfügbar",
        "all": "Alle",
        "confirm": "Bestätigen",
        "period": "Zeitraum",
        "requird": "Betragsanforderung",
        "rate": "Wechselkurs (ETH)",
        "additional": "Zusätzliche Belohnung",
        "less": "Mindestens 1 USDC",
        "participants": "Teilnehmer",
        "total": "Gesamtbetrag (USDC)"
    },
    "chart": {
        "loading": "Lädt",
        "min1": "1 Minute",
        "min15": "15 Minuten",
        "min5": "5 Minuten",
        "min30": "30 Minuten",
        "hour1": "1 Stunde",
        "hour4": "4 Stunden",
        "day1": "1 Tag",
        "week1": "1 Woche",
        "mon1": "1 Monat",
        "more": "Mehr",
        "price": "Preis",
        "open": "Öffnen",
        "amount": "Menge",
        "high": "Hoch",
        "low": "Tief",
        "buy": "Kaufen",
        "sell": "Verkaufen",
        "buyLong": "Kaufen/Long",
        "sellShort": "Verkaufen/Short",
        "main": "Haupt",
        "side": "Neben",
        "setting": "Einstellungen",
        "history": "Bestand"
    },
    "futureOrder": {
        "title": "Bestellung bestätigen",
        "name": "Name",
        "direction": "Richtung",
        "price": "Preis",
        "info": "Lieferzeit (Wischen Sie nach links, um mehr Gewinn zu erzielen)",
        "amount": "Menge (USDC)",
        "available": "Verfügbares USDC",
        "fee": "Gebühr",
        "second": "Sekunde",
        "buy": "Kaufen",
        "sell": "Verkaufen",
        "minAount": "Mindestmenge von ${minAmount}-${maxAmount}",
        "startPrice": "Bestellpreis",
        "expectedProfits": "Erwartete Gewinne",
        "finish": "Abgelaufen",
        "currentPrice": "Aktueller Preis",
        "completeResult": "Ergebnis",
        "confirmOrder": "Bestätigen",
        "continueBuy": "Weiter einkaufen"
    },
    "futureHistory": {
        "title": "Bestellhistorie",
        "direction": "Richtung",
        "amount": "Menge",
        "buyPrice": "Bestellpreis",
        "OrderPrice": "Endpreis",
        "deliveryTime": "Typ",
        "pl": "Gewinn/Verlust",
        "sellTime": "Abschlusszeit"
    },
    "market": {
        "currency24h": "24h Währung",
        "volume": "Volumen",
        "liquidity": "Liquidität"
    },
    "user": {
        "title": "Glückwunsch an die folgenden neuen Nutzer, die dem Mining-Pool beigetreten sind.",
        "nation": "Nutzer-Nationalität",
        "address": "Öffentliche Adresse",
        "Hashrate": "Wallet-Hashrate"
    },
    "rule": {
        "daily": "Täglich",
        "interest": "Zinsen",
        "rule": "Regeln",
        "flexible": "Flexibel",
        "payments": "Zinsen werden alle 4 Stunden gezahlt, insgesamt 6 Zahlungen pro Tag.",
        "safety": "Sicherheit der Vermögenswerte",
        "less": "Etwa 80% der USDC-Reservemittel bestehen aus US-amerikanischen kurzfristigen Staatsanleihen, deren Laufzeit nicht mehr als 3 Monate beträgt.",
        "economy": "Diese gelten als eines der sichersten Vermögenswerte der Welt und werden vom 'vollständigen Vertrauen und der Kreditwürdigkeit' der US-Regierung gestützt, die selbst von der weltweit größten Wirtschaft unterstützt wird.",
        "fees": "Gebühren",
        "additional": "Keine zusätzlichen Gebühren für die Abhebung von Zinsen."
    },
    "report": {
        "title": "Unterstützt Web3 Wallets für DApps",
        "subTitle": ["Mobil", "Hardware", "Smart Contracts", "Andere"]
    },
    "help": {
        "article": [
            {
                "title": "Haben Sie Ihre Daten",
                "details": [
                    "Mit dieser Ethereum-basierten Blockchain-Software-Wallet sind nur Sie in der Lage, Ihre Daten zu besitzen und lokal zu speichern. Sie können über Browser-Erweiterungen oder mobile Apps auf Ihr Wallet zugreifen."
                ]
            },
            {
                "title": "Was sind die Vorteile von Web 3.0?",
                "details": [
                    "Web 3.0 basiert auf den zentralen Konzepten der Dezentralisierung, Offenheit und größerer Benutzerfreundlichkeit.",
                    "In einem dezentralen System können Informationen nach ihrem Inhalt abgerufen werden, was bedeutet, dass sie an mehreren Orten gleichzeitig gespeichert werden können und somit dezentralisiert sind. Dies wird die massiven Datensätze, die derzeit von den Internet-Giganten gehalten werden, aufbrechen und gleichzeitig skalierbar, sicher und zugänglich machen."
                ]
            },
            {
                "title": "Was sind dezentrale Anwendungen (dApps)?",
                "details": [
                    "Dezentrale Anwendungen (dApps) sind Programme, die auf einer Blockchain oder auf Peer-to-Peer (P2P) Netzwerkknoten laufen und nicht auf einem zentralen Computer basieren.",
                    "Einige Vorteile von dApps sind, dass sie finanziell effizienter sind, da sie ohne Mittelsmänner betrieben werden, die Gewinne aus Transaktionen ziehen. Benutzer müssen jedoch für die Inhalte, die sie teilen oder konsumieren, die rechtliche und regulatorische Verantwortung übernehmen."
                ]
            },
            {
                "title": "Smart Contracts",
                "details": [
                    "Dezentrale Anwendungen (dApps) sind Programme, die auf einer Blockchain oder auf Peer-to-Peer (P2P) Netzwerkknoten laufen und nicht auf einem zentralen Computer basieren.",
                    "Einige Vorteile von dApps sind, dass sie finanziell effizienter sind, da sie ohne Mittelsmänner betrieben werden, die Gewinne aus Transaktionen ziehen. Benutzer müssen jedoch für die Inhalte, die sie teilen oder konsumieren, die rechtliche und regulatorische Verantwortung übernehmen."
                ]
            }
        ],
        "center": "Hilfezentrum",
        "hope": "Hoffentlich hilft es Ihnen weiter"
    },
    "partner": {
        "title1": "Audit-Bericht",
        "detail1": "Wir haben einen sicheren Audit-Bericht",
        "title2": "Partner",
        "detail2": "Unsere Geschäftspartner"
    },
    "trading": {
        "titles": ["Gesamt-Handelsvolumen", "Offene Positionen", "Gesamt-Nutzerzahl"],
        "currency": "Beliebte Währungen",
        "banners": ["Liquiditätssparen USDC verdienen", "Unsere Vorteile"]
    },
    "poolIndex": {
        "tab1": "Pool-Daten",
        "tab2": "Plan",
        "tab3": "Steuern",
        "tab4": "Konto",
        "tab5": "Überweisung",
        "transfer": "Überweise USDC vom Wallet zum Guthaben",
        "button": "Überweisen"
    },
    "liquidity": {
        "type": "Typ",
        "interest": "Zinsen",
        "period": "Zeitraum",
        "amountRequire": "Mengenanforderung (USDC)",
        "rate": "Wechselkurs (ETH)",
        "additionalReward": "Zusätzliche Belohnung",
        "days": "Tage",
        "subscription": "Staking",
        "redeem": "Einlösen",
        "record": "Aufzeichnung",
        "participants": "Teilnehmer",
        "totalAmount": "Gesamtbetrag (USDC)",
        "interestRate": "Zinssatz",
        "amount": "Betrag (USDC)",
        "collectionAmount": "Gesammelter Betrag",
        "reward": "Belohnung",
        "addReward": "Zusätzliche Belohnung",
        "endTime": "Endzeit",
        "status": "Status",
        "stakingAmount": "Staking-Betrag",
        "unCollected": "Ungesammelter Betrag"
    },
    "pool": {
        "total": "Gesamtdaten zu Dividenden",
        "participant": "Teilnehmer",
        "userRevenue": "Benutzer-Einnahmen",
        "poolData": "Pool-Daten"
    },
    "message": {
        "title": "Nachricht",
        "ok": "OK"
    },
    "lottery": {
        "title": "Blind Box USDC Pump",
        "btn": "Zufälliger Eintrag",
        "stake": "Staken",
        "connectWallet": "Wallet verbinden",
        "voucher": "Voucher abholen",
        "tab1": "Pool-Daten",
        "tab2": "Plan",
        "tab3": "Steuern",
        "tab4": "Konto",
        "ok": "OK",
        "lotteryChance": "Glückwunsch! Sie haben die Chance auf ein ETH-Gewinnspiel gewonnen!",
        "lotteryBtn": "Beginnen Sie das Gewinnspiel",
        "congratulations": "Herzlichen Glückwunsch",
        "experienceUsdt1": "Herzlichen Glückwunsch! Sie haben",
        "experienceUsdt2": "USDC Erfahrungsguthaben erhalten. Die aktuellen Zinssätze sind",
        "experienceUsdt3": "ETH. Das Verfallsdatum dieser Erfahrung ist"
    },
    "my": {
        "myAccount": "Mein Konto",
        "totalOutput": "Gesamtoutput",
        "walletBalance": "Wallet-Balance",
        "exchangeable": "Einlösbar",
        "exchange": "Einlösen",
        "withdraw": "Abheben",
        "record": "Aufzeichnungen"
    },
    "exchange": {
        "redeem": "Alles einlösen",
        "exchange": "Einlösen",
        "convert": "ETH in USDC umwandeln"
    },
    "withdraw": {
        "total": "Gesamtbetrag",
        "confirm": "Bestätigen",
        "tip": "Ihre Abhebung wird innerhalb von 24 Stunden an Ihre USDC-Wallet-Adresse gesendet"
    },
    "record": {
        "time": "Zeit",
        "quantity": "Menge",
        "status": "Status",
        "output": "Ertrag",
        "exchange": "Einlösen",
        "withdraw": "Abheben",
        "interest": "Zinsen",
        "rebate": "Rabatt"
    },
    "loan": {
        "title": "Kreditsumme",
        "dueDate": "Fälligkeitsdatum",
        "tips": ["Einfache Dokumenten-Kredite", "einfache Datei"],
        "form": "Kreditformular",
        "download": "Herunterladen",
        "print": "Formular drucken und ausfüllen",
        "upload": "Foto des Formulars hochladen",
        "btn": "Hochladen",
        "file": "Datei herunterladen",
        "cancel": "Abbrechen",
        "uFile": "Datei hochladen",
        "tip": "Klicken Sie hier, um die Datei hochzuladen",
        "doc1": "Kreditvereinbarung1.doc",
        "doc2": "Kreditvereinbarung2.doc",
        "file1": "PERSONAL_LOAN_AGREEMENT-1.docx",
        "file2": "PERSONAL_LOAN_AGREEMENT-2.docx"
    },
    "nav": {
        "home": "Startseite",
        "lang": "Sprache",
        "loan": "Kredit",
        "paper": "Weißbuch",
        "pool": "Pool-Daten",
        "trading": "Handel"
    },
    "whitepaper": {
        "title": "USDC Whitepaper",
        "view": "Ansehen",
        "eth": "Ethereum Whitepaper"
    },
    "nft": {
      "vol": "Handelsvolumen",
      "amount": "Handelsbetrag",
      "qty": "NFT-Menge",
      "users": "Nutzer",
      "quantity": "Menge",
      "totalVol": "Gesamtvolumen",
      "lastestTrans": "Letzter Transaktionspreis",
      "collQuantity": "Sammlungsanzahl",
      "showMore": "Mehr anzeigen",
      "pd": "Projekt Details",
      "sellPrice": "Verkaufspreis",
      "royalty": "Lizenzgebühr",
      "royaltyTip": "Alle Lizenzgebühren dieser Transaktion werden an Wohltätigkeitsorganisationen gespendet",
      "buy": "Kaufen",
      "basic": "Basisinformationen",
      "contract": "Vertragsadresse",
      "network": "Netzwerk",
      "attribute": "Attribut",
      "trait": "Besitzt dieses Merkmal",
      "records": "Aufzeichnungen",
      "balance": "Kontostand",
      "sellNow": "Jetzt verkaufen",
      "cancel": "Abbrechen",
      "buyNow": "Jetzt kaufen",
      "pay": "Zu zahlender Betrag",
      "transPass": "Transaktionspasswort",
      "transTip": "Achtung: Bitte merken Sie sich Ihr Transaktionspasswort. Wenn Sie es vergessen haben, kontaktieren Sie den Online-Support des Plattformanbieters.",
      "newPass": "Neues Passwort",
      "rePass": "Passwort bestätigen",
      "confirm": "Bestätigen",
      "purchaseTime": "Kaufzeit",
      "purchaseAmount": "Kaufbetrag",
      "price": "Preis",
      "priceTip": "Bitte geben Sie den Verkaufspreis ein",
      "sell": "Verkaufen",
      "digitTip": "Bitte geben Sie 6 Ziffern ein"
    },
    "info": {
      "1": [
        "USDC ist ein Token, das von Händlern verwendet wird, um Zinsen zu verdienen. Es gibt viele Möglichkeiten, dies zu tun, und dies ist eine einzigartige Funktion von Kryptowährungen, da traditionelle Banken nur sehr geringe Erträge bieten. Das Verständnis, wie man mit USDC-Token arbeitet, ist für diejenigen, die Kryptowährungen handeln, sehr wertvoll.",
        "Inhaltsverzeichnis",
        "1 Was ist der US-Dollar Coin (USDC)?",
        "2 Wie funktioniert USDC Zinsen?",
        "3 Wie kann man mit USDC Zinsen verdienen?",
        "4 Welche Methoden gibt es, um mit USDC Zinsen zu verdienen?",
        "5 Ist es lohnenswert, mit USDC Zinsen zu verdienen?",
        "6 Häufig gestellte Fragen",
        "Was ist der US-Dollar Coin (USDC)?",
        "Der US-Dollar Coin, abgekürzt als 'USDC', ist eine Kryptowährung, die als Stablecoin bezeichnet wird. Sie wird von den beiden größten regulierten Finanzinstituten in den USA, Circle und Coinbase, ausgegeben und vollständig durch Reservevermögen gedeckt. Anders ausgedrückt, er ist 1:1 gegen den US-Dollar eintauschbar.",
        "Dies ermöglicht es, Transaktionen mit einer Währung durchzuführen, die an den US-Dollar gebunden ist. Der USDC ist ein ERC-20 Ethereum-Token, der durch das Ethereum-Netzwerk unterstützt wird, was bedeutet, dass er in jeder Brieftasche gespeichert werden kann, die Ethereum unterstützt. Das macht ihn sehr flexibel und für die meisten Nutzer im Kryptowährungsbereich zugänglich.",
        "Wie funktioniert USDC Zinsen?",
        "Stablecoins sind Token, deren Wert an einen anderen Vermögenswert gebunden ist, und sie sind eine ideale Wahl, um Zinsen zu verdienen. Im Fall von USDC entspricht der Wert immer einem US-Dollar. Die Zinsen, die mit diesen Münzen verdient werden, sind in der Regel viel höher als bei anderen Kryptowährungen, da sie leichter quantifiziert werden können. Wenn ein Vermögenswert stabil ist, ist es einfacher, Belohnungen zu bieten, da er nicht so stark schwanken sollte. Indem Unternehmen und Privatpersonen USDC leihen, können sie über die Blockchain Kredite für Investitionen, persönliche Zwecke oder Hebelhandel erhalten. Sie stimmen zu, den Kredit samt Zinsen zurückzuzahlen, ähnlich wie bei traditionellen Bankkrediten. Mit anderen Worten, ein Kredit von 10.000 USDC ist genauso wie ein Kredit von 10.000 US-Dollar.",
        "Kryptowährungszinsen und Stablecoins",
        "Man kann sowohl mit Stablecoins als auch mit Kryptowährungen Zinsen verdienen. Sie unterscheiden sich jedoch erheblich, und die Risikosituation, mit der Sie es zu tun haben, wird dies widerspiegeln. Da Kryptowährungen eine größere Volatilität aufweisen, sind Börsen weniger geneigt, sie zu verleihen. Kryptowährungen können innerhalb eines Tages 30% ihres Wertes verlieren, während Stablecoins nur sehr schwer zu bewegen sind.",
        "Wegen der extremen Volatilität von Kryptowährungen bieten Börsen geringere Zinssätze für diejenigen, die Kryptowährungen in ihren Börsen hinterlegen. Schließlich könnten sie in Schwierigkeiten geraten, wenn sie hohe Belohnungen auszahlen. Auf der anderen Seite könnten diejenigen, die Kryptowährungen hinterlegen, Zinsen verdienen, aber dabei erhebliche Kapitalverluste erleiden. Risiken sind immer vorhanden.",
        "Da Token wie USDC weniger volatil sind, sind Stablecoins eine sicherere Möglichkeit, Kryptowährungswerte zu speichern. Daher sind sowohl Börsen als auch Blockchains bereit, ihren Nutzern höhere Zinssätze anzubieten. Der USDC APR ist in der Regel stärker, da er 'stabil' ist.",
        "Warum sollten Sie Zinsen mit USDC verdienen?",
        "Es gibt viele Gründe, warum man Zinsen mit USDC verdienen möchte, und die Vorteile umfassen Folgendes und mehr:",
        "Alternative zu Sparbüchern:",
        "Einige Menschen nutzen USDC, um Zinsen zu verdienen, als Ersatz für ein Sparkonto. In den letzten Jahren boten Sparkonten sehr geringe Zinssätze, und Kryptowährungen sind eifrig dabei, diese Lücke zu füllen.",
        "Höhere Renditen:",
        "Die Renditen, die durch das Staking von Kryptowährungen wie USDC erzielt werden, sind in der Regel viel höher als bei traditionellen Bankgeschäften.",
        "Verdienen während des Wartens:",
        "Der Markt kann manchmal gefährlich und instabil sein. Daher halten manche Händler USDC, während sie sich von den Märkten fernhalten. Dies ist eine gute Möglichkeit, weiterhin das eigene Konto auszubauen.",
        "Wie verdient man Zinsen mit USDC?",
        "Um von USDC zu profitieren, müssen Sie es zuerst besitzen. Die Speicherung von USDC ist einfach und kann auf den meisten Börsen erfolgen. Sie müssen sich bei mehreren Börsen registrieren. Wir empfehlen, das Geld per Überweisung an Ihre Börse zu senden. Sobald das Geld auf der Börse eingegangen ist, müssen Sie zuerst ETH auf der Börse kaufen und dann das ETH zu Ihrer Wallet senden. Der nächste Schritt ist, das ETH in USDC zu tauschen. Dies ist sehr einfach und dauert nur wenige Minuten.",
        "Welche Möglichkeiten gibt es, Zinsen mit USDC zu verdienen?",
        "Es gibt viele verschiedene Möglichkeiten und Orte, um Zinsen mit USDC zu verdienen. Aber es ist wichtig, die Unterschiede zwischen den verschiedenen Plattformtypen zu verstehen und ihre Vor- und Nachteile zu kennen.",
        "Zinsen mit einer Börse verdienen",
        "Das Verdienen von Zinsen über eine Börse kann der einfachste Prozess sein. Die meisten Börsen erfordern eine ähnliche Reihe von Schritten, einschließlich der Kontoerstellung, Einzahlung von USDC und der Auswahl eines bevorzugten Zinsprodukts. Dies kann auch bedeuten, dass USDC für eine bestimmte Zeit gesperrt werden muss. Wenn Sie Liquidität benötigen, könnte dies ein Problem sein.",
        "Der größte Vorteil der Nutzung einer zentralisierten Börse ist die Einfachheit, da Sie wahrscheinlich Ihre Kryptowährungen nicht auf dieser Plattform aufbewahren oder zumindest Ihre Handelsgeschäfte dort abwickeln.",
        "Vorteile:",
        "Nachteile:",
        "Einfach und bequem.",
        "Möglicherweise müssen Sie USDC für einen bestimmten Zeitraum sperren.",
        "Kann direkt auf der Plattform erledigt werden.",
        "Möglicherweise erhalten Sie niedrigere Zinssätze.",
        "Die meisten Börsen haben ausreichendes Kapital.",
        "Verfügbare Produkte sind möglicherweise begrenzt.",
        "Web3 ist ein führender Bereich des Kryptowährungs-Stakings.",
        "Zinsen mit DeFi verdienen",
        "Eine der aufregendsten Möglichkeiten im Kryptowährungsbereich ist die Nutzung von DeFi-Plattformen, die es Nutzern ermöglichen, grundlegende Finanzdienstleistungen ohne Vermittler zu erhalten. Nutzer können ihre Kryptowährungen (in diesem Fall USDC) in dezentralisierte Kreditprotokolle einzahlen, um Zinsen zu verdienen.",
        "Dieser DeFi-Bereich ermöglicht es Nutzern, neue Finanzprodukte zu leihen und zu investieren, alles unter der Verwendung von Smart Contracts und Blockchain-Technologie.",
        "Vorteile:",
        "Nachteile:",
        "Smart Contracts bieten Benutzerfreundlichkeit.",
        "DeFi-Protokolle heilen keine Fehler der Nutzer.",
        "Kein Vermittler.",
        "Skalierbarkeit kann für einige Protokolle ein Problem sein.",
        "Weitere Produkte sind verfügbar.",
        "Für kleinere Protokolle kann Liquidität ein Problem sein.",
        "Beispiele für DeFi-Protokolle sind Uniswap und Bancor.",
        "Lohnt es sich, Zinsen mit USDC zu verdienen?",
        "Das Verdienen von Zinsen mit USDC ist eine gute Möglichkeit, Zinsen auf Kryptowährungsbestände zu erhalten. Besonders für Anfänger ist es oft die bevorzugte Methode, Zinsen zu verdienen. Es ist auch eine großartige Möglichkeit für Nutzer, eine sichere Kryptowährung aufzubauen und zu profitieren.",
        "Mit USDC können Anleger passives Einkommen auf eine einfache und sichere Weise generieren, da es mit einem stabilen Kurs an den US-Dollar gebunden ist. Wenn Sie sich fragen, ob es sich lohnt, Zinsen mit USDC zu verdienen, lautet die Antwort: Ja!",
        "Häufig gestellte Fragen",
        "1. Wie kann ich mit USDC Zinsen verdienen?",
        "Antwort: Sie können Zinsen verdienen, indem Sie USDC in zentralisierte Börsen, DeFi-Plattformen oder andere Finanzprodukte einzahlen.",
        "2. Ist es sicher, mit USDC Zinsen zu verdienen?",
        "Antwort: Ja, da USDC an den US-Dollar gebunden ist, ist es eine sichere Kryptowährung für Zinsen und ist weniger volatil als andere Kryptoassets.",
        "3. Wie hoch sind die Zinssätze für USDC?",
        "Antwort: Die Zinssätze für USDC variieren je nach Plattform, aber sie sind in der Regel höher als traditionelle Bankzinsen.",
        "4. Muss ich USDC sperren, um Zinsen zu verdienen?",
        "Antwort: In den meisten Fällen müssen Sie Ihr USDC für eine bestimmte Zeit sperren, um Zinsen zu erhalten.",
        "5. Kann ich jederzeit auf meine USDC zugreifen?",
        "Antwort: Ja, jedoch könnte eine Sperrfrist für die Einzahlung bestehen, wenn Sie Zinsen über eine Plattform verdienen."
      ],

      2: [
        "Inhaltsverzeichnis",
        "1. Was ist eine Web3-Wallet?",
        "1.1. Was ist Web3?",
        "2. Warum benötigt man eine Web3-Wallet?",
        "3. Alternativen zu Web3-Wallets",
        "3.1. MetaMask",
        "3.2. Coinbase Wallet",
        "3.3. Argent",
        "3.4. Trust Wallet",
        "3.5. Rainbow",
        "4. Was ist eine Web3-Wallet?",
        "Mit dem zunehmenden Interesse an Web3 wächst auch die Beteiligung an verschiedenen Ökosystemen. Wenn Sie jedoch mit diesem Bereich nicht vertraut sind, scheint die Einstiegshürde hoch und schwer zu überwinden. Doch mit dem zunehmenden Interesse an Kryptowährungen und Web3-Entwicklungen und dem fortschreitenden Wandel der Branche hin zu einer benutzerfreundlicheren Lösung verschwinden diese Hürden allmählich. Durch eine vereinfachte Zugänglichkeit und zunehmenden Wettbewerb entwickelt sich der Krypto-Raum weiter, was bedeutet, dass ständig neue dApps (dezentrale Apps), Token, NFTs und Web3-Wallets entwickelt werden. Wallets sind entscheidend für den Eintritt in den Krypto-Bereich, da sie im Web3-Bereich viele grundlegende Funktionen erfüllen. Daher werden wir in diesem Artikel näher auf den Krypto-Bereich eingehen und die Frage „Was ist eine Web3-Wallet?“ beantworten.",
        "Mit einem besseren Verständnis davon, was eine Web3-Wallet ist, werden wir auch einige der beliebtesten Alternativen auf dem Markt näher betrachten. Außerdem, wenn Sie an der Web3-Entwicklung interessiert sind und Ihre eigene Krypto-Wallet erstellen möchten, werden wir Ihnen den richtigen Weg weisen, der bei Web3.0 beginnt.",
        "Mit Web3 können Sie schnell und effizient Ihre eigene Web3-Wallet entwickeln, da Sie auf die zugrunde liegende Backend-Infrastruktur des Betriebssystems zugreifen können. Web3.0 ist das erste Betriebssystem für Web3-Entwicklungen, und neben der Backend-Infrastruktur können Benutzer auch auf eine breite Palette von Tools zugreifen. Zu diesen Tools gehören Web3.0 Speedy Nodes, Web3.0 SDK, Price API, native Unterstützung für IPFS und andere Lösungen.",
        "Wenn Sie also ein Blockchain-Entwickler und -Sparer werden möchten, ist der erste Schritt die Anmeldung bei Web3.0. Die Anmeldung dauert nur wenige Minuten und ist damit schnell erledigt.",
        "Was ist eine Web3-Wallet?",
        "Wenn Sie in die Kryptowelt einsteigen möchten, ist der erste Schritt, eine Web3-Wallet zu erhalten. Web3-Wallets sind entscheidend für den Zugang zum Web3-Raum, DeFi und Kryptowährungen. Möglicherweise haben Sie den Begriff „Web3“ oder „Krypto-Wallet“ bereits gehört. Wenn Sie neu in diesem Bereich sind, lassen Sie uns zunächst die Frage „Was ist eine Web3-Wallet?“ beantworten.",
        "Zunächst einmal ist eine Web3-Wallet im Wesentlichen eine digitale Wallet. Sie hat die Fähigkeit, digitale Vermögenswerte zu speichern. Dies umfasst alles, von fungiblen Token bis hin zu nicht fungiblen Token (NFTs). Zweitens öffnet eine Web3-Wallet die Tür zum Krypto-Raum und ermöglicht es Ihnen, mit dApps auf verschiedenen Blockchains zu interagieren. Umgekehrt kann die Wallet Ihnen helfen, auf das breite dApp-Ökosystem zuzugreifen.",
        "Krypto-Wallets sind in der Regel nicht verwahrend, was bedeutet, dass Sie als Wallet-Inhaber digitale Vermögenswerte speichern können, ohne dass ein Vermittler oder Zwischenhändler erforderlich ist. Das bedeutet, dass Sie als Benutzer die vollständige Kontrolle über Ihre gesamten Vermögenswerte behalten, da niemand Zugriff auf Ihre Token hat. Doch mit dieser exklusiven Zugriffsmöglichkeit tragen Sie auch die gesamte Verantwortung, was bedeutet, dass Sie Ihre privaten Schlüssel selbst verwalten müssen.",
        "Neben der Fähigkeit, digitale Vermögenswerte zu verwahren, bieten Wallets oft auch andere Funktionen. Zum Beispiel ermöglicht eine Web3-Wallet das Senden und Tauschen von Token. Daher können Krypto-Wallets verwendet werden, um Ihre Vermögenswerte vollständig zu verwalten, einschließlich Möglichkeiten, zusätzliche Token zu erwerben.",
        "Es gibt eine Vielzahl von Wallets auf dem Markt, die jeweils ihre eigenen Besonderheiten haben. Einige der beliebtesten sind MetaMask, Coinbase Wallet, DeFi Wallet Trust Wallet, Argent, Atoken, Hyperpay, Bitkeep und mehr. Ein weiteres Thema, das sich lohnt, zu erforschen, ist WalletConnect, da es eng mit Web3-Wallets verbunden ist.",
        "Was ist Web3?",
        "Mit dem Aufkommen der Blockchain-Industrie haben Sie wahrscheinlich schon von „Web3“ gehört. Für Außenstehende mag dieser Begriff jedoch neu und möglicherweise etwas verwirrend sein. Daher nehmen wir uns in diesem Abschnitt Zeit, zu erklären und zusammenzufassen, was Web3 ist.",
        "Im Allgemeinen bezieht sich Web3 auf die „neueste Generation“ oder „Stufe“ des Internets. Wie Sie sich vorstellen können, waren die vorherigen Generationen Web1 und Web2, die Ihnen möglicherweise vertraut sind. Diese drei Internet-Epochen sind nicht zu einem bestimmten Zeitpunkt entstanden oder von einer einzelnen Entität initiiert worden, die das Internet vollständig verändert hat. Dennoch hat jede Phase ihre eigenen Merkmale: Web1 war statisch, Web2 ist dynamisch und Web3 ist dezentral.",
        "Dezentralisierung ist das zentrale Konzept der neuesten Internet-Phase und wird von dezentralen Daten dominiert. Im Gegensatz zu Web2 gibt es keine zentralisierte Entität, die Daten besitzt; vielmehr sind die Daten verteilt und gemeinsam nutzbar. Darüber hinaus löst Web3 letztlich das Problem der Datensammlung durch Unternehmen, da die Benutzer die Kontrolle über ihre eigenen Daten haben.",
        "Im Web3-Ökosystem finden wir auch eine andere wichtige Komponente: dApps (dezentrale Anwendungen). Diese dezentralen Apps basieren in der Regel auf Blockchains, und das größte dApp-Ökosystem befindet sich derzeit auf der Ethereum-Blockchain. Dank der dezentralen Eigenschaften von dApps können leistungsstarke Anwendungen entwickelt werden, die viele Probleme der Zentralisierung lösen, darunter Single Points of Failure.",
        "Wenn Sie jedoch mehr über die neueste Internet-Phase erfahren möchten, empfehlen wir Ihnen, den ausführlicheren Artikel über Web3 zu lesen.",
        "Warum braucht man eine Web3-Wallet?",
        "Im Abschnitt „Was ist eine Web3-Wallet?“ haben wir kurz erläutert, warum man eine solche Wallet braucht. In diesem Teil des Artikels werden wir diese Punkte detaillierter besprechen. Also, warum braucht man eine Web3-Wallet?",
        "Der erste Anwendungsfall für eine Web3-Wallet ist die vollständige Verwaltung Ihrer Krypto-Vermögenswerte. Um dies zu veranschaulichen, werden wir die größte Krypto-Wallet auf dem Markt, MetaMask, genauer untersuchen. Wenn Sie ein MetaMask-Konto haben, sind Sie möglicherweise schon mit der Funktionsweise der Wallet vertraut. Dennoch sehen Sie hier die Benutzeroberfläche:",
        "Wie Sie sehen können, gibt es zwei verschiedene Tabs: einen für „Assets“ und einen für „Aktivitäten“. Der „Aktivitäten“-Tab zeigt lediglich Ihre Transaktionshistorie, die Sie unabhängig durchsuchen können. Außerdem können Sie im „Assets“-Tab Ihre gesamten Vermögenswerte einsehen. Es gibt auch drei Aktionstasten: Kaufen, Senden, Tauschen. Diese Tasten sind selbsterklärend, und wir werden sie hier nicht weiter im Detail erklären.",
        "Dies zeigt jedoch, dass Sie als Krypto-Wallet-Benutzer Ihre gesamten Vermögenswerte direkt über MetaMask verwalten können. Und das ist nicht einzigartig, denn die meisten anderen Wallets erlauben Ihnen, Ihre Vermögenswerte auf ähnliche Weise zu verwalten.",
        "Ein weiterer Grund, warum Sie eine Web3-Wallet benötigen, ist die Zugänglichkeit. Krypto-Wallets sind das Tor zu verschiedenen Blockchains. Daher ermöglicht eine Web3-Wallet es den Benutzern, einfach auf dApps zuzugreifen, da Krypto-Vermögenswerte notwendig sind, um mit den meisten Anwendungen zu interagieren. So können Benutzer ihre Identität über die Wallet verifizieren und sicherstellen, dass sie in der Lage sind, Transaktionsgebühren zu bezahlen.",
        "Wenn Sie mehr über Benutzerverifizierung erfahren möchten, werfen Sie einen Blick auf unseren Artikel, wie Sie sich mit MetaMask verifizieren und wie Sie WalletConnect verwenden, um Benutzer zu verbinden.",
        "Alternativen zu Web3-Wallets",
        "Wie bereits erwähnt, wächst die Anzahl der Web3-Wallets, da immer mehr Menschen in den Krypto-Bereich einsteigen. Dies ist eine einfache „Angebot-Nachfrage“-Dynamik, da Unternehmen die Wachstumschancen des aufkommenden Marktes erkannt haben. Doch wenn Benutzer versuchen, unter den vielen Alternativen zu wählen, kann das verwirrend sein.",
        "Daher werden wir uns fünf alternative Wallets näher ansehen. Die meisten von ihnen verfolgen das gleiche Ziel, aber sie unterscheiden sich in ihrer Art und Weise und sind jeweils einzigartig auf ihre Weise. Diese fünf Web3-Wallets sind:",
        "MetaMask",
        "Coinbase Wallet",
        "Argent",
        "Trust Wallet",
        "Rainbow",
        "Nun, ohne weiter zu reden, lassen Sie uns einen genaueren Blick auf MetaMask werfen!",
        "MetaMask",
        "Das erste Web3-Wallet auf unserer Liste ist MetaMask, eines der bekanntesten Wallets auf dem Markt"
      ],
      3: [
        "Web3 fördert das Web3-Konzept und vergibt Stipendien an der UEL in Vietnam",
        "Web3 freut sich, die erfolgreiche Durchführung seiner neuesten exklusiven Veranstaltung „Gate Web3 - Empowering GenZ in Web3“ bekannt zu geben. Diese Bildungs- und Wohltätigkeitsveranstaltung fand am 28. Oktober 2023 von 13:00 bis 16:30 Uhr an der Universität für Wirtschaft und Recht (UEL) in Ho-Chi-Minh-Stadt, Vietnam, statt.",
        "Die Veranstaltung hatte das Ziel, der nächsten Generation (insbesondere der Generation Z) Wissen und Chancen in der sich schnell entwickelnden Welt der Web3-Technologie zu bieten. Die Teilnehmer erhielten wertvolle Einblicke in die revolutionären Konzepte von Web3 und warum es als führende Technologie der Zukunft gilt. Darüber hinaus gaben Experten Ratschläge zu grundlegenden Fähigkeiten, dem Einstieg in den Bereich und wie man eine erfolgreiche Karriere in diesem dynamischen Sektor aufbaut.",
        "Web3 setzt sich dafür ein, benachteiligte Studierende zu unterstützen, indem es Stipendien für Studierende vergibt, die finanzielle Hilfe für Studiengebühren und Lebenshaltungskosten benötigen. Die Rednerin Frau Mai Ngo leitete die Veranstaltung.",
        "Während der Veranstaltung fand auch eine Podiumsdiskussion mit Branchenexperten statt, darunter Frau Mai Ngo von BSCS, Herr Tuan Anh und Herr Dao Tien Phong, der leitende Anwalt von Investpush Legal. Sie teilten wertvolle Einblicke in berufliche Möglichkeiten im Bereich der Blockchain-Technologie. Die Teilnehmer hatten die Gelegenheit, bedeutungsvolle Gespräche mit diesen Experten zu führen und wertvolle Ratschläge und Wissen zu erhalten.",
        "Neben den informativen Sitzungen vergaben Web3 und Gate Charity während der Veranstaltung 15 Stipendien an herausragende, aber bedürftige Studierende. Jedes Stipendium hatte einen Wert von 1 Million vietnamesischen Dong.",
        "Es wird erwartet, dass etwa 100 Studierende und Dozenten der FTC Universität an der Veranstaltung teilnehmen. Zudem wird die Veranstaltung online übertragen, wobei eine Zuschauerzahl von etwa 50.000 erwartet wird.",
        "Web3 freut sich, diese Veranstaltung zu organisieren, die Experten, Studierende und Befürworter der Web3-Technologie vereint, um Bildung, Bewusstsein und Chancen in diesem sich wandelnden Bereich zu fördern.",
        "Über Gate Charity",
        "Gate Charity ist eine globale gemeinnützige Organisation, die sich der Förderung der weltweiten Blockchain-Wohltätigkeitsbewegung widmet. Gate Charity verfolgt die Mission, „das weltweite Gleichgewicht zu fördern“, indem sie die Krypto-Community vereint und durch innovative und vertrauenswürdige Blockchain-Netzwerke sowie Kryptowährungen die Kluft zwischen Arm und Reich verringert, das Leben benachteiligter Gruppen verbessert, Bildungsgerechtigkeit fördert und nachhaltige Umweltinitiativen unterstützt.",
        "Haftungsausschluss:",
        "Bitte beachten Sie, dass alle Inhalte dieser Veranstaltung ausschließlich für wohltätige Zwecke verwendet werden. Die Nutzung oder der Erwerb von Produkten oder Dienstleistungen ist nicht vorgesehen. Bei Fragen wenden Sie sich bitte an Gate Charity unter support@gatecharity.org.",
        "Sicher, schnell und einfach auf Web3 handeln – Gateway zu über 1.700 Kryptowährungen",
        "Registrieren Sie sich auf Web3 und erhalten Sie 40 % Empfehlungsprovision. Erhalten Sie sofort Speicherplatz auf Web3.",
        "Kontaktieren Sie uns!",
        "Team, 23. Oktober 2023",
      ],
      4: [
          "Blockchain Zugang\n verändert Leben",
          "Schaffung einer Welt, in der jeder jederzeit und überall über Blockchain-Lösungen Zugang zu Finanzdienstleistungen und Unterstützung erhält.",
          "Gesamtspendenbetrag\n (USD)",
          "Gesamtzahl der Begünstigten\n",
          "Begünstigte\n Regionen",
          "Förderung einer ausgewogenen\n Weltentwicklung",
          "Beseitigung der Armut",
          "Wir glauben, dass Blockchain und Kryptowährungen eine wichtige Rolle bei der Schaffung von gleichen Bildungs- und Arbeitsmöglichkeiten, der Unterstützung von Menschen, die von Armut betroffen sind, und der Befreiung von Millionen von Menschen aus der Armut spielen können.",
          "Befähigung benachteiligter Gruppen",
          "Abbau von Barrieren für Schlüsselressourcen und Unterstützungssysteme, mit dem Fokus auf die Unterstützung von Frauen, Menschen mit Behinderungen, armen Kindern, älteren Menschen und benachteiligten Familien in armen Regionen, um Inklusion, Zugang sowie die Fähigkeit zum Wachsen und Aufblühen zu ermöglichen.",
          "Rettung unseres Planeten",
          "Es gibt nur einen Planeten, und wir werden immer Maßnahmen ergreifen, um den Schaden, den der Mensch dem Klima und der natürlichen Umwelt zufügt, zu verringern. Eine gesunde Umwelt bedeutet, einen langfristig positiven Einfluss auf künftige Generationen auszuüben.",
          "Humanitäre Hilfe leisten",
          "Bereitstellung von Hilfe für Menschen in Krisensituationen, einschließlich Unterstützung für Menschen, die von Konflikten betroffen sind, Wiederaufbau von Katastrophengebieten, Hilfe für Vertriebene, und medizinische Hilfe für arme Regionen.",
          "Transparente und nachvollziehbare Mittelbeschaffung",
          "Durch den Einsatz von Blockchain-Technologie gewährleisten wir transparente, verlässliche wohltätige Handlungen, die wirklich den benachteiligten Gruppen helfen.",
          "NFT Kunst-Auktionsfonds",
          "Durch NFT-Auktionen zur Mittelbeschaffung können wir Wohltätigkeitszwecke effektiv erreichen und gleichzeitig den Wert von Kunst verbreiten.",
          "Metaverse Wohltätigkeitsspielprojekt",
          "Vielfältige Web3.0-Lösungen bieten eine lehrreiche und unterhaltsame Möglichkeit, den Samen der Wohltätigkeit in jedem Herzen zu verbreiten.",
          "Blockchain-Technologie zum Wohle der Menschen nutzen",
          "Weltweit führende Blockchain-Technologie",
          "Durch sichere und innovative Blockchain-Technologie stellen wir sicher, dass wohltätige Spenden transparent, sicher und zuverlässig sind. Jeder, der finanzielle Unterstützung benötigt, erhält garantiert Hilfe in allen Bereichen.",
          "Breit unterstütztes digitales Asset-Repository",
          "Wir sind stolz darauf, das weltweit breiteste digitales Asset-Repository zu unterstützen, was bedeutet, dass Sie jedes digitale Asset, das Sie besitzen, für diese großartige Wohltätigkeitsbewegung nutzen können.",
          "Blockchain-Bildung erhalten",
          "Wir glauben, dass Bildung die Grundlage für alles ist, und der Aufbau einer grundlegenden Blockchain-Bildung wird den Menschen, die wir unterstützen, wertvolle Ergebnisse bringen. Sie werden ein unverzichtbarer Teil des zukünftigen Aufbaus von Blockchain-Technologie sein.",
          "Wohltätigkeit und multilaterale Zusammenarbeit",
          "Wir setzen uns dafür ein, die Effektivität von Blockchain durch Partnerschaften mit den besten Institutionen, Projekten und Experten zu maximieren, wobei jede Form der Integration letztlich der Wohltätigkeit selbst zugutekommt.",
          "Rangliste",
          "Am 21. Oktober 2010 von 8:00 bis 16:00 Uhr (GMT -3) veranstalteten Web3 Charity, A. A. Garotos de Ouro und Gate.io ein besonderes Kinderfest im berühmten Brahma-Stadion in Brasilien. Diese außergewöhnliche Veranstaltung zog etwa 150 Kinder aus den umliegenden Gemeinden an, die voller Sehnsucht und Begeisterung waren.",
          "Die globale gemeinnützige Organisation Web3 Charity veranstaltete am 6. Oktober 2023 erfolgreich ein Event zum Thema „Schutz der Meere“ in der Philippinenstadt Baclaran.",
          "Unsere Initiativen",
          "Somali Hungerkrise Unterstützungsprojekt",
          "Schließen Sie sich Gate Charity und World Vision an, um die Opfer der Hungersnot in Somalia zu unterstützen",
          "Hervorhebung",
          "Spenden",
          "Jetzt spenden",
          "Unterstützte Ursachen",
          "Weltweit sind in mindestens 45 Ländern fast 50 Millionen Menschen vom Hunger bedroht. Wir haben noch nie eine so große Hungerkrise erlebt, die Länder in Afrika, Lateinamerika, dem Nahen Osten und Asien betrifft. In Ländern wie Somalia leiden Millionen von Menschen unter Hunger. Die negativen Auswirkungen des Klimawandels, wie Dürre, Überschwemmungen und andere extreme Wetterereignisse, zerstörten Ernten und Einkommen und führten in einigen Ländern sogar zu massiven Heuschreckenplagen, die Ernten vernichteten. Die Folgen der COVID-19-Pandemie führten zu einem weltweiten Anstieg der Lebensmittel- und Warenpreise, wodurch Familien es schwerfiel, die grundlegenden Nahrungsmittel für das Überleben zu kaufen. Gleichzeitig verursachen Konflikte weltweit, dass Millionen von Menschen ihre Heimat verlassen und Häuser, Leben und Lebensgrundlagen zerstört werden.",
          "Die Vereinbarung mit dem UN World Food Program bedeutet, dass jede Person, die 1 USDC spendet, mit 27 multipliziert wird.",
          "Die Auswirkungen von mehreren Konflikten, einschließlich des Kriegs in der Ukraine, auf die globale Weizenproduktion haben eine perfekte Sturm für die am meisten gefährdeten Kinder der Welt geschaffen.",
          "Gleichzeitig führen Konflikte an mehreren Standorten weltweit dazu, dass Millionen von Menschen vertrieben werden, ihre Häuser, Leben und Lebensgrundlagen zerstört werden.",
          "Die Auswirkungen der COVID-19-Pandemie führten zu einem weltweiten Anstieg der Lebensmittel- und Warenpreise.",
          "Die negativen Auswirkungen des Klimawandels, wie Dürre, Überschwemmungen, extreme Stürme und unvorhersehbares Wetter, zerstörten Ernten und Einkommen und führten in einigen Ländern zu massiven Heuschreckenplagen, die Ernten zerstörten.",
          "Gemeinsam gegen Hungersnöte kämpfen",
          "Um der beispiellosen Hungersnot zu begegnen, die 50 Millionen Menschen in 45 Ländern betrifft, arbeitet Web3 Charity mit World Vision zusammen. Diese Partnerschaft zielt darauf ab, dringend benötigte Lebensmittelhilfe für die betroffenen Regionen, insbesondere in Ländern wie Somalia, bereitzustellen. Das Projekt soll die schwerwiegenden Hungerprobleme in Afrika, Lateinamerika, dem Nahen Osten und Asien bekämpfen.",
          "Maximierung des Einflusses des World Food Program",
          "Web3 Charity arbeitet mit World Vision zusammen, um durch persönliche Spenden in Partnerschaft mit dem UN World Food Program (WFP) eine große Wirkung zu erzielen. Jede 1 USDC-Spende wird 27-mal verstärkt, was bedeutet, dass eine Spende von 1.000 USDC zu 27.000 USDC wird. Diese Ressourcen sind entscheidend, um Familien in den von Hungersnöten betroffenen Gebieten, insbesondere Kinder, mit lebenswichtiger Nahrungsmittelhilfe zu versorgen. Das Projekt umfasst die Verteilung von Lebensmittelpaketen an Familien und die Bereitstellung therapeutischer Nahrungsmittel für junge Kinder, um ihre unmittelbaren Ernährungsbedürfnisse während der Krise zu decken.",
          "Unsere Partner",
          "World Vision ist eine christliche NGO, die sich der Beseitigung von Armut und der Förderung der nachhaltigen Entwicklung von schwachen Gemeinschaften, Familien und Kindern weltweit verschrieben hat. Die Organisation stellt jede Minute Wasser, Nahrung und Unterstützung für Bedürftige bereit. World Vision ist in fast 100 Ländern tätig und kann schnell auf Notfälle, Krisen und Naturkatastrophen reagieren. Im Jahr 2021 half World Vision mehr als 30 Millionen Menschen in 52 Ländern, die von Notfällen betroffen waren. Die Buchhaltung von World Vision wird jährlich einer Transparenzprüfung unterzogen.",
          "Unterstützung der indonesischen SLB Sri Soedewi Schule für benachteiligte Kinder",
          "Schließen Sie sich Gate Charity und World Vision an, um die Opfer der Hungersnot in Somalia zu unterstützen",
          "Hervorhebung",
          "Spenden",
          "Jetzt spenden",
          "Unterstützte Ursachen",
          "Die SLB Sri Soedewi Schule für Kinder mit Behinderungen wurde 1982 gegründet und hat mehr als hundert Schüler, die Unterricht in verschiedenen Behinderungsarten erhalten, darunter Sehbehinderungen, Hörbehinderungen, geistige Behinderungen, körperliche Behinderungen und Autismus. Die Schule bietet Unterricht für Grundschule, Sekundarschule und Oberschule an. Die Institution konzentriert sich darauf, das einzigartige Potenzial und Interesse jedes Schülers zu nutzen, ihre akademischen Fähigkeiten und Fähigkeiten zu entwickeln, um ihre ganzheitliche Entwicklung zu gewährleisten. Das Endziel ist es, den Schülern Lebenskompetenzen beizubringen, damit sie selbstständig in der Gesellschaft leben können. Web3 Charity und die SLB Sri Soedewi Schule setzen sich für Inklusion ein und bieten jedem Kind eine sichere und unterstützende Plattform.",
          "Jede Spende hat einen bedeutenden Einfluss auf das Leben der Schüler, und auch Sie können ein Teil davon sein.",
          "Die Bereitstellung von hochwertiger Unterstützung und akademischem Leben für sehbehinderte Schüler kann ihnen helfen, ihr Selbstwertgefühl aufzubauen, wichtige Freundschaften zu entwickeln und ihnen zeigen, dass die Zukunft da ist und es wert ist, erforscht zu werden.",
          "Eine geeignete Schulumgebung ist eine wichtige langfristige Antwort auf die sozialen und kulturellen Bedürfnisse von Kindern. Die SLB Sri Soedewi Schule bietet Möglichkeiten zur Entwicklung emotionaler, sprachlicher und akademischer Unterstützungssysteme, damit Schüler glänzen können.",
          "Behinderungen bedeuten nicht, dass das Leben ein Hindernis ist, noch bedeutet es, dass sie nicht an bestimmten Aktivitäten teilnehmen können. Sie haben einfach einen anderen Weg, um Aktivitäten zu tun, die sie sonst nicht durchführen können, weil sie besonders sind.",
          "Der Geist der Einheit ist einer der wichtigen Faktoren, um Inklusion zu fördern, leuchtende Lächeln zu verbreiten, Hände zu ergreifen und starke Bindungen zu schaffen."
      ],
      5: [
          "Circle veröffentlicht ersten Jahresbericht „USDC Wirtschaftsstatus“",
          "Nachfolgend finden Sie das Vorwort zum Bericht „USDC Wirtschaftsstatus – Willkommen in der praktischen Wertphase der digitalen Währungen“.",
          "Laden Sie den vollständigen USDC Wirtschaftsstatus-Bericht herunter und sehen Sie sich unsere #WEF23 Agenda vom Weltwirtschaftsforum an unter Circle.com/Davos",
          "Digitale US-Dollar-Währung treibt die nächste Evolution von Währung, Zahlungen und Finanzen voran",
          "Vor zehn Jahren gründete ich Circle zusammen mit anderen mit der Vision, dass traditionelle Währungen in Form von digitalen Währungen im Internet bereitgestellt werden können und in einem offenen, interoperablen globalen Netzwerk existieren. Wir haben eine Welt des reibungslosen Werttransfers geschaffen, die den ursprünglichen Jahrzehnten des reibungslosen Informationsaustauschs und der Kommunikation des Internets widerspiegelt. Seitdem ist die Arbeit von Circle lebendig und aktiv.",
          "Im Jahr 2013 gab es nahezu keine technische Reife von digitalen Währungen und Blockchain-Technologie, kein Verständnis der Branche und keinerlei regulatorische Wahrnehmung. Tatsächlich gab es nicht einmal die notwendigen Voraussetzungen, um ein „Internet-Dollar“-Protokoll zu etablieren. Wir wussten, dass dies mindestens eine Reise von zehn bis zwanzig Jahren sein würde, und dass der Einfluss von digitalen Währungen auf das offene Netzwerk tiefgreifender sein würde als der des Informationsnetzwerks.",
          "Zehn Jahre später ist die weltweit führende regulierte digitale US-Dollar-Währung, der USDC, im Mainstream angekommen. In nahezu jeder Kennzahl und jedem Trend wächst die USDC-Wirtschaft gesund und wird zu einer treibenden Kraft für die Skalierung von Internetwirtschaftsaktivitäten – Aktivitäten, die auf festen Infrastrukturen oder Bankdienstleistungen angewiesen sind, die nicht in der Lage sind, breitere Märkte und Bevölkerungen zu erreichen.",
          "Während die spekulativen Zeiten von Bitcoin und anderen Krypto-Vermögenswerten ein turbulentes Jahr durchlebt haben, zeigt USDC und ähnliche digitale Währungen die notwendige Transformation hin zu einer praktischen Wertphase von Blockchain und programmierbaren, zusammensetzbaren und immer verfügbaren Währungen. Im Zeitalter des Internets geht es nicht darum, mit traditionellen Finanzsystemen zu konkurrieren, sondern die unerledigte Arbeit zu vollenden.",
          "Richtig betrachtet könnte eine digitale Währung wie USDC beginnen, einen erheblichen Teil der mehr als 2 Billionen Dollar an Einnahmen aus der Zahlungsindustrie zu absorbieren und eine neue digitale Ausdrucksform der 21-Billionen-Dollar-M2-Geldmenge zu werden.",
          "Ein Beispiel: Händler verstehen die Bedeutung dieser Entwicklung. Mehr als 85 % bevorzugen die Aktivierung von Zahlungen mit digitalen Währungen. Obwohl die Point-of-Sale-Zahlungen von USDC noch neu sind, gibt es bereits erste Anwendungen. Viele traditionelle Zahlungsanbieter (darunter Checkout.com, Mastercard, Plaid, Stripe, Visa, FIS' Worldpay und andere) arbeiten mit USDC zusammen, um es Händlern so einfach wie möglich zu machen, es zu akzeptieren.",
          "Die öffentliche Blockchain-Infrastruktur erlebt ein „Dial-Up-Breitband“-Upgrade, das nahezu sofortige, hochsichere, programmierbare und kostengünstige Transaktionen ermöglicht. Immer mehr Startups, Unternehmen sowie traditionelle Zahlungs- und Finanzdienstleistungsunternehmen suchen nach Wegen, sich mit dem Wert-Internet zu verbinden, während Entwickler aus dem Web2-Bereich in Scharen nach Web3 strömen.",
          "Gleichzeitig verstärken globale Gesetzgeber ihre Aufmerksamkeit und führen dringend benötigte regulatorische Maßnahmen ein, um zu überwachen, wie Banken und Zahlungsaufsichtsbehörden vertrauenswürdige digitale Währungen auf konsistente und koordinierte Weise regulieren.",
          "Seit seiner Gründung glaubt Circle fest daran, dass ein neues globales Wirtschaftssystem auf der nativen Infrastruktur des Internets aufgebaut werden kann – eine offene, globale und interoperable öffentliche Internet-Infrastruktur für die Speicherung und Übertragung von Werten, die letztlich auch eine breitere Kapitalvermittlung ermöglichen sollte. Diese Vision wird Realität, und 2023 wird ein entscheidendes Jahr für die nächste Evolution von Währung, Zahlungen und Finanzen sein.",
          "Wie in unserem ersten Jahresbericht „USDC Wirtschaftsstatus“ 2023 hervorgehoben, sprießen weltweit erste Anzeichen von öffentlichem Nutzen und katalysieren eine Welle von offenen, verantwortungsvollen Innovationen im Bereich Finanzdienstleistungen, die den Weg zu Wohlstand verbessern und gleichzeitig die Integrität des globalen Finanzsystems schützen können."
         ],
      6: [
          "Neuer Inhalt im USDC Wirtschaftsstatus 2024",
          "Ein neues Netzwerkfinanzsystem entsteht. Dank USDC, Blockchain-Netzwerken und der Unterstützung einiger der besten Entwickler und Technologieunternehmen weltweit wird das Finanzsystem aufgerüstet. Es wird schneller, einfacher, sicherer und ermöglicht es allen, Geld zu verdienen.",
          "Vorwort",
          "In den letzten zehn Jahren hat der Aufstieg der Blockchain-Technologie die Integration von Netzwerken und globalen Finanzen vorangetrieben. Diese Integration bringt eine Software-Upgrade-Revolution für das Finanzsystem mit sich.",
          "Dies war der Grund, warum Circle 2013 gegründet wurde. Unsere Mission ist es, das offene und skalierbare Potenzial des Internets zu nutzen, um globalen wirtschaftlichen Wohlstand durch reibungslosen Werttransfer zu fördern – den Aufbau eines aufkommenden Netzwerkfinanzsystems, das wir uns vorstellen.",
          "Software wird die Finanzwelt erobern, genauso wie sie in der letzten Generation viele traditionelle Branchen entwickelt hat. Indem Kommunikation, Unterhaltung und andere kommerzielle Aktivitäten schneller, billiger und zugänglicher gemacht werden, sinken die Kosten, die Barrieren verringern sich, und Innovation floriert. Wir glauben, dass die beschleunigte Migration von Unternehmen zu Blockchain die nächste Welle einer digitalen Transformation ist, die Jahrzehnte dauert und deren gesellschaftliche und wirtschaftliche Auswirkungen möglicherweise größer sind als die der frühen Internetzeiten der 1990er und 2000er Jahre.",
          "Wenn man bedenkt, dass von 2000 bis 2020 die Internetnutzung von weniger als 7% der Weltbevölkerung auf 60% gestiegen ist, wird ein ähnlicher Wachstumstrend auch bei Blockchain erwartet.",
          "In den kommenden Jahren erwarten wir, dass Millionen von Unternehmen und Milliarden von Menschen beginnen werden, diese neue, offene Web3-Netzwerkschicht zu nutzen, um Billionen von Dollar zu verarbeiten.",
          "Offene Währungen sind eine großartige Idee, die in den Prinzipien von Wirtschaft, Technologie und Philosophie verwurzelt ist, die auf der Offenheit und Interoperabilität des Internets basieren. Durch die Senkung der Grenzkosten für die Verbreitung von Daten auf nahezu null ermöglicht das Netzwerk allen den Zugang zum gesamten menschlichen Wissen. Durch die Ermächtigung von Entwicklern und Schöpfern zur einfachen Erstellung hat es ein reichhaltiges Ökosystem von Websites und Anwendungen hervorgebracht.",
          "Das Projekt des Internets bleibt jedoch unvollständig, da trotz der sicheren, sofortigen und kostenlosen Übertragung von Daten weltweit die ‚Highways‘ für Geldtransfers im Internet noch ausgebaut werden müssen.",
          "Alles, was wir heute tun, ist darauf ausgerichtet, dieses Netzwerkfinanzsystem aufzubauen und zu beschleunigen.",
          "Wie in diesem Bericht beschrieben, begann alles mit unserem Bestreben, USDC zum größten und am weitesten verbreiteten Stablecoin-Netzwerk der Welt zu machen. Mit USDC ermöglichen wir es fast jedem, überall, auf das digitale Dollar zuzugreifen und es zu verwenden. Wir wandeln USDC in eine offene Plattform um, auf der Unternehmen und Entwickler neue Anwendungen aufbauen können, die mit den heute dominierenden Internet- und Finanzplattformen konkurrieren und diese umgestalten können.",
          "Das vergangene Jahr markierte einen wichtigen Wendepunkt in der Zukunft des internet-nativen Finanzwesens auf Blockchain-Basis. Schadhafte Akteure beginnen, die volle Verantwortung für ihre bösartigen Aktivitäten zu tragen, Betrügereien werden aufgedeckt, aber die zugrunde liegende Technologie ist bereits ausgereift, und Mainstream-Unternehmen vertiefen ihre Beteiligung an Technologien wie USDC.",
          "Führende Zahlungsunternehmen und kommerzielle Abrechnungsgesellschaften nutzen jetzt USDC, um reale Probleme zu lösen. Blockchain-basierte Kreditmärkte verwenden USDC, um weltweite Wirtschaftstätigkeiten zu unterstützen. Fintech-Unternehmen und neue Banken, die im Internet entstanden sind, wenden sich USDC zu, um es als Mittel zur Übertragung von Dollar mit der Geschwindigkeit des Online-Geschäfts zu nutzen. Remittance-Unternehmen und NGOs arbeiten mit USDC zusammen, um das Problem zu lösen, Werte schnell und gerecht an die Bedürftigen zu übertragen. Traditionelle Banken, Kapitalmarktunternehmen und Verwahrstellen unterstützen USDC, um es als eine Möglichkeit zur Geschäftsentwicklung zu nutzen.",
          "Dieser Schwung erklärt, warum globale Gesetzgeber bedeutende Fortschritte bei der Veröffentlichung von Regeln und Leitlinien gemacht haben, wie man verantwortungsbewusst mit Zahlungstablecoins und offenen Blockchains umgeht. Sie verstehen das enorme Potenzial und sind entschlossen, die Chancen zu nutzen, um der Wirtschaft und der Gesellschaft zugute zu kommen. Innovatoren und Gesetzgeber definieren gemeinsam die Kunst des digitalen Währungsmanagements neu.",
          "Obwohl es noch zu früh ist, gibt es klare Anzeichen dafür, dass USDC und das breitere Ökosystem bereit sind, die Fluchtgeschwindigkeit zu erreichen – die Macht von offenen Währungen weltweit freizusetzen.",
          "Ich hoffe, Ihnen gefällt der USDC Wirtschaftsstatus 2024. Mein Team und ich freuen uns darauf, mit Ihnen über die Ergebnisse dieser Untersuchung zu diskutieren.",
          "Mit freundlichen Grüßen,",
          "Mitgründer, Vorsitzender und CEO",
          "Dollar für jeden, überall",
          "Das offene Blockchain-Netzwerk treibt eine neue Form von Währung an und definiert die Art und Weise, wie Menschen und Unternehmen weltweit Werte austauschen, neu. Diese Netzwerke repräsentieren die neue Dimension von Fintech, die das Internet nutzt, um tief verwurzelte Kosten und Reibungen zu verringern, die Geschwindigkeit zu erhöhen und die Möglichkeiten beim Austausch von Werten neu zu denken – sei es beim grenzüberschreitenden Versand von digitalen Dollars oder bei der Interaktion mit tokenisierten Versionen anderer traditioneller Vermögenswerte.",
          "Diese Möglichkeit inspirierte Circle dazu, 2018 USDC einzuführen.",
          "Unter dem Einfluss der globalen Rolle des US-Dollars im internationalen Finanzwesen sind die Chancen in jüngster Zeit enorm und beginnen, große Auswirkungen zu haben. In Lateinamerika machen US-Dollar mehr als 90% des Handelsvolumens aus, im asiatisch-pazifischen Raum 74%, und in Europa und anderen Teilen der Welt außerhalb Europas 79%. Ein Drittel der US-Dollar-Banknoten weltweit wird außerhalb der Vereinigten Staaten gehalten.",
          "Die globale Stellung des US-Dollars bedeutet, dass die überwältigende Mehrheit der Stablecoin-Aktivitäten heute in US-Dollar denominiert ist. USDC ermöglicht es fast jedem, überall, auf das digitale Dollar zuzugreifen, zu halten und zu handeln. USDC ist in über 190 Ländern verfügbar und wird in fast 3 Millionen Blockchain-Wallets aufbewahrt.",
          "Offene Währungsplattformen im Internet",
          "In den letzten Jahren hat USDC als Stablecoin-Netzwerk exponentielles Wachstum erlebt. Seit seiner Einführung im Jahr 2018 wurde USDC in Blockchain-Transaktionen im Wert von etwa 12 Billionen US-Dollar verwendet. Immer mehr Menschen in immer mehr Ländern erleben die Vorteile des Zugriffs auf digitale Dollar auf der Blockchain. Dies hat Entwickler dazu angeregt, neue Anwendungen zu bauen, das Service-Ökosystem rund um USDC zu stärken und es einfacher zu machen, auf USDC zuzugreifen, es zu senden und zu speichern.",
          "Der wachsende Anwendungsbereich der Blockchain-Technologie erinnert an die Einführung von Cloud-Computing. In den frühen Jahren wurde Cloud-Computing als Nischenmarkt angesehen, der riskant war, aber heute sind die inhärenten Vorteile von Cloud-Computing für Unternehmen offensichtlich. Heute werden 60% der Unternehmensdaten in der Cloud gespeichert, und neun von zehn großen Unternehmen verwenden Multi-Cloud-Architekturen. Eine Phase der Randnotizen. Das Netzwerkfinanzsystem, das Circle aufbaut, wird nicht mit der Cloud konkurrieren; es wird ihr helfen. Tatsächlich bringt das Blockchain-Netzwerk vertrauenswürdige Daten, Transaktionen und Rechenleistung ins öffentliche Internet.",
          "Während immer mehr Unternehmen in Unternehmensblockchains Web3 übernehmen, stehen wir an der Schwelle zur nächsten Phase dieser Evolution.",
          "USDC als Dollar-API für das Netzwerkfinanzwesen ist im Wesentlichen eine Dollar-API für öffentliche Netzwerke. Es ist ein gut regulierter Open-Source-Baustein, der einfach in andere Fintech-, Bank- und digitale Währungsprojekte integriert werden kann, um nahezu sofortige, nahezu kostenlose Transaktionen in der weltweit am weitesten verbreiteten Währung zu ermöglichen. In einer Zeit, in der der Fintech-Stack schnell wächst und APIs immer populärer werden, eröffnet dies enorme Möglichkeiten.",
          "Speziell für Blockchain-Interoperabilität entwickelt, ist USDC auf 15 Blockchain-Netzwerken verfügbar. Mit der Zeit planen wir, USDC an Orten verfügbar zu machen, an denen Entwickler aktiv sind und die richtigen Sicherheitsmaßnahmen ergreifen, damit es sicher und einfach durch das Blockchain-Ökosystem fließen kann.",
          "Gleichzeitig arbeiten wir daran, die Komplexität der Blockchain aus dem Blickfeld der Menschen zu entfernen. Das kürzlich eingeführte Cross-Chain Transfer Protocol (CCTP) hilft, Reibungen zu reduzieren, die Sicherheit zu erhöhen und die Kosten für das Senden von USDC von einer Blockchain auf eine andere zu senken. In naher Zukunft ist es unser Ziel, CCTP-Unterstützung auf allen Blockchains zu aktivieren, auf denen USDC existiert, damit USDC nahtlos über das Internet gesendet werden kann, ohne dass Benutzer vor der Herausforderung stehen, USDC von einer Blockchain auf eine andere zu übertragen.",
          "Blockchain für Entwickler leichter machen",
          "Tausende von Entwicklern arbeiten bereits an Blockchain-Netzwerken, und wir erwarten, dass diese Zahl in den kommenden Jahren schnell steigen wird. Circle bietet programmierbare Wallets, Smart-Contract-Vorlagen und andere Dienste an, um das Wachstum dieses Ökosystems zu unterstützen und den Prozess für Unternehmen und Entwickler zu vereinfachen, neue Anwendungen auf Blockchain-Netzwerken zu erstellen. Unternehmen, die bereits in großem Maßstab tätig sind, können USDC und andere digitale Assets einfach in ihre bestehenden Kundenerfahrungen integrieren, ohne alles von Grund auf neu aufbauen zu müssen."
        ],
      7: [
          "Laden Sie Ihr Dollar hoch",
          "USDC ist ein digitaler Dollar, auch als Stablecoin bezeichnet, der 24/7 verfügbar ist und mit Netzwerkgeschwindigkeit bewegt wird. USDC selbst existiert im Internet und läuft auf vielen der weltweit fortschrittlichsten Blockchains. Jeden Tag wechseln Milliarden von USDC den Besitzer, und jeder USDC-Dollar kann 1:1 in US-Dollar eingelöst werden.",
          "Immer offen. Immer verfügbar.",
          "USDC überschreitet Grenzen und Banköffnungszeiten. Als globaler digitaler Dollar kann USDC jederzeit und überall genutzt werden, wenn Sie es brauchen. USDC kann leicht weltweit gesendet werden, um Waren und Dienstleistungen zu bezahlen oder für zukünftige Ersparnisse zu sparen. Jeder mit Internetzugang kann USDC senden, empfangen und speichern.",
          "Sofortiger Cashflow. Rund um die Uhr. Jeden Tag.",
          "Sie müssen nicht mehr auf den Abschluss von Transaktionen und das Abheben von Bargeld warten. USDC macht den Begriff der Abwicklungszeiten veraltet, Zahlungen können genauso schnell wie E-Mails die Welt umrunden und auf Ihrem Konto ankommen. Stellen Sie sich vor, dass jede Währungsumrechnung in wenigen Sekunden abgeschlossen ist – das ist die Kraft von USDC.",
          "Wirtschaftlich effektive Überweisungen",
          "Geld sollte nicht extra kosten. Verabschieden Sie sich von den 50-Dollar-Gebühren für Überweisungen – begrüßen Sie den Centbetrag. Händler können die 2,9 % Kreditkartengebühren vermeiden, von sofortigem Cashflow profitieren und die gesparten Kosten an die Kunden weitergeben.",
          "Finanzen neu erfinden",
          "USDC hebt die Fähigkeiten von Bargeld auf ein neues Niveau. Durch USDC werden die Handels-, Kredit- und Fundraising-Möglichkeiten der globalen Kryptowährungskapitalmärkte freigeschaltet. Die Möglichkeiten haben gerade erst begonnen.",
          "Offene Währungsplattform",
          "Nutzen Sie die globale, Open-Source, interoperable und kostenlose Uber-Geld-API, um Ihre Produkte zu entwickeln. Entwickler vertrauen USDC als Kernbaustein ihrer Anwendungen, um Benutzern sofortige Zahlungen, Transaktionen und Finanzdienstleistungen zu bieten. Verwirklichen Sie große Träume mit USDC.",
          "USDC verändert die Finanzwelt",
          "USDC ist Teil eines globalen Ökosystems, das sowohl traditionelle als auch Kryptowährungsunternehmen abdeckt. USDC wurde von dem regulierten Fintech-Unternehmen Circle entwickelt und ist ein vertrauenswürdiger, weit akzeptierter und hochliquider digitaler Dollar.",
          "Vertrauenswürdige Stabilität",
          "USDC kann jederzeit 1:1 gegen US-Dollar eingelöst werden. Die Reserven von USDC werden von führenden amerikanischen Finanzinstituten wie BlackRock und der Bank of New York Mellon verwaltet und aufbewahrt.",
          "Sehen Sie sich die monatliche Zertifizierung an, die nach den Zertifizierungsstandards der American Institute of Certified Public Accountants (AICPA) garantiert, dass die USDC-Reserven größer oder gleich der monatlichen Umlaufmenge sind.",
          "Wie PayPal, Stripe und Apple Pay ist Circle ein nach US-Staatengesetzen lizenziertes Geldübertragungsunternehmen. Die Finanzberichte von Circle werden jährlich geprüft.",
          "USDC ist überall",
          "Wählen Sie Ihre Reise in die Welt des digitalen Dollars. Erfahren Sie mehr über die verschiedenen Möglichkeiten, wie Unternehmen, Benutzer und Entwickler USDC verwenden – oder holen Sie sich sofort USDC.",
          "Unternehmen",
          "Bieten Sie sofortigen Cashflow mit blitzschnellen USDC-Zahlungen und beschleunigen Sie Ihr globales Wachstum. Erhalten Sie USDC-Liquidität, um auf den Krypto-Kapitalmärkten zu handeln, zu leihen und zu investieren.",
          "Ökosystem",
          "Erleben Sie mit USDC die nächste Generation von Währungen. Senden, konsumieren, speichern und tauschen Sie digitale Dollar rund um die Uhr mit jedem weltweit – mit Internetgeschwindigkeit.",
          "Entwickler",
          "Integrieren Sie USDC sofort in Ihre Anwendung – USDC ist ein programmierbarer digitaler Dollar, der Open-Source und kombinierbar ist, sodass jeder ihn zum Bauen nutzen kann.",
          "Warum Führungskräfte sich für USDC entscheiden",
          "Ob Transparenz oder Programmierbarkeit, USDC wurde für die Zukunft entwickelt.",
          "Erfahren Sie, warum Innovatoren im Konsens 2023 Circle verwenden, um zu bauen",
          "Wir haben unsere Partner gefragt, warum sie sich für Circle und USDC entscheiden, und die Antwort war eindeutig: Circle und USDC bauen anders. Circle macht es schnell, einfach zu nutzen und umzusetzen, während die Reserven USDC von anderen Stablecoins abheben.",
          "Erfahren Sie, wie die Gründer von Circle Ventures USDC nutzen",
          "Obwohl Vertrauen und Transparenz wie ein Risiko erscheinen mögen, ist dies der Hauptgrund, warum die Gründer von Unternehmen im Circle Ventures Portfolio sich für USDC entscheiden. Weitere Gründe sind die programmierbare Liquidität, nahezu sofortige Abwicklungszeiten, Effizienz und mehr.",
          "Stabilitätsgesetzgebung für Stablecoins festlegen",
          "USDC wird häufig als Entwurf für regulierte digitale Währungen angesehen. Auf höchster Ebene arbeitet Circle eng mit Führungskräften des US-Kongresses zusammen, um die offenen Werte von Web3 mit dem traditionellen Finanzsystem zu verbinden und eine harmonische, vernetzte Zukunft zu schaffen.",
          "Ist USDC eine Kryptowährung?",
          "USDC ist ein vollständig reservierter Stablecoin und eine Art von Kryptowährung oder digitalen Asset. Im Gegensatz zu anderen Kryptowährungen mit Preisvolatilität ist USDC darauf ausgelegt, den Wert im Verhältnis zum US-Dollar stabil zu halten. USDC ist ein stabiles Wertaufbewahrungsmittel, das von den Vorteilen der Geschwindigkeit und Sicherheit der Blockchain-Technologie profitiert.",
          "Wie kann ich sicher sein, dass USDC immer 1:1 gegen den Dollar eingelöst werden kann?",
          "USDC ist ein digitaler Dollar, der zu 100 % von hochliquiden Bargeld- und Barmitteläquivalenten gedeckt ist und jederzeit 1:1 gegen US-Dollar eingelöst werden kann. Ein Teil der Reserven von USDC wird in den Circle Reserve Fund (USDXX) investiert, einem von BlackRock verwalteten Geldmarktfonds, der von der SEC reguliert wird. Tägliche unabhängige Drittanbieterberichte über das Portfolio sind öffentlich zugänglich. Weitere Informationen finden Sie auf unserer Transparenzseite.",
          "Was bedeutet es, wenn USDC ‚geprägt‘ oder ‚verbrannt‘ wird?",
          "Unternehmen können ein Circle Mint-Konto eröffnen und US-Dollar gegen USDC eintauschen. Wenn ein Unternehmen US-Dollar auf sein Circle Mint-Konto einzahlt, gibt Circle dem Unternehmen den entsprechenden Betrag in USDC aus. Der Prozess, bei dem neue USDC ausgegeben wird, wird als ‚Prägung‘ bezeichnet. Dieser Prozess schafft neuen USDC, der in Umlauf kommt.",
          "Ebenso können Unternehmen, die ihre USDC gegen US-Dollar eintauschen möchten, ihren USDC auf ihr Circle Mint-Konto einzahlen und kostenlos US-Dollar anfordern. Der Prozess, bei dem USDC zurückgetauscht wird, wird als ‚Verbrennen‘ bezeichnet. Dieser Prozess lässt USDC aus dem Umlauf verschwinden.",
          "Was benötigt es, um USDC zu erhalten?",
          "Eine schnelle und einfache Möglichkeit für Unternehmen, auf USDC zuzugreifen, ist die Beantragung eines Circle Mint-Kontos.",
          "USDC kann auch schnell über Krypto-Börsen wie Coinbase, Crypto.com und Kraken bezogen werden.",
          "Entwickler können heute auf unserem GitHub-Repository auf USDC zugreifen und mit dem Bauen beginnen. Unsere Entwicklerseite für USDC bietet weitere Ressourcen.",
          "Auf welchen Blockchains ist USDC verfügbar?",
          "USDC ist auf 15 Blockchains verfügbar: Algorand, Arbitrum, Avalanche, Base, Ethereum, Flow, Hedera, NEAR, Noble, OP Mainnet, Polkadot, Polygon PoS, Solana, Stellar und TRON, und es werden in diesem Jahr und darüber hinaus noch viele weitere Blockchains erwartet.",
          "USDC wird auch über Drittanbieter-Brücken zu vielen neuen Blockchains verbunden, wodurch Brückenformen von USDC, wie USDC.e, entstehen.",
          "Weitere Informationen finden Sie auf unserer Entwicklerseite für USDC.",
          "Wie unterscheidet sich USDC von CBDC (Central Bank Digital Currencies)?",
          "USDC wird von dem privaten Unternehmen Circle herausgegeben, während CBDCs von Regierungen herausgegeben werden. Während die meisten CBDCs noch in der Forschungsphase sind, existiert USDC bereits und wird weltweit von Millionen von Menschen verwendet. Die von Circle entwickelte Technologie ermöglicht es USDC, auf 11 öffentlichen Blockchains zu laufen, und Innovationen im Open-Source- und privaten Markt treiben das schnelle Voranschreiten des digitalen Dollar-Modells voran.",
          "Erfahren Sie mehr über Circles Perspektive zur CBDC-Diskussion der Federal Reserve."
      ],
      8: [
        "Was ist der US-Dollar-Münzen (USDC)?",
        "Ein Smart Contract ist ein selbst ausführendes digitales Protokoll, das es zwei oder mehr Parteien ermöglicht, Geld, Eigentum, Aktien oder andere wertvolle Dinge transparent und konfliktfrei auszutauschen, ohne dass ein Dritter erforderlich ist.",
        "Vereinfacht gesagt, können Sie einen Smart Contract als einen Automaten für komplexe Transaktionen betrachten.",
        "Wie Smart Contracts funktionieren: Sie folgen einfachen „Wenn/Dann...“-Anweisungen, die in den Code auf der Blockchain geschrieben werden. Wenn die vordefinierten Bedingungen erfüllt und validiert sind, führt das Netzwerk die Aktion aus. Diese Aktionen können das Auszahlen von Geldern an die entsprechenden Parteien, die Registrierung von Fahrzeugen, das Versenden von Benachrichtigungen oder das Ausstellen von Bußgeldern umfassen. Wenn die Transaktion abgeschlossen ist, wird die Blockchain aktualisiert. Das bedeutet, dass Transaktionen nicht mehr geändert werden können und nur autorisierte Parteien die Ergebnisse sehen können.",
        "Mit einem Smart Contract legen Sie einfach Bitcoin in den Automaten (also das Ledger), und alle Verwaltungs-, Vertrags-, Vertrags- oder Warenaufgaben werden automatisch von Ihrem Konto übernommen. Der Smart Contract sorgt dafür, dass die Bedingungen der Bestellung erfüllt sind.",
        "Smart Contracts definieren die Regeln und Strafen eines Vertrages wie traditionelle Verträge und führen diese Verpflichtungen automatisch aus.",
        "Wie verdienen Smart Contracts Geld?",
        "Smart Contract-Entwickler haben hauptsächlich zwei On-Chain-Methoden, um ihre Arbeit zu monetarisieren: Sie starten eine doppelte Gewinnfunktion, die für die Nutzung des Protokolls erforderlich ist, und die Blockchain sendet 90 % der Gewinne, die sie als Belohnung erhält, an die Benutzer, die den Smart Contract unterzeichnen. Sie erheben eine doppelte Provision für die Nutzung von Smart Contracts.",
        "Beispiel: Ein Smart Contract funktioniert ähnlich wie ein Bankeinlagenkonto, bei dem Sie doppelte Zinsen erhalten.",
        "Gibt es Risiken bei Smart Contracts?",
        "Smart Contracts sind automatisch ausgeführte Codes auf der Blockchain-Plattform, die durch automatisierte Prozesse und vertrauenslose Transaktionen die gesamte Branche verändern. Deshalb erhalten sie auch Aufmerksamkeit im Bereich der Blockchain-Finanzierung. Bis jetzt haben wir noch keinen Fall von Risiken bei Smart Contracts erlebt.",
        "Okay, wo existieren Smart Contracts? Wo werden sie ausgeführt? Wie funktionieren sie?",
        "Was Sie wissen müssen",
        "Smart Contracts sind automatisch ausführende Codes, bei denen die Bedingungen eines Vertrags zwischen Käufer und Verkäufer durch Computernetzwerke automatisch überprüft und ausgeführt werden.",
        "Nick Szabo, ein amerikanischer Informatiker, erfand 1998 eine virtuelle Währung namens „Bit Gold“, in der er Smart Contracts als computerisierte Transaktionsvereinbarungen zur Ausführung von Vertragsbedingungen definierte.",
        "Smart Contracts, die auf der Blockchain bereitgestellt werden, machen Transaktionen nachvollziehbar, transparent und unumkehrbar.",
        "Vorteile der Genauigkeit, Geschwindigkeit und Effizienz von Smart Contracts",
        "Sobald die Bedingungen erfüllt sind, wird der Vertrag sofort ausgeführt.",
        "Da Smart Contracts digital und automatisiert sind, müssen keine Papierarbeiten bearbeitet werden.",
        "Es gibt keine Fehler, die durch manuelles Ausfüllen von Dokumenten entstehen.",
        "Vertrauen und Transparenz",
        "Es gibt keine Sorge, dass Informationen zu eigenen Gunsten manipuliert werden, weil keine Drittparteien beteiligt sind und die Teilnehmer verschlüsselte Transaktionsprotokolle austauschen.",
        "Smart Contracts werden von Computerprogrammen, die auf Blockchain-Netzwerken ausgeführt werden, validiert, ausgeführt und durchgesetzt. Wenn beide Parteien die Bedingungen eines Smart Contracts akzeptieren, wird das Programm automatisch ausgeführt. Dies eliminiert den Bedarf an einem Dritten, weil der Vertrag vom Blockchain-Netzwerk validiert und ausgeführt wird.",
        "Da Smart Contracts von Code und nicht von Menschen ausgeführt werden, eliminieren sie menschliche Fehler und können viele Aufgaben automatisch ausführen, die traditionell menschliche Interaktion erforderten.",
        "Einer der besten Aspekte der Blockchain ist, dass es sich um ein dezentrales System handelt, das zwischen allen zugelassenen Parteien existiert, sodass keine Zahlungen an Mittelsmänner erforderlich sind, was Zeit spart und Konflikte reduziert.",
        "Natürlich hat die Blockchain auch ihre Probleme, aber es lässt sich nicht leugnen, dass sie schneller, billiger und sicherer ist als traditionelle Systeme. Deshalb sehen wir immer mehr Smart Contracts, die auf verschiedenen Blockchain-Netzwerken ausgeführt werden, einschließlich Ethereum, Solana, Tezos, Hyperledger und mehr.",
        "Laden Sie Ihre Brieftasche auf",
        "Nutzen Sie NFTs, DeFi-Protokolle, USDC und mehr, um innovative Erlebnisse zu schaffen. Fügen Sie mehr Funktionen zu Ihrer Brieftasche hinzu, indem Sie Smart Contracts integrieren.",
        "Steigern Sie die Loyalität und Kundenbeteiligung",
        "Erstellen Sie neue, einzigartige Erlebnisse, um mit Ihren Kunden zu interagieren und die Loyalität zu steigern, einschließlich NFT-Drops, Loyalitätspunkte oder Token, die gegen echte Erlebnisse eingelöst werden können.",
        "Tausch, Staking oder Bridging",
        "Durch die Einbettung von Smart Contracts können DeFi-Protokolle nahtlos in Ihre Anwendung integriert werden, wodurch die Lücke zum DeFi-Ökosystem verringert wird.",
        "Skalieren mit USDC",
        "Nutzen Sie Smart Contracts, um programmierbare USDC-Transaktionen einfach zu aktivieren und zu verwalten, wie z. B. Gehaltsabrechnungen, Abonnements oder Zahlungen.",
        "Bringen Sie Web3-Interaktionen schneller in Ihre Anwendung",
        "Vitalik Buterin über Smart Contracts",
        "Wie der 28-jährige Ethereum-Gründer Vitalik Buterin auf dem DC Blockchain Summit erklärte: „Im Smart Contract-Ansatz werden Vermögenswerte oder Währungen in ein Programm verschoben, das den Code ausführt und irgendwann automatisch eine Bedingung überprüft. Es bestimmt dann, ob ein Vermögenswert einer Person gehören sollte oder an eine andere zurückgegeben werden soll, oder ob er sofort an den Absender des Vermögenswerts zurückgegeben werden sollte – oder eine Kombination aus beidem.",
        "Gleichzeitig speichert das dezentrale Hauptbuch auch Dokumente und kopiert sie, was zu einer gewissen Sicherheit und Unveränderlichkeit führt.",
        "Beispiel: Treuhandgeschäfte",
        "Angenommen, Sie mieten eine Wohnung von mir.",
        "Sie können dies durch eine Kryptowährungszahlung auf der Blockchain erledigen. Sie erhalten den Beleg, der in unserem virtuellen Vertrag gespeichert ist; Ich gebe Ihnen einen digitalen Zugangsschlüssel, der bis zu einem bestimmten Datum bei Ihnen ankommt. Wenn der Schlüssel nicht rechtzeitig ankommt, wird die Blockchain eine Rückerstattung ausstellen.",
        "Wenn ich den Schlüssel vor dem Mietdatum sende, wird diese Funktion ihn behalten und ihn zusammen mit der Zahlung nach Erreichen des Mietdatums an Sie und mich freigeben.",
        "Das System läuft unter der „Wenn-Dann“-Bedingung und wird von Hunderten von Zeugen überprüft, sodass Sie eine perfekte Lieferung erwarten können.",
        "Wenn ich Ihnen den Schlüssel gebe, werde ich sicher bezahlt. Wenn Sie eine bestimmte Menge an Bitcoin senden, erhalten Sie den Schlüssel. Das Dokument wird nach der angegebenen Zeit automatisch abgebrochen, und keiner von uns kann den Code stören, ohne dass der andere es weiß, weil alle Teilnehmer gleichzeitig benachrichtigt werden.",
        "Erkennen von realen Anwendungsfällen für Smart Contracts",
        "Jerry Cuomo, Vice President der Blockchain-Technologie bei IBM, ist der Ansicht, dass Smart Contracts in der gesamten Wertschöpfungskette verwendet werden können, von Finanzdienstleistungen über Gesundheitswesen bis hin zu Versicherungen. Hier einige Beispiele.",
        "Dezentrale Finanzanwendungen",
        "Mit dem Aufstieg von DeFi-Anwendungen sehen wir einen dramatischen Wandel in unserer Art der Geldverwaltung.",
        "Dezentrale Finanzen sind ein Ausstieg aus traditionellen Bankdienstleistungen und -regulierungen. Es handelt sich um eine sehr subtile Veränderung, aber sie scheint unvermeidlich zu sein.",
        "Wir haben Daten, die dies belegen: Der jährliche Transaktionsbetrag von DeFi wuchs 2020 um das 14-fache, 2021 verdoppelte sich der Betrag auf über 112 Milliarden USD, und der Gesamtwert, der in DeFi-Smart Contracts gesperrt ist, überstieg 20 Milliarden USD.",
        "Smart Contracts in DeFi ermöglichen den Austausch von Waren, Dienstleistungen, Daten und Kapital. Benutzer von zentralisierten Finanzinstitutionen wie Banken und Kreditgenossenschaften verlassen sich auf Vermittler, um Transaktionen durchzuführen. DApps verwenden jedoch Smart Contracts, um sicherzustellen, dass jede Operation authentisch, transparent und frei von menschlichen Fehlern ist.",
        "In nur wenigen Jahren sind NFTs auf dem Markt explodiert und haben 2021 einen erstaunlichen Marktwert von 40,9 Milliarden USD erreicht, da sie das erfolgreichste Anwendungsbeispiel für Smart Contracts geworden sind.",
        "NFTs werden durch den Prägeprozess erstellt, der die Bereitstellung von Smart Contracts auf der Blockchain erfordert.",
        "Smart Contracts sind ein Werkzeug, das die Verkaufsvereinbarungen zwischen NFT-Besitzern und Käufern ausführt. Smart Contracts enthalten Informationen über das NFT, wie z. B. den Ersteller des Werks, andere Parteien, die bei jedem Verkauf des NFTs Tantiemen erhalten, sowie die Eigentumshistorie des Werks.",
        "Die Bedingungen eines Smart Contracts werden durch die vertraglich vereinbarten Regeln in den Blockchain-Code geschrieben. Ein NFT-Verkäufer und ein Käufer können sich dann darauf einigen, dass der Verkäufer dem Käufer ein digitales Kunstwerk für eine bestimmte Menge an Kryptowährung oder Fiat-Währung verkauft. Sobald die Bedingungen des Vertrags ausgeführt sind, erfolgt der Transfer der digitalen Kunst an den Käufer, und die Zahlung wird an den Verkäufer gesendet. Da die Transaktionen in einer öffentlichen Blockchain ausgeführt werden, können alle Teilnehmer die vollständige Transaktionshistorie einsehen.",
        "Ein NFT-Smart Contract enthält typischerweise eine Gebühr für den Plattformbetreiber (z. B. OpenSea) und für den Ersteller des NFTs, wobei letzterer eine Gebühr von jeder Weiterverkaufsaktivität erhalten kann."
      ],      
      9: [
          "NFT verändert digitale Kunst, Sport-Sammlerstücke, Medien usw.",
          "Dieses Ökosystem verändert die Art und Weise, wie digitale Produkte erstellt, verteilt und gesammelt werden, revolutionär.",
          "NFT-Bereich wächst schnell:",
          "Blockchain",
          "Die Plattform für das Hosting und die Prägung von NFTs.",
          "Markt",
          "Der Ort, an dem NFTs gelistet und gehandelt werden.",
          "NFT-Projekte",
          "Von generativer Kunst bis hin zu virtuellen Immobilien und mehr.",
          "Aber bevor wir dieses Ökosystem erkunden, lassen Sie uns zunächst NFT definieren.",
          "Berechnung der Austauschbarkeit von Qut",
          "Was sind NFTs?",
          "NFTs sind kryptografisch gesicherte Token, deren Besitzgeschichte und aktueller Eigentümer auf der Blockchain gespeichert sind.",
          "Sie bieten einen starken Primär- und Sekundärmarkt für digitale Produkte, eliminieren Zwischenhändler und ermöglichen es den Schöpfern, den vollen Nutzen aus ihren Werken zu ziehen.",
          "Austauschbar vs. nicht austauschbar",
          "Austauschbar",
          "Austauschbarkeit bedeutet, dass die Einheiten eines Produkts miteinander austauschbar sind und der Wert nicht differenziert werden kann.",
          "Währungen wie der Dollar sind austauschbar. Jeder Dollar kann ersetzt werden und hat den gleichen Wert wie jeder andere Dollar.",
          "Nicht austauschbare Token (NFTs) oder Waren haben einzigartige Eigenschaften, die ihnen einen anderen Wert verleihen als ähnliche Token oder Waren.",
          "Ein Haus mit Terrasse hat einen anderen Wert und ist nicht austauschbar mit einem Haus ohne Terrasse.",
          "Die Terrasse könnte eine der vielen Eigenschaften sein, die das Haus einzigartig machen und ihm einen unterschiedlichen Wert verleihen.",
          "Ähnlich haben nicht austauschbare Token verschiedene Kunst- oder andere Eigenschaften, die sie von anderen Token unterscheiden und ihnen einzigartigen Wert verleihen.",
          "Cryptopunks sind eine Sammlung von 10.000 Pixel-Art 'Punks' NFTs, von denen keines wie das andere ist.",
          "Verschiedene Seltenheitseigenschaften machen einige Punks seltener und wertvoller.",
          "Während die Seltenheit der Eigenschaften häufig den Wert bestimmt, beeinflussen auch ästhetische und subjektive Vorlieben von Investoren und Sammlern die Bewertung.",
          "Punks mit der Eigenschaft 'Buck Teeth' gibt es 78, mit der Eigenschaft 'Hoodie' gibt es 259, und 'Hoodie'-Punks sind dreimal so wertvoll wie 'Buck Teeth'-Punks.",
          "Quelle: Larya Labs",
          "Wert in US-Dollar basierend auf einem ETH-Preis von 4.430 USD",
          "Punk-Werte zum 29. November 2021",
          "Starke Prägestätten",
          "Blockchain-Plattformen, die NFTs hosten",
          "Marktkapitalisierung",
          "Die meisten dieser Blockchains verwenden ein Proof-of-Stake-Konsensmodell, das im Vergleich zum Proof-of-Work-Modell deutlich weniger Energie verbraucht.",
          "Ethereum wird irgendwann im nächsten Jahr auf Proof-of-Stake umsteigen.",
          "Tezos hat eine spezielle NFT-Community und ist eine der frühesten NFT-Plattformen außerhalb von Ethereum.",
          "Quelle: Messari.io",
          "Stand: 29. November 2021",
          "Erkundung des Ethereum-Token-Standards",
          "Ethereum ist die größte Plattform für NFTs, wobei NFTs einen großen Teil des Netzwerktrafiks und der Aktivitäten ausmachen.",
          "Mit dem ERC-721-Token zusammen:",
          "Der ERC-20-Token-Standard wird verwendet, um fungible Token zu erstellen und ist der am häufigsten verwendete Standard im gesamten Ethereum-Netzwerk.",
          "Der ERC-1155-Token-Standard wurde von Enjin entwickelt und ermöglicht die Erstellung von sowohl fungiblen als auch nicht fungiblen Vermögenswerten.",
          "ERC-998 ist eine Erweiterung des ERC-721-Standards, die es NFTs ermöglicht, sowohl NFTs als auch ERC-20-Token zu besitzen.",
          "Der Ethereum-NFT-Token-Standard kann mit dApps (dezentralen Anwendungen) interagieren, die ebenfalls auf der Ethereum-Blockchain aufgebaut sind, was eine nahtlose Integration ermöglicht.",
          "Ethereum Name Service NFTs ändern den Namen Ihrer Ethereum-Wallet-Adresse auf eine ausgewählte '.eth'-Adresse, um eine einzigartige und einprägsame Adresse zu erhalten.",
          "Diese NFTs werden jährlich gekauft, ähnlich wie Internet-Domainnamen.",
          "Großer Sekundärmarkt",
          "Derzeit gibt es mehrere Sekundärmärkte, auf denen NFTs gekauft und verkauft werden.",
          "OpenSea hat sich zur führenden Plattform entwickelt. Das Handelsvolumen im November 2021 betrug 1,9 Milliarden USD.",
          "Der aktuelle Stand der NFTs",
          "Digitale Kunst, Sammlerstücke, Spiele und mehr",
          "NFTs sind nicht nur digitale Eigentumsnachweise, sondern können auch mit physischen Vermögenswerten oder Vorteilen verknüpft werden.",
          "Das neueste Album von Kings of Leon, 'When You See Yourself', wurde zusammen mit NFTs veröffentlicht, die verschiedene physische Vermögenswerte und Vorteile bieten.",
          "Ein NFT für 50 AUD (35,70 GBP) ermöglicht dem Besitzer den Zugang zu digitalen Downloads des Albums, einer physischen Vinyl-Schallplatte und digitalen Merchandise-Artikeln.",
          "Sechs 'Gold-Ticket NFTs' ermöglichen den Inhabern lebenslangen Zugang zu vier Front-Row-Plätzen bei allen Band-Auftritten.",
          "Der NFT-Set 'Currency' des britischen Künstlers Damien Hirst erforscht, wie Menschen NFTs und physische Kunst bewerten.",
          "Die Currency besteht aus 10.000 einzigartigen NFTs, die jeweils ein physisches Kunstwerk repräsentieren, das 2016 erstellt wurde.",
          "Das physische Kunstwerk wird in einem Safe in Großbritannien aufbewahrt, und der NFT-Käufer hat ein Jahr (bis zum 27. Juli 2022), um zu entscheiden, ob er den NFT oder das physische Werk behalten möchte; wenn eine Entscheidung getroffen wird, wird das andere zerstört.",
          "Derzeit gibt es drei Hauptzweige im NFT-Bereich, die jeweils Innovation auf einzigartige Weise vorantreiben:",
          "Von Profilbildern bis hin zu generativer Kunst und kollaborativen Musikprojekten, NFTs treiben die digitale Kunstwelt voran. Es sind nicht nur visuelle Kunst-NFTs, sondern auch Musik und Sport-Sammlerstücke.",
          "Der NFT-Gaming-Bereich ist voller Innovationen, mit verschiedenen Projekten, die das Eigentum der Spieler an In-Game-Vermögenswerten und das Konzept des 'Play-to-Earn' untersuchen.",
          "NFTs definieren digitales Eigentum, Identität und Raum neu, indem sie digitale Grundstücke im virtuellen Raum und 3D-Avatare als Token darstellen.",
          "Über digitale Dinge hinaus.",
          "Obwohl NFTs einen riesigen Boom erlebt haben, ist der Aufschwung noch lange nicht zu Ende.",
          "Neben diesen Bereichen wird NFT weiterhin als Methode zur Tokenisierung und digitalen Übertragung realer Vermögenswerte (wie Immobilien oder physische Kunstwerke) erforscht.",
          "Fast jedes physische Asset oder Gut kann tokenisiert werden, um Eigentum leichter nachzuweisen und zu handeln, während es auch Möglichkeiten für Fragmentierung, Verpfändung und den Nutzen von Smart Contracts eröffnet.",
          "Zeichnen Sie das NFT-Ökosystem",
          "NFTs waren 2021 das heißeste Thema und der Markt mit der größten Blase, mit einem Umsatzwachstum von 100-fach, und sie wurden zum Thema in Late-Night-Talkshows.",
          "Kryptowährungen brauchten fast zehn Jahre, um wirklich in den Mainstream vorzudringen, aber NFTs haben in nur wenigen Jahren die Aufmerksamkeit der Menschen auf sich gezogen. Mit Marken wie Budweiser, Visa und Adidas, die NFTs kaufen und in den Bereich eintreten, ist klar, dass NFTs mehr sind als nur ein weiterer vorübergehender Trend.",
          "Dieses von Next Decentrum gesponserte Infografik definiert NFTs und untersucht das schnell wachsende Ökosystem rund um NFTs. Erfahren Sie mehr über die Bedeutung der Nicht-Austauschbarkeit, die Orte der NFT-Prägung und den Handel sowie die Zukunft dieser Asset-Klasse.",
          "Was sind NFTs und was bedeutet Austauschbarkeit?",
          "NFTs sind nicht austauschbare Token, deren Besitzgeschichte und aktueller Eigentümer kryptografisch auf der Blockchain gespeichert sind. Diese Token können alles repräsentieren, von digitalen Kunstwerken im JPEG-Format bis hin zu Songs im MP3-Format.",
          "Durch das Speichern der Transaktionen dieser Token auf der Blockchain können wir das Eigentum an diesen digitalen Gütern und den digitalen Nachweis des Marktes besitzen, ohne uns Sorgen über doppelte Ausgaben oder Manipulationen von Transaktionen und Besitzgeschichten machen zu müssen.",
          "Austauschbarkeit verstehen",
          "Das hört sich sehr nach Kryptowährungen an, also was macht NFTs so besonders? Es ist ihre Nicht-Austauschbarkeit. Im Gegensatz zu Kryptowährungen wie Bitcoin oder Ethereum repräsentieren nicht austauschbare Token Waren oder Vermögenswerte mit einzigartigen Eigenschaften und Merkmalen, die ihnen auch dann einen einzigartigen Wert verleihen, wenn sie zur gleichen Sammlung gehören.",
          "Austauschbar: Waren mit austauschbaren Einheiten, deren Wert nicht differenziert wird. Beispiel: Dollar, Bitcoin, Spielmünzen.",
          "Nicht austauschbar: Waren mit einzigartigen Eigenschaften, die ihnen einen einzigartigen Wert im Vergleich zu ähnlichen Waren verleihen. Beispiel: Immobilien, Gemälde, NFTs.",
          "Die beliebteste NFT-Serie, Cryptopunks, ist eine Sammlung von 10.000 Pixel-Art 'Punks', die verschiedene Eigenschaften wie Hüte, Brillen, Frisuren usw. haben. Die zufällige Kombination von verschiedenen seltenen Eigenschaften verleiht jedem Punk einen einzigartigen Wert.",
          "Seltenheit und subjektive ästhetische Präferenzen treiben die Bewertung von CryptoPunks und anderen NFTs an. Weitere Faktoren wie ihre historische Bedeutung und die Blockchain, auf der sie gehostet werden, können ebenfalls ihren Wert beeinflussen.",
          "Vergleich von Blockchains, die NFTs unterstützen",
          "Es gibt viele verschiedene Blockchains, die NFTs prägen und hosten können, wobei Ethereum derzeit die größte in Bezug auf Marktkapitalisierung und Handelsvolumen ist.",
          "Digitale Kunst, Spiele, das Metaversum und die Zukunft von NFTs",
          "NFTs lösten 2021 einen riesigen Hype aus, der den Schöpfern ein digitalisiertes und dezentralisiertes Netzwerk bot, in dem sie ihre Werke hosten und tauschen können.",
          "Aktuell sind digitale-first-Anwendungsfälle an der Spitze der NFT-Entwicklung, wobei das Eigentum an In-Game-Vermögenswerten oder -Gütern zwei Hauptanwendungsfälle darstellt, die untersucht werden. NFTs können jedoch auch für die Tokenisierung von Immobilien, physischen Kunstwerken und anderen physischen Vermögenswerten verwendet werden, was nahezu unbegrenzte Anwendungsmöglichkeiten bietet.",
          "Von der Beseitigung bürokratischer Hürden bei Immobiliengeschäften bis hin zur Möglichkeit, Eigentum zu fragmentieren, untersucht NFT weiterhin reale Anwendungsfälle in der Welt."
      ],
      10: [
        
          "Egal, ob man Web3 hasst oder liebt, es ist derzeit sehr populär, da Technologeinfluencer wie der Risikokapitalgeber Marc Andreesen, der ehemalige Twitter-CEO Jack Dorsey und der Tesla-CEO Elon Musk kürzlich darüber gestritten haben. Befürworter von Web3 glauben auch, dass es die wirtschaftliche Landschaft neu gestaltet. Gartner prognostiziert, dass Web3 bis 2030 das globale BIP um 1,76 Billionen Dollar steigern wird. Dies schafft auch viele Geschäftsmöglichkeiten für Technikanwälte und Anwaltskanzleien, die in diesem schnell wachsenden Rechtsbereich tätig werden möchten.",
          "Was ist Web3?",
          "Web3 ist eine neue Plattform, die auf dezentraler und Blockchain-Technologie basiert, die von den Erbauern und Nutzern selbst besessen wird. Menschen können Kryptowährungen, NFTs und das Metaversum an diese neue Plattform binden. Auf Web3 können Einzelpersonen direkt Dienstleistungen an andere Nutzer anbieten und wertvolle Dinge im System handeln. Beispiele für Web3-Anwendungen/Netzwerke/Plattformen könnten Metaversen sein, die auf dezentralen Blockchains laufen (eine online 3D-Welt, die mehrere virtuelle Räume kombiniert, in denen Nutzer zusammenarbeiten, sich treffen, spielen und soziale Kontakte pflegen können) und keine Banken nutzen.",
          "Braucht Web3 rechtlichen Schutz?",
          "Es gibt ein Missverständnis, dass dezentrale Plattformen nicht reguliert sind. Darüber hinaus wachsen digitale Vermögenswerte in vielen Teilen der Welt schnell, und lokale Regulierungsbehörden haben Gesetze zur Verwaltung von Transaktionen eingeführt. Zum Beispiel entwickelt das Financial Crimes Enforcement Network (FinCEN) des US-Finanzministeriums Regeln zur Verfolgung von Kryptowährungstransaktionen und zur Meldung verdächtiger Aktivitäten. Außerdem betrachten Australien und Kanada Bitcoin als ein steuerpflichtiges Finanzvermögen. In Asien hat das japanische Parlament im Juni ein Gesetz verabschiedet, um Stablecoins oder Kryptowährungen zu regulieren und die Investoren zu schützen.",
          "Mit dem dramatischen Wachstum der virtuellen Welt weltweit werden rechtliche Bedenken zunehmen. Im Allgemeinen glauben viele Nutzer, dass NFTs unbestreitbare Eigentumsnachweise bieten. Aber die Sache ist möglicherweise nicht so einfach, wie wir denken. Zum Beispiel erklärte der Juraprofessor João Marinotti von der Indiana University, dass virtuelle Landrechte unter Vertragsrecht und nicht unter Sachenrecht fallen. Außerdem verleiht der Kauf eines NFTs dem Nutzer nicht das Eigentum an dem digitalen Vermögenswert.",
          "Obwohl Web3 ein völlig neues und komplexes Feld ist, erfordert es, wenn Probleme auftreten, neuen rechtlichen Schutz. Die Zahl der Kryptowährungs-Klagen wächst. NFTs könnten Streitigkeiten über Vermögenslizenzierung und Mitbesitz mit sich bringen. Heute nutzen Unternehmen das Metaversum als Teil ihrer Geschäfts- und Marketingstrategien, was viele rechtliche Fragen zu Governance, geistigem Eigentum und Verletzungen aufwirft.",
          "Möglichkeiten in Web3",
          "Wenn ein Technologiebereich sein volles Potenzial ausschöpfen will, muss er in einen geeigneten rechtlichen Rahmen eingebunden werden, der die festgelegten Rechte und Pflichten zum Schutz der Interessen aller Beteiligten umfasst. Die rechtlichen Fragen, die wir klären müssen, beinhalten Steuern, virtuelle Governance, regulatorische Herausforderungen, Lizenzen und Eigentum, Transaktionsverfolgung und Berichterstattung, Datenschutz und Schutz geistigen Eigentums.",
          "Um nicht ins Hintertreffen zu geraten, müssen traditionelle Anwaltskanzleien, Anwälte und Jurastudenten die Entwicklung von Web3-Technologien sowie die sich ständig ändernden Gesetze und Vorschriften in Bezug auf Web3 und lokale Regierungen verstehen."
        
      ],
      11: [
          "Wie funktioniert eine Kryptowährungs-Debitkarte?",
          "Kryptowährungs-Debitkarten werden von Unternehmen verwendet, die sich auf Kryptowährungsfinanzdienstleistungen spezialisiert haben, wie z.B. Coin. Diese Unternehmen arbeiten mit Zahlungsnetzwerken wie Visa und Mastercard zusammen, sodass Benutzer Kryptowährungen an Orten verwenden können, die traditionelle Zahlungssysteme akzeptieren.",
          "Dieser Prozess sorgt dafür, dass Ihre Transaktionen sicher und transparent sind. Darüber hinaus bietet er schnelle Umrechnungskurse, sodass keine Stunden oder Tage gewartet werden müssen, um die Umrechnung abzuschließen.",
          "Die richtige Kryptowährungs-Debitkarte auswählen",
          "In diesem Abschnitt werde ich Ihnen zeigen, wie Sie eine Kryptowährungs-Debitkarte erhalten können, um nahtlose alltägliche Transaktionen durchzuführen. Sie können Ihre Kryptowährungen in Fiat-Währung umwandeln und an jedem Ort einkaufen, der Debitkarten akzeptiert. Ich werde Ihnen auch die Faktoren beschreiben, die beim Erwerb der besten Kryptowährungs-Debitkarte auf dem Markt zu beachten sind:",
          "• Unterstützte Kryptowährungen: Um die beste Kryptowährungs-Debitkarte zu erhalten, müssen Sie sicherstellen, dass die Karte Ihre bevorzugte Kryptowährung unterstützt.",
          "• Gebühren: Kryptowährungs-Debitkarten erheben in der Regel verschiedene Gebühren, wie z.B. Aufladegebühren, Transaktionsgebühren und ATM-Abhebungsgebühren. Vergleichen Sie die Gebühren verschiedener Karten, bevor Sie sich für eine entscheiden.",
          "• Belohnungen: Wählen Sie eine Kryptowährungs-Debitkarte mit einem Cashback- oder Belohnungsprogramm, um Ihre Ausgaben zu maximieren. Suchen, vergleichen und wählen Sie die Karte mit dem besten Cashback-Angebot.",
          "• Sicherheit: Kryptowährungs-Debitkarten verwenden verschiedene Sicherheitsfunktionen zum Schutz Ihrer Kryptowährungen. Wählen Sie eine Karte mit starken Sicherheitsfunktionen.",
          "• Kundensupport: Ein weiteres wichtiges Kriterium bei der Auswahl der besten Kryptowährungs-Debitkarte ist der Kundensupport. Wenn der Support nicht in der Lage ist, Ihnen bei Problemen zu helfen, verlieren Sie sowohl Geld als auch Zeit. Stellen Sie sicher, dass der Support zuverlässig ist.",
          "Sie sind eine innovative Lösung, die es Ihnen ermöglicht, Ihre digitalen Vermögenswerte in realen Transaktionen zu nutzen. Durch die Nutzung der Blockchain-Technologie können diese Karten sichere und transparente Transaktionen ermöglichen, die an jedem Ort verwendet werden können, der Visa- oder Mastercard akzeptiert.",
          "Eine der größten Kryptowährungsbörsen in den USA gab am Freitag bekannt, dass sie „eng“ mit globalen Zahlungsnetzwerken zusammenarbeitet, um einen nahtlosen digitalen Vermögensübergang und die Akzeptanz zu fördern.",
          "Die in San Francisco ansässige Kryptowährungsbörse Coinbase, bekannt für ihren regulatorischen Ansatz in Bezug auf Kryptowährungen, erklärte, dass sie „stolz darauf ist, mit Innovatoren wie Visa zusammenzuarbeiten“, um die Akzeptanz von digitalen Währungen zu fördern:",
          "Die Börse erklärte, dass Unternehmen wie Visa dabei helfen, die Lücke zwischen Kryptowährungen und traditionellen Finanzsystemen zu schließen. Dies schafft ein reifes Umfeld, in dem Kryptowährungen mit ihrem globalen Netzwerk verbunden werden, während gleichzeitig die Führungskräfte aus den Bereichen Traditionelle Finanzen und Kryptowährungen zusammengebracht werden.",
          "Visa entwickelt digitale Währungsprogramme",
          "Der Zahlungsriese Visa, der mit einer Bewertung von 420 Milliarden US-Dollar zu den größten Unternehmen der Branche gehört, führte in einem Blogbeitrag Anfang dieser Woche seine Kryptowährungsstrategie aus.",
          "Das Unternehmen mit dem Namen „Vorantreiben unserer digitalen Währungsstrategie“ setzt digitale Vermögenswerte in den Mittelpunkt einer neuen Strategie, um neue Märkte zu erschließen und die globale Finanzinklusion zu fördern.",
          "Visa erklärte, dass es aktiv mit regulierten Kryptowährungsplattformen wie Coinbase zusammenarbeitet, um eine Brücke zwischen digitalen Währungen und unserem bestehenden Netzwerk von 61 Millionen Händlern weltweit zu schlagen.",
          "Das Unternehmen erklärte, dass mehr als 25 digitale Währungs-Wallets derzeit mit dem Visa-Netzwerk verbunden sind, „um den Benutzern eine einfache Möglichkeit zu bieten, ihre Ausgaben von digitalen Währungsbeständen mit Visa-Debitkarten oder Prepaid-Zertifikaten zu tätigen.\"",
          "Weitere Entwicklungen umfassen Innovationen wie Visa Direct – die Sofortübertragung von Fiat-Währung zu Kryptowährung, mit der digitale Währungen schnell umgewandelt und den Visa-Zertifikaten zugefügt werden können.",
          "Visa erklärte, dass diese Bemühungen das Unternehmen zur besten Wahl für die Akzeptanz von digitalen Währungs-Wallets machen, die „darauf brennen, durch die weltweit schnellere und einfachere Nutzung von digitalen Währungen den Wert für ihre Benutzer zu erhöhen.\"",
          "Stablecoins sind ein wichtiger Bestandteil der zukünftigen Wirtschaft",
          "Visa erklärte in seiner breiteren Strategie zur Einführung von Kryptowährungen im öffentlichen Sektor, dass das Unternehmen mit politischen Entscheidungsträgern und Aufsichtsbehörden zusammenarbeitet, um den Unternehmen die Vorteile digitaler Währungen näherzubringen.",
          "Transaktionen mit Kryptowährungs-Debitkarten durchführen",
          "Die Verwendung eines Kryptowährungs-Wallets mit einer Debitkarte ist eine einfache Möglichkeit, Kryptowährungen zu verwenden, ohne den Umrechnungsprozess und die Wartezeit durchlaufen zu müssen. Ihr Anbieter wandelt automatisch Ihre Kryptowährung in Fiat-Währung um und führt die Transaktion durch.",
          "Alltägliche Transaktionen mit einer Kryptowährungs-Debitkarte durchführen",
          "Sie funktionieren ähnlich wie herkömmliche Debitkarten und ermöglichen es Ihnen, sie an jedem Ort zu verwenden, der Visa oder Mastercard akzeptiert. Sie erleichtern alltägliche Transaktionen und bieten Vorteile gegenüber traditionellen Karten, wie z.B. Cashback oder Krypto-Belohnungsprogramme.",
          "Sicherheitsmerkmale von Kryptowährungs-Debitkarten",
          "Kryptowährungs-Finanzkarten verwenden Verschlüsselung, Zwei-Faktor-Authentifizierung, Betrugsschutz, FDIC-Versicherung und befolgen dieselben Sicherheitsrichtlinien wie traditionelle Karten zum Schutz Ihrer Krypto- und persönlichen Daten.",
          "Vorteile der Verwendung von Kryptowährungs-Debitkarten",
          "Wenn Sie eine Kryptowährungs-Debitkarte erhalten, genießen Sie viele Vorteile wie Bequemlichkeit, Belohnungen und vollständige Kontrolle über Ihre Vermögenswerte aufgrund der Dezentralisierung."      
      ],
      12: [
          "USD Coin (USDC) Spar-Konto: Verdienen Sie Krypto-Zinsen",
          "Web3 bietet eine Vielzahl wachsender Krypto-Spar-Konten. Diese risikoarmen, hochverzinslichen Konten helfen den Nutzern, bis zu 200% monatlich an Krypto-Zinsen + Zinseszinsen zu verdienen. USD Coin (USDC) ist eine der am häufigsten verwendeten Stablecoins im Umlauf. Hier sind einige Informationen darüber und weitere Fakten darüber, wie Sie einfach Krypto-Zinsen auf YouHodler sammeln können.",
          "Was ist USD Coin (USDC)? Wie man Krypto verdient",
          "Im Gegensatz zu vielen anderen Kryptowährungen ist USDC eine einzigartige Art von Kryptowährung, die als „Stablecoin“ bezeichnet wird. USDC ist 1:1 durch den US-Dollar gedeckt. Daher können Sie jederzeit 1 USD Coin gegen 1,00 USD eintauschen. Dies schafft ein stabileres Asset als Bitcoin oder andere Kryptowährungen, was es zu einem sicheren Ort für die Aufbewahrung von Vermögenswerten macht.",
          "USDC ist ein Ethereum-basierter Token, was bedeutet, dass Sie es in einem Ethereum-kompatiblen Wallet aufbewahren können, wie zum Beispiel dem USDC Wallet von YouHodler. Das Team hinter USDC hat diesen Stablecoin entwickelt, um den Nutzern zu helfen, US-Dollar weltweit problemlos von Krypto-Wallets an Unternehmen, Börsen und andere zu übertragen. YouHodler hat ihn zu unserer Liste hinzugefügt, als eine weitere großartige Option, um auf der Plattform Krypto-Zinsen zu verdienen.",
          "USDC Spar-Konto: Warum Sie durch Web3 Krypto-Zinsen verdienen sollten",
          "Web3 bietet viele Möglichkeiten, mit digitalen Vermögenswerten Gewinne zu erzielen. Durch Krypto-Spar-Konten können Nutzer hochverzinsliche, risikoarme Investmentinstrumente freischalten. Hier sind einige Gründe, warum Sie durch Web3 Krypto-Zinsen verdienen sollten:",
          "Verdienen Sie bis zu 2,6% und 10% USDC Zinsen durch Krypto: Verdienen Sie jährlich 2,6% und 10% USDC Zinsen auf Web3. Das ist weit mehr als jedes „hochverzinsliche“ traditionelle Bankkonto oder andere Investitionen. Web3 bietet seinen Kunden die besten USDC-Konditionen auf dem Markt.",
          "Sichern Sie Ihr Kapital: Alle Krypto-Vermögenswerte auf Web3 werden mit den besten Sicherheitspraktiken der Branche geschützt. Die Gelder werden immer zu 100% in Hot Wallets aufbewahrt. Wir nutzen eine Kombination aus Hot und Cold Wallets, um sicherzustellen, dass das Kapital der Nutzer maximal geschützt ist.",
          "Krypto-Zinsen werden alle 4 Stunden ausgezahlt, ohne Gebühren: Am Ende jedes 4-Stunden-Abrechnungszeitraums werden alle verdienten Krypto-Zinsen dem Sparkonto des Nutzers gutgeschrieben. Nutzer können wählen, ob sie die Zinsen aus dem Sparkonto auf ihr Wallet abheben oder das Kapital im Konto lassen möchten, um im nächsten Abrechnungszyklus mehr Krypto-Zinsen zu verdienen. (Beachten Sie, dass Sie bis zu drei Mal pro Tag Zinsen abheben können und der Mindestbetrag für eine Auszahlung 1 USDC beträgt)",
          "Entdecken Sie andere Möglichkeiten, Krypto-Zinsen durch Web3 zu verdienen",
          "USDC Zinsen sind nur eine Möglichkeit, durch Web3 Krypto-Zinsen zu verdienen. Besuchen Sie unsere Seite für Zinsen, um eine Vielzahl von Stablecoins und Krypto-Stablecoins zu entdecken, die verfügbar sind. Verdienen Sie Zinsen mit Bitcoin, Binance Coin und mehr. Verdienen Sie Krypto-Zinsen durch ein Sparkonto und finden Sie effektivere Möglichkeiten für langfristige Investitionen.",
          "USDC Zinszahlende Spar-Wallet App",
          "Die Web3 Wallet-App ist die einzige App, die es Ihnen ermöglicht, USD Coin (USDC) wie ein normales Wallet zu speichern und alle 4 Stunden Zinsen aus Ihrem USDC Wallet-Guthaben zu verdienen.",
          "Wie USDC Zinsen funktionieren",
          "Sie können auf USDC Zinsen verdienen, wie bei einem traditionellen Bank-Spar-Konto, aber es gibt einen entscheidenden Unterschied. Wir bieten Ihnen bis zu 10% ETH-Zinsen, im Gegensatz zu den nahezu Nullzinsen der Banken.",
          "Wie man kostenlos USDC verdient",
          "Das ist einfach! Legen Sie USDC in Ihr Web3 Wallet und verdienen Sie alle 4 Stunden kostenlos ETH.",
          "Warum sind die verdienten Zinsen in ETH?",
          "Wenn Sie USDC in Ihr Wallet einzahlen, erhalten Sie alle 4 Stunden Zinsen in Form von Krypto-ETH. Allerdings können Sie das ETH, das Sie auf der Blockchain verdienen, nicht direkt in Ihr Wallet auszahlen lassen. Sie müssen sich in Ihr Web3-Konto einloggen und das ETH, das Sie verdient haben, in USDC umtauschen, bevor Sie es auf Ihr Wallet auszahlen lassen können. Hier erfahren Sie, wie Sie das ETH in Ihrem Web3-Konto in USDC umtauschen.",
          "Bevor Sie die Zinsen abheben, müssen Sie zuerst in Ihr Web3-Konto gehen, auf „Umtauschen“ klicken und dann „Alles Umtauschen“ wählen, um alles umzutauschen.",
          "Der nächste Schritt besteht darin, das umgetauschte USDC aus Ihrem Web3-Konto auf Ihr Wallet abzuheben. Hier ist eine detaillierte Anleitung, wie man USDC abhebt.",
          "Jetzt müssen Sie auf „Abheben“ klicken, dann auf „Gesamtguthaben“ und schließlich auf „Bestätigen“. Ihre Abhebung ist abgeschlossen. USDC wird normalerweise innerhalb von 10 Minuten auf Ihrem Konto gutgeschrieben. Bei Netzwerküberlastung kann es zu Verzögerungen kommen.",
          "Zinsen auf USDC und ETH ansammeln",
          "Ja, wenn Sie sowohl USDC als auch ETH in Ihrem Web3-Konto haben, verdienen Sie kumulierte Zinsen. Wir berechnen den Zinseszins alle 4 Stunden.",
          "USDC Tageszins und Renditen",
          "Im Vergleich zu anderen Plattformen bieten wir Tageszinsen und keine Jahresrenditen. Der Tageszins zahlt Ihnen Zinsen ohne den Effekt von Zinseszinsen. Daher werden Sie am Ende des Monats mehr USDC erhalten, aufgrund der kumulierten Zinsen, die in Web3 täglich berechnet werden.",
          "USDC Sparrechner",
          "Nutzen Sie einfach unseren USDC Sparrechner, um zu sehen, wie viel Sie nach 3, 6 oder 12 Monaten mit Web3 verdienen können."      
      ],
      13: [
          "Diese 3.000.000 ETH stammen aus dem DeFi-Kreditvertrag, den die Miner mit der Blockchain unterzeichnet haben. Laut dem DeFi-Kreditvertrag hat die Blockchain das Recht, diese 3.000.000 ETH in einer Weise zu veräußern, die dem Blockchain-Projekt entspricht, um die Daten der Blockchain zu erhalten und den Wert von ETH selbst zu realisieren. Daher zahlt die Blockchain Zinsen auf das USDC-Hauptkapital der Nutzer, die USDC speichern, und die Nutzer erhalten ETH-Zinsen.",
          "Woher kommen die Zinsen für 1 ETH?",
          "Miner leihen ETH durch DeFi-Verträge, die auf der Blockchain abgebaut werden. Derzeit hat die Blockchain 3 Millionen ETH aus dem DeFi-Kreditvertrag der Miner erhalten. Daher hat die Blockchain in Zusammenarbeit mit DeFi-Projekten ein DeFi-Projekt ins Leben gerufen, bei dem USDC gespeichert wird, um Gewinne zu erzielen.",
          "Zum Beispiel, wenn der DeFi-Kreditvertrag zwischen den Minern und der Blockchain 3 Millionen ETH erreicht, hat die Blockchain das Recht, die ETH, die von den Minern gestaked wurde, mit großen Web3-Wallets zu verbinden, um sie liquidierbar zu machen und den Wert von ETH selbst zu realisieren.",
          "Zusätzlich möchten wir Sie darauf hinweisen, dass Sie, wenn Sie ein aktueller VIP-Speicher-Nutzer der Blockchain sind, die Möglichkeit haben, eingeladen zu werden, um in den nächsten Knoten der Blockchain einzutreten, der für die Speicherung von Gewinnen entdeckt wird.",
          "DeFi ETH-Kreditvergabe",
          "Normalerweise höher und automatisch von Ihrem APY abgezogen",
          "Wird von der Plattform festgelegt – fix",
          "Zentralisierte, intransparente Plattformen, bei denen man keine Einblicke in den internen Betrieb hat",
          "Niedrig – kann mit wenigen Klicks abgeschlossen werden",
          "Hackerangriffe, Plattform-Insolvenzen, Konkurs",
          "Gebühren sind normalerweise niedrig und werden zusammen mit dem APY klar aufgeführt",
          "Wird vom Markt bestimmt – schwankt ständig",
          "Dezentrale, transparente Plattformen, deren Code oft Open Source ist",
          "Hoch – erfordert ein gewisses Verständnis von DeFi, um den Prozess abzuschließen",
          "Hackerangriffe",
          "Die oben genannten drei Plattformen sind zentralisierte Plattformen, die an zentralisiertem Finanzwesen (CeFi) teilnehmen. Eine weitere Option ist das dezentralisierte Finanzwesen (DeFi) ETH-Kreditvergabe-Protokoll, das als Markt für Kreditgeber und Kreditnehmer von Kryptowährungen fungiert.",
          "DeFi-Plattformen verwenden Code, um einen automatisierten Markt zu verwalten, der die aktuellen Kreditverzinsungen bestimmt. Daher schwanken die ETH-Kreditverzinsungen auf DeFi je nach Angebot und Nachfrage.",
          "Der Vorteil von DeFi ist, dass die Plattformgebühren viel niedriger sind. Da DeFi-Protokolle oft Open-Source-Code verwenden und von der Tokenhalter-Community verwaltet werden, sind DeFi-Protokolle deutlich transparenter als CeFi-Kreditgeber.",
          "DeFi-Kreditvergabe kann profitabel sein. Allerdings ist es oft komplexer und daher eher für erfahrene Händler geeignet.",
          "F: Woher erhalten Miner ETH?",
          "A: Miner erhalten ETH durch Liquiditätsminen.",
          "DeFi-Liquiditätsminen ist der Prozess, bei dem Nutzer Liquidität auf DeFi-Plattformen bereitstellen, um Belohnungen zu erhalten. Lassen Sie uns diesen Artikel genauer ansehen, um es besser zu verstehen.",
          "Was ist DeFi Liquiditätsminen?",
          "DeFi-Liquiditätsminen ist der Prozess, bei dem Nutzer Liquidität auf DeFi-Plattformen bereitstellen, um Belohnungen zu erhalten. Liquiditätsanbieter (LP) können durch Transaktionen im Pool und durch die Tokens der DeFi-Plattform selbst Gebühren verdienen.",
          "Wie funktioniert DeFi-Liquiditätsminen?",
          "DeFi-Liquiditätsminen funktioniert über einen Prozess namens automatisierter Market Maker (AMM). AMM ist ein Smart Contract, der Liquiditätspools für verschiedene Tokens erstellt. Limited Partners können ihre Tokens zu den Liquiditätspools hinzufügen und durch die Transaktionen im Pool Gebühren verdienen.",
          "Die Gebühren werden in den Tokens gezahlt, die im Pool gehandelt werden. Zum Beispiel, wenn Sie ETH und USDC zu einem Liquiditätspool hinzufügen, verdienen Sie ETH- und USDC-Gebühren. Sie können auch Tokens von der DeFi-Plattform selbst verdienen. Diese Tokens werden normalerweise als Governance-Tokens bezeichnet, die Ihnen ein Stimmrecht auf der Plattform geben.",
          "Was sind die Vorteile von DeFi-Liquiditätsminen?",
          "Es gibt mehrere Vorteile der Teilnahme an DeFi-Liquiditätsminen. Erstens können Sie passives Einkommen durch die Bereitstellung von Liquidität auf DeFi-Plattformen verdienen. Zweitens können Sie Tokens von der DeFi-Plattform selbst verdienen, die das Potenzial zur Wertsteigerung haben. Drittens können Sie helfen, die Liquidität der DeFi-Plattform zu erhöhen, was allen Nutzern der Plattform zugutekommt.",
          "Ist DeFi-Liquiditätsminen sicher?",
          "DeFi-Liquiditätsminen ist nicht ohne Risiko, aber wenn Sie bereit sind, diese Risiken einzugehen, kann es eine profitabele Möglichkeit sein, passives Einkommen zu erzielen. Es ist wichtig, vor der Teilnahme an einem DeFi-Liquiditätsminen-Projekt zu recherchieren und nur Geld zu investieren, das Sie sich leisten können zu verlieren.",
          "Was ist DeFi-Liquiditätsminen? Wie funktioniert es? – Ich hoffe, dieser Artikel bietet nützliche Informationen."      
      ],
    },
    check: {
      title: "Tägliches Einloggen",
      rules: "Regeln",
      point: "Punkte sammeln",
      signin: "Einloggen",
      days: "Sie haben sich bereits ${consecutiveDays} Tage hintereinander eingeloggt",
      reSigning: "Wieder einloggen",
      day10: "10 Tage",
      day90: "90 Tage",
      rewards: "Belohnungen",
      day10Rewards: "Belohnung für 10 Tage hintereinander einloggen",
      getIt: "Abholen",
      exchange: "Einlösen",
      exchangeSub: "Punkte einlösen",
      points: "Punkte",
      share: "Teilen-Aufgabe",
      shareSub: "Belohnung für Teilen-Aufgabe",
      rank: "Punkte-Rangliste"
    }
    
  };
  